import React from "react"

import styled, { css } from "styled-components"
import { breakpoints, getThemeByName, ThemeProvider } from "../../styles"
import {
  SPACE_TOKEN_INLINE_2XL,
  SPACE_TOKEN_INLINE_3XL,
  SPACE_TOKEN_INLINE_L,
  SPACE_TOKEN_STACK_2XL,
  SPACE_TOKEN_INLINE_1XL,
  SPACE_TOKEN_STACK_4XL,
  SPACE_TOKEN_STACK_M,
  SPACE_TOKEN_STACK_S,
  SPACE_2XL,
  SPACE_XL,
} from "../../styles/primitives"

import { Text } from "../Text"
import { RichText } from "../RichText"
import { Button } from "../button"
import { MAX_WIDTH } from "../../styles/breakpoints"
import { getSliceRootElementProps } from "./utils"

function createGridRows() {
  return ({ inline }) => css`
    grid-template-rows: [banner-start] auto [banner-end];
  `
}

function createGridColumns(horizontalPadding) {
  return css`
    grid-template-columns: [left] ${horizontalPadding} [banner-start] auto [banner-end] ${horizontalPadding} [right];
  `
}

const Container = styled.section`
  position: relative;
  display: grid;

  ${({ inline, sliceBackgroundVariant }) =>
    breakpoints({
      SM: css`
        padding: ${inline ? 0 : 0} 0;

        &[data-slice-background-variant=${sliceBackgroundVariant}] + *[data-slice-background-variant=${sliceBackgroundVariant}] {
          padding-top: ${SPACE_2XL}px;
        }

        ${createGridRows()}
        ${createGridColumns(0)}
      `,
      MD: css`
        padding: ${inline ? 0 : 0} 0;

        &[data-slice-background-variant=${sliceBackgroundVariant}] + *[data-slice-background-variant=${sliceBackgroundVariant}] {
          padding-top: ${SPACE_2XL}px;
        }

        ${createGridRows()}
        ${createGridColumns(0)}
      `,
      LG: css`
        padding: ${inline ? 0 : `${SPACE_2XL}px`} 0;
        ${createGridRows()}
        ${createGridColumns(`${SPACE_XL}px`)}
      `,
      XL: css`
        padding: ${inline ? 0 : `${SPACE_2XL}px`} 0;
        ${createGridRows()}
        ${createGridColumns(`${SPACE_XL}px`)}
      `,
      MAX: css`
        padding: ${inline ? 0 : `${SPACE_2XL}px`} 0;
        ${createGridRows()}
        ${createGridColumns(
          `calc((100% - ${MAX_WIDTH}px) / 2 + ${SPACE_XL}px)`
        )}
      `,
    })};
`

const InnerContainer = styled.div`
  grid-row-start: banner-start;
  grid-row-end: banner-end;
  grid-column-start: banner-start;
  grid-column-end: banner-end;

  ${({ inline }) =>
    inline &&
    breakpoints({
      LG: css`
        position: absolute;
        transform: translateY(-50%);
      `,
      XL: css`
        position: absolute;
        transform: translateY(-50%);
      `,
    })};

  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.backgroundColor};
  border-radius: ${SPACE_TOKEN_STACK_S}px;

  ${breakpoints({
    SM: css`
      padding: ${SPACE_TOKEN_STACK_2XL}px ${SPACE_TOKEN_INLINE_L}px;
      flex-direction: column;
      border-radius: 0;
    `,
    MD: css`
      border-radius: 0;
      padding: ${SPACE_TOKEN_STACK_2XL}px ${SPACE_TOKEN_INLINE_1XL}px;
    `,
    LG: css`
      padding: ${SPACE_TOKEN_STACK_2XL}px ${SPACE_TOKEN_INLINE_2XL}px;
    `,
    XL: css`
      padding: ${SPACE_TOKEN_STACK_2XL}px ${SPACE_TOKEN_INLINE_3XL}px;
    `,
  })};
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledHeadlineText = styled(Text)`
  padding-bottom: ${SPACE_TOKEN_STACK_M}px;
`

const StyledButton = styled(Button)`
  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_TOKEN_STACK_2XL}px;
      width: 100%;
    `,
    MD: css`
      margin-left: ${SPACE_TOKEN_STACK_2XL}px;
    `,
    LG: css`
      margin-left: ${SPACE_TOKEN_STACK_2XL}px;
    `,
    XL: css`
      margin-left: ${SPACE_TOKEN_STACK_4XL}px;
    `,
  })};
`

const BackgroundColor = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  grid-row-start: top;
  grid-row-end: bottom;
  grid-column-start: left;
  grid-column-end: right;
`

export function BannerSlice({ slice }) {
  const { sliceTheme, bannerTheme, headline, content, cta, inline } = slice
  const sliceBackgroundVariant = inline ? "unknown" : sliceTheme

  return (
    <ThemeProvider themeName={bannerTheme}>
      <Container
        inline={inline}
        sliceBackgroundVariant={sliceBackgroundVariant}
        {...getSliceRootElementProps(slice, {}, { sliceBackgroundVariant })}
      >
        <BackgroundColor
          backgroundColor={getThemeByName(sliceTheme).backgroundColor}
        />

        <InnerContainer>
          <TextContainer>
            <StyledHeadlineText type="secondary">
              {headline.headline}
            </StyledHeadlineText>
            <Text type="copy">
              <RichText data={content} />
            </Text>
          </TextContainer>
          <StyledButton
            variant="primary"
            linkTo={cta.internalLink || cta.externalLink}
          >
            {cta.label}
          </StyledButton>
        </InnerContainer>
      </Container>
    </ThemeProvider>
  )
}
