import React, { useMemo, useState } from "react"
import AnimateHeight from "react-animate-height"
import { TransitionGroup, Transition } from "react-transition-group"
import styled, { css } from "styled-components"
import { useGlobalTranslation } from "../globalValues"
import { useLocale } from "../locale"
import { breakpoints } from "../styles"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../styles/animations"

import {
  SPACE_2XL,
  SPACE_L,
  SPACE_TOKEN_STACK_2XL,
  SPACE_XL,
} from "../styles/primitives"
import { Button } from "./button"

import { AssetTeaser } from "./AssetTeaser"

const StyledButton = styled(Button)`
  display: block;
  margin: ${SPACE_TOKEN_STACK_2XL}px auto;
`

export const TeaserColumns = styled(
  ({ teasers, showLinks, showDates, numberOfColumns, ...otherProps }) => {
    const { locale } = useLocale()
    const [displayedNumberOfRows, setDisplayedNumberOfRows] = useState(1)

    const teaserRows = useMemo(() => {
      const rows = []
      const numberOfRows = Math.ceil(teasers.length / numberOfColumns)

      for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
        rows.push([])
        for (
          let columnIndex = 0;
          columnIndex < numberOfColumns;
          columnIndex++
        ) {
          const nextColumn = teasers[rowIndex * numberOfColumns + columnIndex]
          nextColumn && rows[rowIndex].push(nextColumn)
        }
      }

      return rows
    }, [numberOfColumns, teasers])
    const loadMoreButtonLabel = useGlobalTranslation("loadMoreButtonLabel")

    return (
      <>
        <TransitionGroup
          component={InnerContainer}
          numberOfColumns={numberOfColumns}
        >
          {teaserRows
            .slice(0, displayedNumberOfRows)
            .map((teaserRow, rowIndex) => (
              <SRowTransition
                timeout={ANIMATION_DURATION}
                classNames="teaser-item-transition"
                key={`${rowIndex}`}
              >
                {state => (
                  <AnimateHeight
                    easing={ANIMATION_EASING}
                    duration={ANIMATION_DURATION * 1}
                    height={{ entering: 0, entered: "auto" }[state] || 0}
                  >
                    {teaserRow.map((teaser, columnIndex) => (
                      <AssetTeaser
                        teaser={teaser}
                        locale={locale}
                        showLink={showLinks}
                        showMeta={showDates}
                        columnIndex={columnIndex}
                        rowIndex={rowIndex}
                      />
                    ))}
                  </AnimateHeight>
                )}
              </SRowTransition>
            ))}
        </TransitionGroup>
        <AnimateHeight
          easing={ANIMATION_EASING}
          duration={ANIMATION_DURATION}
          delay={200}
          height={displayedNumberOfRows < teaserRows.length ? "auto" : 0}
        >
          {
            <StyledButton
              onClick={() =>
                setDisplayedNumberOfRows(displayedNumberOfRows + 1)
              }
              variant="secondary"
            >
              {loadMoreButtonLabel}
            </StyledButton>
          }
        </AnimateHeight>
      </>
    )
  }
)`
  & + & {
    margin-top: -${SPACE_2XL}px;
  }
`

const SRowTransition = styled(Transition)`
  margin: 1px;
`

const InnerContainer = styled.div`
  > * + * {
    margin-top: ${SPACE_L}px;
  }

  > * {
    > * {
      display: grid;

      ${({ numberOfColumns }) => css`
        grid-template-columns: repeat(
          ${numberOfColumns},
          [column-start] 1fr [column-end]
        );
        grid-template-rows: [top-start] auto [top-end] 0 [bottom-start] auto [bottom-end];
        grid-column-gap: ${SPACE_L}px;

        ${breakpoints({
          SM: css`
            grid-template-columns:
              repeat(${numberOfColumns}, 0 [column-start])
              auto repeat(${numberOfColumns}, 0 [column-end]);
            grid-template-rows: repeat(
              ${numberOfColumns},
              [top-start] auto [top-end] 0 [bottom-start] auto [bottom-end]
                ${SPACE_XL}px
            );
            grid-column-gap: 0px;
          `,
        })};
      `};
    }
  }
`
