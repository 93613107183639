import React, { useCallback, useEffect, useMemo, useState } from "react"
import styled, { css } from "styled-components"

import { breakpoints, ThemeProvider } from "../../styles"
import {
  SPACE_2XL,
  SPACE_3XL,
  SPACE_4XL,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../../styles/primitives"
import { Text } from "../Text"
import {
  FacebookIcon,
  LinkedInIcon,
  MailIcon,
  TwitterIcon,
} from "../../icons/icons"
import { getSliceRootElementProps } from "./utils"
import { MAX_WIDTH } from "../../styles/breakpoints"

const SRoot = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor};
  width: fit-content;

  &:last-child {
    padding-bottom: ${SPACE_2XL}px;
  }

  ${breakpoints({
    SM: css`
      width: 100%;
      padding: ${SPACE_4XL}px ${SPACE_L}px;
    `,
    MD: css`
      padding: ${SPACE_4XL}px ${SPACE_L}px;
    `,
    LG: css`
      padding: ${SPACE_4XL}px ${SPACE_XL}px;
    `,
    XL: css`
      padding: ${SPACE_4XL}px
        calc(max(0px, 100vw - ${MAX_WIDTH}px) / 2 + ${SPACE_XL}px);
    `,
  })}
`

const Header = styled.header`
  padding-bottom: ${SPACE_XL}px;
`

const IconContainer = styled.div`
  ${breakpoints({
    SM: css`
      display: flex;
      justify-content: space-between;
    `,
  })}

  > * {
    margin-right: ${SPACE_XL}px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    ${breakpoints({
      SM: css`
        margin-right: ${SPACE_L}px;
      `,
    })}
  }
`

const siteBaseURL = process.env.GATSBY_SITE_URL
export function SharingSlice({ href, slice, sharedTitle, sharedDescription }) {
  const url = `${siteBaseURL}/${href}`

  const [navigatorShareFn, setNavigatorShareFn] = useState(null)

  const openPopup = useCallback(url => {
    window.open(
      url,
      "_blank",
      "top=100,left=100,toolbar=0,status=0,width=400,height=500"
    )
  }, [])

  const shareViaWebShare = useCallback(() => {
    navigator
      .share({
        title: sharedTitle,
        text: sharedDescription,
        url: url,
      })
      .catch((...args) => console.error(...args))
  }, [sharedDescription, sharedTitle, url])
  const shareViaFacebook = useCallback(() => {
    openPopup(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
    )
  }, [openPopup, url])

  const shareViaTwitter = useCallback(() => {
    openPopup(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        sharedTitle
      )}&url=${encodeURIComponent(url)}`
    )
  }, [openPopup, sharedTitle, url])
  const sharedViaLinkedIn = useCallback(() => {
    openPopup(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        url
      )}`
    )
  }, [openPopup, url])

  const mailToLink = useMemo(
    () =>
      `mailto:?subject=${encodeURIComponent(
        sharedTitle
      )}&body=${encodeURIComponent(url)}`,
    [sharedTitle, url]
  )

  useEffect(() => {
    if (typeof navigator.share === "function") {
      setNavigatorShareFn(() => (...args) => navigator.share(...args))
    }
  }, [])

  return (
    <ThemeProvider themeName="stone">
      <SRoot
        {...getSliceRootElementProps(
          slice,
          {},
          { sliceBackgroundVariant: "stone" }
        )}
      >
        <Header>
          <Text type="secondary">{slice.headline.headline}</Text>
        </Header>
        <IconContainer>
          <LinkedInIcon onClick={sharedViaLinkedIn} />
          <TwitterIcon onClick={shareViaTwitter} />
          {navigatorShareFn ? (
            <MailIcon onClick={shareViaWebShare} />
          ) : (
            <a href={mailToLink}>
              <MailIcon />
            </a>
          )}
          <FacebookIcon onClick={shareViaFacebook} />
        </IconContainer>
      </SRoot>
    </ThemeProvider>
  )
}
