import React, { useEffect, useMemo, useState } from "react"

import styled, { css } from "styled-components"
import { breakpoints } from "../../styles"
import {
  SPACE_1XL,
  SPACE_2XL,
  SPACE_3XL,
  SPACE_L,
  SPACE_TOKEN_STACK_2XL,
  SPACE_TOKEN_STACK_4XL,
  SPACE_TOKEN_STACK_M,
  SPACE_TOKEN_STACK_S,
  SPACE_XL,
} from "../../styles/primitives"

import { Text } from "../Text"
import { Image } from "../Image"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../../styles/animations"
import { useLocale } from "../../locale"
import { getSliceRootElementProps } from "./utils"
import { MAX_WIDTH } from "../../styles/breakpoints"

const quotationMarksByLocale = {
  de: ["„", "“"],
  fr: ["«", "»"],
  en: ["“", "”"],
  nl: ["„", "“"],
}

export function QuotesSlice({ slice }) {
  const { defaultDisplayDurationInSeconds } = slice
  const { locale } = useLocale()
  const { currentQuoteIndex, setCurrentQuoteIndex } = useQuotes(
    slice.quotes,
    defaultDisplayDurationInSeconds
  )

  const quoteTextsWithQuotes = useMemo(
    () =>
      slice.quotes.map(quote => {
        const [quotationMarkLeft, quotationMarkRight] =
          quotationMarksByLocale[locale.slice(0, 2)] ||
          quotationMarksByLocale.en
        return `${quotationMarkLeft}${quote.quoteText.quoteText}${quotationMarkRight}`
      }),
    [locale, slice.quotes]
  )

  return (
    <SRoot {...getSliceRootElementProps(slice)}>
      {slice.headline?.headline && (
        <Text type="meta">{slice.headline.headline}</Text>
      )}

      <QuoteContainer>
        {quoteTextsWithQuotes.map((quoteText, index) => (
          <QuoteText type="primary" isShown={index === currentQuoteIndex}>
            {quoteText}
          </QuoteText>
        ))}
      </QuoteContainer>

      <ImageGrid>
        {slice.quotes.map(({ authorImage }, index) => (
          <StyledImage
            key={index}
            asset={authorImage}
            isShown={index === currentQuoteIndex}
            objectFit="contain"
            onClick={() => setCurrentQuoteIndex(index)}
          />
        ))}
      </ImageGrid>
    </SRoot>
  )
}

function useQuotes(quotes, defaultDisplayDurationInSeconds) {
  const [currentQuoteIndex, setCurrentQuoteIndex] = useState(0)

  useEffect(() => {
    const timeoutId = setTimeout(
      () =>
        setCurrentQuoteIndex(
          quotes.length === currentQuoteIndex + 1 ? 0 : currentQuoteIndex + 1
        ),
      (quotes[currentQuoteIndex].customDisplayDurationInSeconds ||
        defaultDisplayDurationInSeconds) * 1000
    )

    return () => clearTimeout(timeoutId)
  }, [
    currentQuoteIndex,
    defaultDisplayDurationInSeconds,
    quotes,
    quotes.length,
  ])

  return { currentQuoteIndex, setCurrentQuoteIndex }
}

const QuoteText = styled(Text)`
  grid-area: quote;
  opacity: 0;
  transition: opacity ${ANIMATION_DURATION}ms ${ANIMATION_EASING};
  transition-delay: 0ms;
  display: flex;
  align-items: center;
  white-space: pre-wrap;

  ${({ isShown }) =>
    isShown &&
    css`
      opacity: 1;
      transition-delay: ${ANIMATION_DURATION}ms;
    `}
`

const SRoot = styled.section`
  ${breakpoints({
    SM: css`
      padding: ${SPACE_3XL}px ${SPACE_L}px;
    `,
    MD: css`
      padding: ${SPACE_3XL}px ${SPACE_1XL}px;
    `,
    LG: css`
      padding: ${SPACE_3XL}px ${SPACE_2XL}px;
    `,
    XL: css`
      padding: ${SPACE_TOKEN_STACK_4XL}px
        calc(max(0px, (100% - ${MAX_WIDTH}px) / 2) + ${SPACE_XL}px);
    `,
  })}
`

const ImageGrid = styled.div`
  display: flex;
  justify-content: space-between;

  ${breakpoints({
    SM: css`
      flex-wrap: wrap;
    `,
  })};
`

const StyledImage = styled(Image)`
  opacity: ${({ isShown }) => (isShown ? 1 : 0.5)};
  cursor: pointer;
  transition: opacity ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;

  &:hover {
    opacity: 1;
  }

  border-radius: ${SPACE_TOKEN_STACK_S}px;

  height: 72px;
  width: 128px;

  ${breakpoints({
    SM: css`
      flex: 0 0 calc(50% - ${SPACE_1XL / 2}px);

      &:nth-child(n + 3) {
        margin-right: 0;
        margin-top: ${SPACE_1XL}px;
      }
    `,
    MD: css`
      &:not(:last-child) {
        margin-right: ${SPACE_1XL}px;
      }
    `,
    LG: css`
      &:not(:last-child) {
        margin-right: ${SPACE_1XL}px;
      }
    `,
    XL: css`
      &:not(:last-child) {
        margin-right: ${SPACE_1XL}px;
      }
    `,
  })};
`

const QuoteContainer = styled.div`
  margin-top: ${SPACE_TOKEN_STACK_M}px;
  margin-bottom: ${SPACE_TOKEN_STACK_2XL}px;

  display: grid;
  grid-template-areas: "quote";
`
