import React from "react"
import styled, { css } from "styled-components"
import { breakpoints, ThemeProvider } from "../../styles"
import {
  SPACE_2XL,
  SPACE_3XL,
  SPACE_4XL,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../../styles/primitives"
import { LoadingDecoratedImage } from "../Image"
import { RichText } from "../RichText"
import { Text } from "../Text"
import { scrollStyles, calculateGridWidth } from "../ScrollableColumns"
import { MAX_WIDTH } from "../../styles/breakpoints"
import { getSliceRootElementProps } from "./utils"
import { SBackgroundImageSliceRichText } from "./backgroundImageParallaxSlice/richTextComponents"

function createGridTemplateRows(spacing, offsetRatio) {
  return css`
    grid-template-rows: [top] 0 [top-left-image-start] 0 [top-right-text-start] ${offsetRatio}fr [top-right-text-end] 0 [top-right-image-start] 1fr [top-left-image-end] 0 [top-left-text-start] ${offsetRatio}fr [top-left-text-end] 0 [top-right-image-end] ${({
        hasMiddleItem,
      }) =>
        hasMiddleItem
          ? css`
              ${spacing}px [wide-image-start] auto [wide-image-end]
            `
          : ""} ${({ hasBottomItems }) =>
        hasBottomItems
          ? css`
              ${spacing}px [bottom-left-image-start] 0 [bottom-right-text-start] ${offsetRatio}fr [bottom-right-text-end] 0 [bottom-right-image-start] 1fr [bottom-left-image-end] 0 [bottom-left-text-start] ${offsetRatio}fr [bottom-left-text-end] 0 [bottom-right-image-end]
            `
          : ""};
  `
}
function createGridTemplateColumns(outerPadding, innerGaps) {
  return css`
    grid-template-columns:
      [left] ${outerPadding}px
      [content-left-start] 1fr [content-left-end] ${innerGaps /
      2}px [center] ${innerGaps / 2}px [content-right-start] 1fr [content-right-end]
      ${outerPadding}px
      [right];
  `
}

const OuterContainer = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor};

  ${breakpoints({
    SM: css`
      padding-top: ${SPACE_2XL}px;
      padding-bottom: ${SPACE_2XL}px;
    `,
    MD: css`
      padding-top: ${SPACE_3XL}px;
      padding-bottom: ${SPACE_XL}px;
      /* margin-bottom: -${SPACE_2XL}*/
    `,
    LG: css`
      padding-top: ${SPACE_2XL}px;
      padding-bottom: ${SPACE_2XL}px;
      /* margin-bottom: -116px; */
    `,
    XL: css`
      padding-top: ${SPACE_4XL}px;
      padding-bottom: ${SPACE_4XL}px;
      /* margin-bottom: -112px; */
    `,
    MAX: css`
      padding-top: ${SPACE_4XL}px;
      padding-bottom: ${SPACE_4XL}px;
      /* margin-bottom: -112px; */
    `,
  })};
`

const ScrollContainer = styled.section`
  ${breakpoints({
    SM: css`
      ${scrollStyles};
    `,
  })}
`

const Container = styled.div`
  display: grid;

  ${breakpoints({
    SM: css`
      ${({ numberOfColumnsForMobile }) => css`
        ${calculateGridWidth(numberOfColumnsForMobile, 1)};
        grid-template-rows: [top] 0 [image-start] auto [image-end] ${SPACE_XL}px [text-start] auto [text-end] ${SPACE_2XL}px [bottom];
        grid-template-columns:
          [left]
          ${SPACE_L}px repeat(
            ${numberOfColumnsForMobile},
            [column-start] 1fr [column-end] ${SPACE_M}px
          )
          ${SPACE_L - SPACE_M}px [right];
      `};

      > * {
        scroll-snap-align: center;
      }
    `,
    MD: css`
      ${createGridTemplateRows(SPACE_M, 2)};
      ${createGridTemplateColumns(SPACE_L, SPACE_M)};
    `,
    LG: css`
      ${createGridTemplateRows(SPACE_L, 2)};
      ${createGridTemplateColumns(SPACE_XL, SPACE_L)};
    `,
    XL: css`
      ${createGridTemplateRows(SPACE_L, 1)};
      ${createGridTemplateColumns(SPACE_XL, SPACE_L)};
    `,
    MAX: css`
      ${createGridTemplateRows(SPACE_L, 1)};
      grid-template-columns:
        [left] calc(max(100% - ${MAX_WIDTH}px, 0px) / 2 + ${SPACE_XL}px)
        [content-left-start] 1fr [content-left-end] ${SPACE_L /
        2}px [center] ${SPACE_L / 2}px [content-right-start] 1fr [content-right-end]
        calc(max(100% - ${MAX_WIDTH}px, 0px) / 2 + ${SPACE_XL}px)
        [right];
    `,
  })};
`

const textIndentationByBreakpoints = { LG: 80, XL: SPACE_4XL - SPACE_L }

const ItemText = styled(Text)`
  padding: ${SPACE_2XL}px 0;
  display: flex;

  ${({ position }) =>
    position === "MIDDLE"
      ? css`
          display: none;
          ${breakpoints({
            SM: css`
              display: block;
              grid-row-start: text-start;
              grid-row-end: text-end;
              grid-column-start: column-start 3;
              grid-column-end: column-end 3;
            `,
          })}
        `
      : breakpoints({
          SM: css`
            grid-row-start: text-start;
            grid-row-end: text-end;
            ${{
              TOP_LEFT: css`
                grid-column-start: column-start 2;
                grid-column-end: column-end 2;
              `,
              TOP_RIGHT: css`
                grid-column-start: column-start 1;
                grid-column-end: column-end 1;
              `,
              BOTTOM_LEFT: css`
                grid-column-start: column-start 5;
                grid-column-end: column-end 5;
              `,
              BOTTOM_RIGHT: css`
                grid-column-start: column-start 4;
                grid-column-end: column-end 4;
              `,
            }[position]}
          `,
          LG: {
            TOP_LEFT: css`
              padding-right: ${textIndentationByBreakpoints.LG}px;
            `,
            TOP_RIGHT: css`
              padding-left: ${textIndentationByBreakpoints.LG}px;
            `,
            BOTTOM_LEFT: css`
              padding-right: ${textIndentationByBreakpoints.LG}px;
            `,
            BOTTOM_RIGHT: css`
              padding-left: ${textIndentationByBreakpoints.LG}px;
            `,
          }[position],
          XL: {
            TOP_LEFT: css`
              padding-right: ${textIndentationByBreakpoints.XL}px;
            `,
            TOP_RIGHT: css`
              padding-left: ${textIndentationByBreakpoints.XL}px;
            `,
            BOTTOM_LEFT: css`
              padding-right: ${textIndentationByBreakpoints.XL}px;
            `,
            BOTTOM_RIGHT: css`
              padding-left: ${textIndentationByBreakpoints.XL}px;
            `,
          }[position],
        })}

  ${({ position }) =>
    ({
      TOP_LEFT: css`
        grid-row-start: top-left-text-start;
        grid-row-end: top-left-text-end;
        grid-column-start: content-left-start;
        grid-column-end: content-left-end;
      `,
      TOP_RIGHT: css`
        grid-row-start: top-right-text-start;
        grid-row-end: top-right-text-end;
        grid-column-start: content-right-start;
        grid-column-end: content-right-end;

        align-items: flex-end;
      `,
      BOTTOM_LEFT: css`
        grid-row-start: bottom-left-text-start;
        grid-row-end: bottom-left-text-end;
        grid-column-start: content-left-start;
        grid-column-end: content-left-end;
      `,
      BOTTOM_RIGHT: css`
        grid-row-start: bottom-right-text-start;
        grid-row-end: bottom-right-text-end;
        grid-column-start: content-right-start;
        grid-column-end: content-right-end;

        align-items: flex-end;
      `,
    }[position])};

  ${breakpoints({
    SM: css`
      padding: 0;
      align-items: flex-start;
    `,
  })};
`

const ItemImage = styled(LoadingDecoratedImage)`
  ${({ position }) =>
    ({
      TOP_LEFT: css`
        grid-row-start: top-left-image-start;
        grid-row-end: top-left-image-end;
        grid-column-start: content-left-start;
        grid-column-end: content-left-end;
      `,
      TOP_RIGHT: css`
        grid-row-start: top-right-image-start;
        grid-row-end: top-right-image-end;
        grid-column-start: content-right-start;
        grid-column-end: content-right-end;
      `,
      BOTTOM_LEFT: css`
        grid-row-start: bottom-left-image-start;
        grid-row-end: bottom-left-image-end;
        grid-column-start: content-left-start;
        grid-column-end: content-left-end;
      `,
      BOTTOM_RIGHT: css`
        grid-row-start: bottom-right-image-start;
        grid-row-end: bottom-right-image-end;
        grid-column-start: content-right-start;
        grid-column-end: content-right-end;
      `,
    }[position])}

  ${({ position }) =>
    breakpoints({
      SM: css`
        grid-row-start: image-start;
        grid-row-end: image-end;

        ${{
          TOP_LEFT: css`
            grid-column-start: column-start 1;
            grid-column-end: column-end 1;
          `,
          TOP_RIGHT: css`
            grid-column-start: column-start 2;
            grid-column-end: column-end 2;
          `,
          BOTTOM_LEFT: css`
            grid-column-start: column-start 4;
            grid-column-end: column-end 4;
          `,
          BOTTOM_RIGHT: css`
            grid-column-start: column-start 5;
            grid-column-end: column-end 5;
          `,
        }[position]}
      `,
    })}
`

const WideImage = styled(LoadingDecoratedImage)`
  grid-row-start: wide-image-start;
  grid-row-end: wide-image-end;
  grid-column-start: content-left-start;
  grid-column-end: content-right-end;

  ${breakpoints({
    SM: css`
      grid-row-start: image-start;
      grid-row-end: image-end;
      grid-column-start: column-start 3;
      grid-column-end: column-end 3;
    `,
  })};
`

export function BigEditorialSlice({ slice }) {
  const { topItems, wideItem } = slice
  const bottomItems = wideItem ? slice.bottomItems : null

  return (
    <ThemeProvider themeName="stone">
      <OuterContainer
        {...getSliceRootElementProps(
          slice,
          {},
          { sliceBackgroundVariant: "stone" }
        )}
      >
        <ScrollContainer>
          <Container
            numberOfColumnsForMobile={
              2 + (wideItem ? 1 : 0) + (bottomItems ? 2 : 0)
            }
            hasMiddleItem={!!wideItem}
            hasBottomItems={!!bottomItems}
          >
            <ItemText type="subHeadline" position="TOP_LEFT">
              <div>
                <div>
                  <SBackgroundImageSliceRichText
                    data={topItems[1].text}
                    keepTopLevelParagraph={true}
                  />
                </div>
              </div>
            </ItemText>
            <ItemImage
              asset={topItems[0].image}
              position="TOP_LEFT"
              colorShift={0}
            />

            <ItemText type="subHeadline" position="TOP_RIGHT">
              <div>
                <SBackgroundImageSliceRichText
                  data={topItems[0].text}
                  keepTopLevelParagraph={true}
                />
              </div>
            </ItemText>
            <ItemImage
              asset={topItems[1].image}
              position="TOP_RIGHT"
              colorShift={1}
            />

            {wideItem && (
              <>
                <ItemText type="subHeadline" position="MIDDLE">
                  <div>
                    <SBackgroundImageSliceRichText
                      data={wideItem.text}
                      keepTopLevelParagraph={true}
                    />
                  </div>
                </ItemText>
                <WideImage asset={wideItem.image} colorShift={2} />
              </>
            )}

            {bottomItems && (
              <>
                <ItemText type="subHeadline" position="BOTTOM_LEFT">
                  <div>
                    <SBackgroundImageSliceRichText
                      data={bottomItems[1].text}
                      keepTopLevelParagraph={true}
                    />
                  </div>
                </ItemText>
                <ItemImage
                  asset={bottomItems[0].image}
                  position="BOTTOM_LEFT"
                  colorShift={3}
                />

                <ItemText type="subHeadline" position="BOTTOM_RIGHT">
                  <div>
                    <SBackgroundImageSliceRichText
                      data={bottomItems[0].text}
                      keepTopLevelParagraph={true}
                    />
                  </div>
                </ItemText>
                <ItemImage
                  asset={bottomItems[1].image}
                  position="BOTTOM_RIGHT"
                  colorShift={4}
                />
              </>
            )}
          </Container>
        </ScrollContainer>
      </OuterContainer>
    </ThemeProvider>
  )
}
