import React, { forwardRef, useCallback } from "react"
import styled from "styled-components"
import { OverlayWrapper } from "../../map/OverlayWrapper"
import { SearchOverlay } from "../../map/SearchOverlay"
import { responsiveTopPadding } from "./config"
import { GarageLocationItem } from "./GarageLocationItem"

export const GarageFinderMapOverlay = forwardRef(
  (
    {
      locations,
      locationDistanceMap,
      replacementListContent,

      locationSearchState,
      unuScooterClassicOnlyLabel,
      pickUpAvailableLabel,
      mapOverlayHeadline,
      inputPlaceholder,
      handleAutoCompleteLoad,
      handleAutoCompletePlaceChanged,
      ...otherProps
    },
    ref
  ) => {
    const renderLocationItem = useCallback(
      (location, distanceToAutocompleteLocation) => (
        <GarageLocationItem
          key={location.id}
          location={location}
          distanceToAutocompleteLocation={distanceToAutocompleteLocation}
          isSelected={locationSearchState.selectedLocationId === location.id}
          onSelect={locationSearchState.setSelectedLocationId}
          pickUpAvailableLabel={pickUpAvailableLabel}
          unuScooterClassicOnlyLabel={unuScooterClassicOnlyLabel}
        />
      ),
      [
        locationSearchState.selectedLocationId,
        locationSearchState.setSelectedLocationId,
        pickUpAvailableLabel,
        unuScooterClassicOnlyLabel,
      ]
    )

    return (
      <OverlayWrapper index={0} responsiveTopPadding={responsiveTopPadding}>
        <StyledSearchOverlay
          locations={locations}
          locationDistanceMap={locationDistanceMap}
          replacementListContent={replacementListContent}
          ref={ref}
          renderLocationItem={renderLocationItem}
          headline={mapOverlayHeadline.mapOverlayHeadline}
          filterInputPlaceholder={inputPlaceholder}
          handleAutoCompleteLoad={handleAutoCompleteLoad}
          handleAutoCompletePlaceChanged={handleAutoCompletePlaceChanged}
        />
      </OverlayWrapper>
    )
  }
)

const StyledSearchOverlay = styled(SearchOverlay)`
  height: fit-content;
  max-height: 100%;
`
