import React from "react"

import styled, { css } from "styled-components"
import {
  breakpoints,
  TEXT_TOKEN_COPY_BREAKPOINT_LG,
  TEXT_TOKEN_COPY_BREAKPOINT_MD,
  TEXT_TOKEN_COPY_BREAKPOINT_SM,
  TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_MD,
  ThemeProvider,
} from "../../styles"
import { MAX_WIDTH } from "../../styles/breakpoints"
import {
  SPACE_2XL,
  SPACE_3XL,
  SPACE_4XL,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../../styles/primitives"
import { Image } from "../Image"
import { RichText, RichTextParagraphText } from "../RichText"

import { createTextStylesFromToken, Text } from "../Text"
import { getSliceRootElementProps } from "./utils"
import { CTAButton } from "../CTAButton"

function createGridRows(outerPadding, innerPadding) {
  return css`
    grid-template-rows: [top] ${outerPadding} [background-start] 0 [headline-start] min-content [headline-end] ${innerPadding} [description-start] min-content [description-end] ${innerPadding} [input-start] min-content [input-end] ${outerPadding} [background-end] 0 [bottom];
  `
}

function createGridColumns(outerPadding, contentRightPadding) {
  return css`
    grid-template-columns: [left] ${outerPadding} [content-start] 1fr [content-end] 0 [background-start] ${contentRightPadding} ${outerPadding} [background-end] 0 [right];
  `
}

const Container = styled.section`
  position: relative;
  z-index: 0;
  display: grid;
  background-color: ${({ theme }) => theme.backgroundColor};

  ${({ numberOfItems }) =>
    breakpoints({
      SM: css`
        grid-template-rows: [top] ${SPACE_2XL}px [headline-start] min-content [headline-end] ${SPACE_M}px [description-start] min-content [description-end] ${SPACE_L}px [input-start]min-content [input-end] ${SPACE_XL}px [background-start] min-content [background-end] 0 [right];
        grid-template-columns: [left] ${SPACE_L}px [content-start] 0 [background-start] 1fr [content-end] ${SPACE_L}px [background-end] 0 [right];
      `,
      MD: css`
        ${createGridRows(`${SPACE_3XL}px`, `${SPACE_L}px`)}
        ${createGridColumns(`${SPACE_XL}px`, "1fr")}
      `,
      LG: css`
        ${createGridRows(`${SPACE_3XL}px`, `${SPACE_L}px`)}
        ${createGridColumns(`${SPACE_XL}px`, "1fr")}
      `,
      XL: css`
        ${createGridRows(`${SPACE_4XL}px`, `${SPACE_XL}px`)}
        ${createGridColumns(`${SPACE_XL}px`, "1fr")}
      `,
      MAX: css`
        ${createGridRows(`${SPACE_4XL}px`, `${SPACE_XL}px`)}
        ${createGridColumns(
          `calc(max(100% - ${MAX_WIDTH}px, 0px) / 2 + ${SPACE_XL}px)`,
          "1fr"
        )}
      `,
    })}
`

const SImageWrapper = styled.div`
  grid-row-start: background-start;
  grid-row-end: background-end;
  grid-column-start: background-start;
  grid-column-end: background-end;

  display: flex;
  align-items: flex-end;
`

const HeadlineText = styled(Text)`
  grid-row-start: headline-start;
  grid-row-end: headline-end;
  grid-column-start: content-start;
  grid-column-end: content-end;
`

const SRichTextParagraphText = styled(RichTextParagraphText)`
  ${createTextStylesFromToken({
    SM: TEXT_TOKEN_COPY_BREAKPOINT_SM,
    MD: TEXT_TOKEN_COPY_BREAKPOINT_MD,
    LG: TEXT_TOKEN_COPY_BREAKPOINT_LG,
    XL: TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_MD,
  })};
`

const SDescriptionRichText = styled(RichText)`
  grid-row-start: description-start;
  grid-row-end: description-end;
  grid-column-start: content-start;
  grid-column-end: content-end;
`

const StyledCTAButton = styled(CTAButton)`
  grid-row-start: input-start;
  grid-row-end: input-end;
  grid-column-start: content-start;
  grid-column-end: content-end;
`

export function NewsletterSignUpSlice({ slice }) {
  const { theme, headline, description, backgroundImage, newsletterCta } = slice

  return (
    <ThemeProvider themeName={theme}>
      <Container {...getSliceRootElementProps(slice)}>
        <SImageWrapper>
          <Image asset={backgroundImage} objectFit="contain" />
        </SImageWrapper>
        <HeadlineText type="primary">{headline.headline}</HeadlineText>
        <SDescriptionRichText
          data={description}
          components={richtextComponents}
        />
        <StyledCTAButton cta={newsletterCta} />
      </Container>
    </ThemeProvider>
  )
}

const richtextComponents = { ParagraphText: SRichTextParagraphText }
