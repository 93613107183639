import React from "react"
import styled, { css } from "styled-components"
import {
  breakpoints,
  COLOR_UI_BACKGROUND_INVERTED,
  ThemeProvider,
} from "../../styles"
import {
  SPACE_2XL,
  SPACE_3XL,
  SPACE_4XL,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../../styles/primitives"
import { Image } from "../Image"
import { RichText } from "../RichText"
import { Text } from "../Text"
import { LoadingDecoratedMedia } from "../Media"
import { getSliceRootElementProps } from "./utils"
import { MAX_WIDTH } from "../../styles/breakpoints"

const SRoot = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  display: grid;

  ${breakpoints({
    SM: css`
      padding: ${SPACE_2XL}px ${SPACE_L}px;
      grid-template-rows: [row-1-start] auto [row-1-end] 0 [row-2-start] auto [row-2-end];
      grid-template-columns: [col-start] 1fr [col-1] 1fr [col-2] 1fr [col-3] 1fr [col-4] 1fr [col-5] 1fr [col-end];
      column-gap: ${SPACE_XL}px;
      row-gap: ${SPACE_M}px;
    `,
    MD: css`
      padding: ${SPACE_4XL}px ${SPACE_L}px;
      grid-template-rows: [row-1-start] auto [row-1-end] 0 [row-2-start] auto [row-2-end];
      grid-template-columns: [col-start] 1fr [col-1] 1fr [col-2] 1fr [col-3] 1fr [col-4] 1fr [col-5] 1fr [col-end];
      column-gap: ${SPACE_XL}px;
      row-gap: ${SPACE_M}px;
    `,
    LG: css`
      padding: ${SPACE_4XL}px ${SPACE_XL}px;
      grid-template-rows: [row-1-start] 0 [row-2-start] auto [row-1-end] 0 [row-2-end];
      grid-template-columns: [col-start] 1fr [col-1] 1fr [col-2] 1fr [col-3] 1fr [col-4] 1fr [col-5] 1fr [col-end];
      column-gap: ${SPACE_XL}px;
    `,
    XL: css`
      padding: ${SPACE_4XL}px
        calc(max(100% - ${MAX_WIDTH}px, 0px) / 2 + ${SPACE_XL}px);
      grid-template-rows: [row-1-start] 0 [row-2-start] auto [row-1-end] 0 [row-2-end];
      grid-template-columns: [col-start] 1fr [col-1] 1fr [col-2] 1fr [col-3] 1fr [col-4] 1fr [col-5] 1fr [col-end];
      column-gap: ${SPACE_XL}px;
    `,
  })};
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-row-start: row-2-start;
  grid-row-end: row-2-end;

  ${breakpoints({
    LG: css`
      ${({ sideVariant }) =>
        ({
          imageLeft: css`
            padding-right: 80px;
          `,
          imageRight: css`
            padding-left: 80px;
          `,
        }[sideVariant])};
    `,
    SM: css`
      grid-column-start: col-start;
      grid-column-end: col-end;
    `,
    MD: css`
      grid-column-start: col-start;
      grid-column-end: col-end;
    `,
  })}

  ${({ sideVariant }) =>
    ({
      imageLeft: css`
        grid-column-start: col-3;
        grid-column-end: col-end;
      `,
      imageRight: css`
        grid-column-start: col-start;
        grid-column-end: col-3;
      `,
      imageBigLeft: css`
        grid-column-start: col-4;
        grid-column-end: col-end;
      `,
      imageBigRight: css`
        grid-column-start: col-start;
        grid-column-end: col-2;
      `,
      imageSmallLeft: css`
        grid-column-start: col-2;
        grid-column-end: col-end;
      `,
      imageSmallRight: css`
        grid-column-start: col-start;
        grid-column-end: col-4;
      `,
    }[sideVariant])}
`

const Header = styled.header`
  padding-bottom: ${SPACE_M}px;
`

const SLoadingComponentDecorator = styled(LoadingDecoratedMedia)`
  height: 100%;

  grid-row-start: row-1-start;
  grid-row-end: row-1-end;

  ${breakpoints({
    SM: css`
      grid-column-start: col-start;
      grid-column-end: col-end;
    `,
    MD: css`
      grid-column-start: col-start;
      grid-column-end: col-end;
    `,
  })}

  ${({ sideVariant }) =>
    ({
      imageLeft: css`
        grid-column-start: col-start;
        grid-column-end: col-3;
      `,
      imageRight: css`
        grid-column-start: col-3;
        grid-column-end: col-end;
      `,
      imageBigLeft: css`
        grid-column-start: col-start;
        grid-column-end: col-4;
      `,
      imageBigRight: css`
        grid-column-start: col-2;
        grid-column-end: col-end;
      `,
      imageSmallLeft: css`
        grid-column-start: col-start;
        grid-column-end: col-2;
      `,
      imageSmallRight: css`
        grid-column-start: col-4;
        grid-column-end: col-end;
      `,
    }[sideVariant])}
`

export function ImageAndTextSlice({ slice, ...otherProps }) {
  const { headline, content, image, video, sideVariant } = slice

  return (
    <ThemeProvider themeName="stone">
      <SRoot
        sideVariant={sideVariant}
        {...getSliceRootElementProps(slice, otherProps, {
          sliceBackgroundVariant: "stone",
        })}
      >
        <SLoadingComponentDecorator
          sideVariant={sideVariant}
          media={video || image}
          autoPlay={true}
          //onLoad={videoAPIOrImage => handleMediaLoad(index, videoAPIOrImage)}
          autoScale={false}
          objectFit="contain"
          objectPosition="left"
        />
        {/* <Image asset={image} onLoad={onLoad} onError={onError} /> */}
        <TextContainer sideVariant={sideVariant}>
          <Header>
            <Text type="secondary">{headline.headline}</Text>
          </Header>
          <Text type="copy">
            <RichText data={content} />
          </Text>
        </TextContainer>
      </SRoot>
    </ThemeProvider>
  )
}
