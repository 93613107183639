import React, { useCallback, useLayoutEffect, useMemo, useRef } from "react"
import styled, { css } from "styled-components"
import { getSliceRootElementProps } from "../utils"
import {
  COLOR_CORAL,
  COLOR_DARK_CORAL,
  COLOR_STONE,
  SPACE_1XL,
  SPACE_3XL,
  SPACE_L,
  SPACE_XL,
} from "../../../styles/primitives"
import {
  RichText,
  SRichTextHeadline1Text,
  SRichTextHeadline2Text,
  SRichTextHeadline3Text,
  SRichTextHeadline4Text,
  SRichTextHeadline5Text,
  SRichTextHeadline6Text,
} from "../../RichText"
import { BREAKPOINT_SM, MAX_WIDTH } from "../../../styles/breakpoints"
import { LoadingDecoratedMedia } from "../../Media"
import { Image } from "../../Image"
import throttle from "lodash/throttle"
import {
  breakpoints,
  COLOR_BACKGROUND_SECONDARY_CORAL,
  ThemeProvider,
  usePageThemeColor,
} from "../../../styles"
import { createScrollbarStyles } from "../../scrollHelpers"
import {
  ANIMATION_DURATION,
  ANIMATION_EASING,
} from "../../../styles/animations"

const MEDIA_ASPECT_RATIO = 1920 / 1080
const MEDIA_ASPECT_RATIO_MOBILE = 272 / 342
const MOBILE_BACKGROUND_HEIGHT_PX = 230

const PLAY_VIDEO_AT_IN_VIEW_RATIO = 0
const REVEAL_NEXT_VIDEO_THRESHOLD_START = 0.5
const REVEAL_NEXT_VIDEO_THRESHOLD_END = 1

const MOBILE_X_SCROLL_NEXT_ITEM_PEEK_PX = 24

export function ParallaxMediaSlice({ slice, ...otherProps }) {
  const { items, backgroundImage } = slice
  const mediaElementsRef = useRef([])
  const videoAPIsRef = useRef([])

  const handleMediaLoad = useCallback((itemIndex, videoAPIOrImage) => {
    videoAPIsRef.current[itemIndex] = videoAPIOrImage
  }, [])

  const handleScrollEvent = useCallback(isMobile => {
    if (isMobile) {
      mediaElementsRef.current.forEach((mediaElement, index) => {
        const mediaElementRect = mediaElement.getBoundingClientRect()

        if (
          mediaElementRect.left < window.innerWidth &&
          mediaElementRect.left + mediaElementRect.width > 0
        ) {
          videoAPIsRef.current[index]?.play?.()
        } else {
          videoAPIsRef.current[index]?.pause?.()
        }
      })
    } else {
      const { top } = mobileXScrollElementRef.current.getBoundingClientRect()
      adjustTranslateAndPlaybackOnScroll(
        mediaElementsRef.current,
        videoAPIsRef.current,
        {
          scrollPx: top,
          viewportSize: window.innerHeight,
          translateProperty: "translateY",
        }
      )
    }
  }, [])

  const handleIsMobileChange = useCallback(newIsMobile => {
    if (newIsMobile) {
      // When we were on non-mobile screens before, we translated the media elements out of the
      // visible area of the clip element. For mobile, this is not happening but keeping the
      // translate transform would produce ill-placed media elements. So, we just clear these.
      mediaElementsRef.current.forEach(mediaElement => {
        mediaElement.style.transform = ""
        mediaElement.style.visibility = ""
      })
    }
  }, [])

  const throttledHandleScrollEvent = useMemo(
    () => throttle(handleScrollEvent, 30),
    [handleScrollEvent]
  )

  const mobileXScrollElementRef = useRef()

  useLayoutEffect(() => {
    let currentTarget
    let scrollListener

    function unlistenScroll() {
      currentTarget?.removeEventListener("scroll", scrollListener)
    }

    function updateTarget() {
      const isMobile = window.innerWidth <= BREAKPOINT_SM
      const newTarget = isMobile ? mobileXScrollElementRef.current : document

      if (newTarget === currentTarget) {
        return
      }

      handleIsMobileChange(isMobile)

      unlistenScroll()

      scrollListener = event => throttledHandleScrollEvent(isMobile, event)

      newTarget.addEventListener("scroll", scrollListener, {
        passive: true,
        capture: false,
      })

      currentTarget = newTarget
    }

    updateTarget()

    window.addEventListener("resize", updateTarget)

    return () => {
      unlistenScroll()
      window.removeEventListener("resize", updateTarget)
    }
  }, [handleIsMobileChange, throttledHandleScrollEvent])

  return (
    <ThemeProvider themeName={"stone"}>
      <Root {...getSliceRootElementProps(slice, otherProps)}>
        <MobileXScrollContent
          numberOfItems={items.length}
          ref={mobileXScrollElementRef}
        >
          <TextTrack numberOfItems={items.length}>
            {items.map((item, index) => (
              <TextItem key={index} index={index} numberOfItems={items.length}>
                <SRichText data={item.text} keepTopLevelParagraph={true} />
              </TextItem>
            ))}
          </TextTrack>
          <MediaTrack>
            <MediaClipContainer numberOfItems={items.length}>
              {items.map((item, index) => (
                <MediaItem
                  key={index}
                  index={index}
                  ref={element => {
                    if (element) {
                      mediaElementsRef.current[index] = element
                    }
                  }}
                >
                  <SMedia
                    media={item.video || item.image}
                    autoPlay={index === 0}
                    onLoad={videoAPIOrImage =>
                      handleMediaLoad(index, videoAPIOrImage)
                    }
                    autoScale={false}
                  />
                </MediaItem>
              ))}
            </MediaClipContainer>
            <DesktopStickyBackground backgroundImage={backgroundImage} />
          </MediaTrack>
        </MobileXScrollContent>
        <MobileStickyBackground backgroundImage={backgroundImage} />
      </Root>
    </ThemeProvider>
  )
}

const Root = styled.section`
  position: relative;
  display: grid;
  background-color: ${({ theme }) => theme.backgroundColor};

  ${breakpoints({
    SM: css`
      padding-bottom: ${SPACE_3XL}px;
    `,
  })}
`

const TextItem = styled.div`
  height: 65vh;
  display: flex;
  flex-direction: column;

  ${breakpoints({
    SM: ({ index }) => css`
      height: auto;
      width: calc(
        100vw - ${SPACE_L}px - ${MOBILE_X_SCROLL_NEXT_ITEM_PEEK_PX}px
      );
      grid-area: row-start / column-start ${index + 1} / row-end / column-end
        ${index + 1};
      padding: ${SPACE_1XL}px ${SPACE_L / 2}px 0;
      scroll-snap-align: center;
    `,
    MD: css`
      padding: ${SPACE_1XL}px ${SPACE_L}px ${SPACE_XL}px;
    `,
    LG: css`
      justify-content: flex-start;
      padding-top: ${SPACE_1XL}px;
    `,
    XL: css`
      justify-content: center;
    `,
  })}
`

const MediaTrack = styled.div`
  grid-area: media-start / media-start / media-end / media-end;
  height: 65vh;

  position: sticky;
  top: 0vh;

  overflow: hidden;

  ${breakpoints({
    SM: ({ numberOfItems }) => css`
      position: relative;
      height: auto;
      overflow: visible;
    `,
    MD: css`
      padding-right: ${SPACE_XL}px;
    `,
    LG: css`
      padding-right: ${SPACE_XL}px;
    `,
    XL: css`
      padding-right: ${calcMaxWidthXPadding(SPACE_XL)};
    `,
  })}
`

const TextTrack = styled.div`
  grid-area: text-start / text-start / text-end / text-end;

  ${breakpoints({
    SM: ({ numberOfItems }) => css`
      padding: 0 ${SPACE_L / 2}px;

      width: calc(
        ${numberOfItems} *
          (100vw - ${SPACE_L}px - ${MOBILE_X_SCROLL_NEXT_ITEM_PEEK_PX}px) +
          ${SPACE_L}px
      );
      display: grid;
      grid-template-columns: repeat(
        ${numberOfItems},
        [column-start] 1fr [column-end]
      );
    `,
    LG: css`
      padding-left: ${SPACE_XL}px;
    `,
    XL: css`
      padding-left: ${calcMaxWidthXPadding(SPACE_XL)};
    `,
  })}
`

const MobileXScrollContent = styled.div`
  display: grid;

  ${breakpoints({
    SM: ({ numberOfItems }) => css`
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      ${createScrollbarStyles()}

      grid-template-rows: [media-start] auto [media-end] 0 [text-start] auto [text-end];
      grid-template-columns: [text-start] 0 [media-start] auto [media-end] auto [text-end];
      padding-bottom: ${SPACE_1XL}px;
    `,
    MD: css`
      grid-template-rows: [text-start] 0 [media-start] auto [media-end] 0 [text-end];
      grid-template-columns: [text-start] 20vw [media-start] 30vw [text-end] 50vw [media-end];
    `,
    LG: css`
      grid-template-rows: [text-start] 0 [media-start] auto [media-end] 0 [text-end];
      grid-template-columns: [text-start] 4fr [media-start] 1fr [text-end] 5fr [media-end];
    `,
    XL: css`
      grid-template-rows: [text-start] 0 [media-start] auto [media-end] 0 [text-end];
      grid-template-columns: [text-start] 1fr [text-end] ${SPACE_1XL}px [media-start] 1fr [media-end];
    `,
  })}
`

const MediaItem = styled.div`
  grid-area: media;
  width: 100%;
  padding-bottom: ${100 / MEDIA_ASPECT_RATIO}%;
  position: relative;
  transition: transform 30ms linear;

  ${({ index }) =>
    css`
      transform: translateY(${index === 0 ? 0 : 100}%);
    `};

  ${breakpoints({
    SM: ({ index }) => css`
      transform: none;
      overflow: hidden;
      margin-left: ${SPACE_L / 2}px;
      margin-right: ${SPACE_L / 2}px;

      height: auto;
      grid-area: row-start / column-start ${index + 1} / row-end / column-end
        ${index + 1};
      width: calc(
        100vw - ${2 * SPACE_L}px - ${MOBILE_X_SCROLL_NEXT_ITEM_PEEK_PX}px
      );
      scroll-snap-align: center;

      height: calc(
        (100vw - ${2 * SPACE_L}px - ${MOBILE_X_SCROLL_NEXT_ITEM_PEEK_PX}px) /
          ${MEDIA_ASPECT_RATIO_MOBILE}
      );
    `,
  })}
`

const SMedia = styled(LoadingDecoratedMedia)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${breakpoints({
    SM: css`
      width: 100%;
      height: 100%;
    `,
  })}
`

const MediaClipContainer = styled.div`
  display: grid;
  grid-template-areas: "media";
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;

  ${breakpoints({
    SM: ({ numberOfItems }) => css`
      top: auto;
      transform: none;

      padding: ${MOBILE_BACKGROUND_HEIGHT_PX / 2}px ${SPACE_L / 2}px 0;

      width: calc(
        ${numberOfItems} *
          (100vw - ${SPACE_L}px - ${MOBILE_X_SCROLL_NEXT_ITEM_PEEK_PX}px) +
          ${SPACE_L}px
      );
      display: grid;
      grid-template-columns: repeat(
        ${numberOfItems},
        [column-start] 1fr [column-end]
      );
    `,
  })}
`

const SImage = styled(Image)`
  height: 100%;
  width: 100%;
`

const StickyBackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30%;
  right: 0;
  z-index: -1;
  overflow: hidden;

  transition: background-color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;

  ${({ theme, hasBackgroundImage }) =>
    (!hasBackgroundImage || theme.pageThemeColor) &&
    css`
      background-color: ${theme.pageThemeColor
        ? theme.pageThemeColor.color
        : COLOR_BACKGROUND_SECONDARY_CORAL};
    `}

  ${breakpoints({
    SM: css`
      height: ${MOBILE_BACKGROUND_HEIGHT_PX}px;
      top: 0;
      left: 0;
      right: 0;
    `,
    MD: css`
      left: 50%;
    `,
  })}
`

const BackgroundCircleClip = styled.div`
  border-radius: 50%;
  position: absolute;
`

const BackgroundStoneCircleClip = styled(BackgroundCircleClip)`
  background-color: ${COLOR_STONE};
  width: 31%;
  padding-top: 31%;
  top: 0;
  left: 10%;
  transform: translateY(-60%);

  ${breakpoints({
    SM: css`
      left: auto;
      right: 10%;
    `,
  })}
`

const BackgroundCoralCircleClip = styled(BackgroundCircleClip)`
  background-color: ${COLOR_DARK_CORAL};
  width: 50%;
  padding-top: 50%;
  bottom: 0;
  right: 0;
  transform: translate(35%, 15%);

  ${breakpoints({
    SM: css`
      right: auto;
      left: 0;
      transform: translate(-35%, 15%);
    `,
  })}
`

function calcMaxWidthXPadding(xPadding) {
  return `calc(max(0px, calc((100vw - ${MAX_WIDTH}px) / 2)) + ${xPadding}px)`
}

const SRichText = styled(RichText)`
  ${SRichTextHeadline1Text},
  ${SRichTextHeadline2Text},
  ${SRichTextHeadline3Text},
  ${SRichTextHeadline4Text},
  ${SRichTextHeadline5Text},
  ${SRichTextHeadline6Text} {
    color: ${({ theme }) =>
      theme.pageThemeColor && theme.pageThemeColor.id !== "MWHT"
        ? theme.pageThemeColor.color
        : COLOR_CORAL};
  }
`

function computeItemInRevealViewRatio(
  containerInViewRatio,
  itemSize,
  itemIndex,
  revealThresholdStart,
  revealThresholdEnd
) {
  const topThresholdForItemRevealStart =
    itemSize * (itemIndex + REVEAL_NEXT_VIDEO_THRESHOLD_START)
  const topThresholdForItemRevealEnd =
    itemSize * (itemIndex + REVEAL_NEXT_VIDEO_THRESHOLD_END)
  return (
    (containerInViewRatio - topThresholdForItemRevealStart) /
    (topThresholdForItemRevealEnd - topThresholdForItemRevealStart)
  )
}

function adjustTranslateAndPlaybackOnScroll(
  mediaElements,
  videoAPIs,
  { scrollPx, viewportSize, translateProperty }
) {
  const xInView = -1 * (scrollPx - viewportSize)
  const itemSize = viewportSize

  mediaElements.forEach((mediaElement, index) => {
    const revealRatio = computeItemInRevealViewRatio(
      xInView,
      itemSize,
      index,
      REVEAL_NEXT_VIDEO_THRESHOLD_START,
      REVEAL_NEXT_VIDEO_THRESHOLD_END
    )
    if (revealRatio >= PLAY_VIDEO_AT_IN_VIEW_RATIO && revealRatio <= 3) {
      videoAPIs[index]?.play?.()
    } else {
      videoAPIs[index]?.pause?.()
    }

    if (index === 0) {
      return
    }

    const newElementTranslate = (1 - revealRatio) * 100
    mediaElement.style.visibility = revealRatio > 0 ? "visible" : "hidden"
    mediaElement.style.transform = `${translateProperty}(${Math.min(
      100,
      Math.max(0, newElementTranslate)
    )}%)`
  })
}

const ENABLE_CIRCLE_CLIP = false

function StickyBackground({ backgroundImage, ...otherProps }) {
  return (
    <StickyBackgroundWrapper
      hasBackgroundImage={!!backgroundImage}
      {...otherProps}
    >
      {backgroundImage && (
        <>
          <SImage asset={backgroundImage} objectFit="cover" />
          {ENABLE_CIRCLE_CLIP && (
            <>
              <BackgroundStoneCircleClip />
              <BackgroundCoralCircleClip />
            </>
          )}
        </>
      )}
    </StickyBackgroundWrapper>
  )
}

const DesktopStickyBackground = styled(StickyBackground)`
  ${breakpoints({
    SM: css`
      display: none;
    `,
  })}
`

const MobileStickyBackground = styled(StickyBackground)`
  display: none;
  ${breakpoints({
    SM: css`
      display: block;
    `,
  })}
`
