import React, { forwardRef, useCallback, useRef } from "react"
import styled, { css } from "styled-components"
import ReactList from "react-list"
import { breakpoints, ThemeProvider } from "../../styles"
import { createScrollbarStyles } from "../../components/scrollHelpers"
import { MapMarkerIcon } from "../../icons/icons"
import {
  COLOR_GRAY_100,
  SPACE_L,
  SPACE_M,
  SPACE_S,
  SPACE_XL,
} from "../../styles/primitives"
import { Text } from "../../components/Text"
import { Input } from "../../components/input"
import { Autocomplete } from "@react-google-maps/api"

export const SearchOverlay = forwardRef(
  (
    {
      locations,
      locationDistanceMap,
      replacementListContent,

      handleInputChange,
      renderLocationItem,
      headline,
      filterInputPlaceholder,
      handleAutoCompleteLoad,
      handleAutoCompletePlaceChanged,
      ...otherProps
    },
    ref
  ) => {
    const inputRef = useRef()
    const renderListItem = useCallback(
      index =>
        renderLocationItem(
          locations[index],
          locationDistanceMap ? locationDistanceMap[locations[index].id] : null
        ),
      [locationDistanceMap, renderLocationItem, locations]
    )

    function onAutocompleteLoad(ref) {
      enableEnterKey(inputRef.current)
      handleAutoCompleteLoad(ref)
    }

    return (
      <ThemeProvider themeName="stone">
        <Container {...otherProps} ref={ref}>
          <HeadlineText type="primary">{headline}</HeadlineText>

          <Autocomplete
            onLoad={onAutocompleteLoad}
            onPlaceChanged={handleAutoCompletePlaceChanged}
          >
            <StyledInput
              ref={inputRef}
              placeholder={filterInputPlaceholder}
              leftAppend={<MapMarkerIcon />}
            />
          </Autocomplete>

          <ScrollList>
            {replacementListContent ? (
              replacementListContent
            ) : (
              <ReactList
                itemRenderer={renderListItem}
                type="variable"
                length={locations.length}
                itemSizeEstimator={itemSizeEstimator}
              />
            )}
          </ScrollList>
        </Container>
      </ThemeProvider>
    )
  }
)

function itemSizeEstimator() {
  return 130
}

const Container = styled.section`
  background-color: ${COLOR_GRAY_100};
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  height: 100%;

  ${breakpoints({
    SM: css`
      padding: ${SPACE_XL}px ${SPACE_L}px;
      height: 100%;
    `,
    MD: css`
      padding: ${SPACE_XL}px ${SPACE_L}px;
    `,
    LG: css`
      padding: ${40}px ${40}px;
      height: 100%;
    `,
    XL: css`
      padding: ${40}px ${40}px;
      height: 100%;
    `,
  })}
`

const HeadlineText = styled(Text)`
  margin-bottom: ${SPACE_L}px;

  ${breakpoints({
    SM: css`
      margin-bottom: ${SPACE_M}px;
    `,
  })}
`

const StyledInput = styled(Input)`
  background-color: ${COLOR_GRAY_100};
  margin-top: ${SPACE_XL}px;
`

const ScrollList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  margin-top: ${SPACE_M}px;

  overflow-y: auto;
  overflow-x: hidden;

  ${createScrollbarStyles("4px")};
  padding-right: ${SPACE_S}px;

  height: 100%;
`

/**
 * Workaround to select the first address when you hit enter.
 * It does so by overriding the event listener ofr the input.
 * Source: https://stackoverflow.com/questions/7865446/google-maps-places-api-v3-autocomplete-select-first-option-on-enter
 */
function enableEnterKey(input) {
  /* Store original event listener */
  const _addEventListener = input.addEventListener

  const addEventListenerWrapper = (type, listener) => {
    if (type === "keydown") {
      /* Store existing listener function */
      const _listener = listener
      listener = event => {
        /* Simulate a 'down arrow' keypress if no address has been selected */
        const suggestionSelected = document.getElementsByClassName(
          "pac-item-selected"
        ).length
        if (event.key === "Enter" && !suggestionSelected) {
          const e = new KeyboardEvent("keydown", {
            key: "ArrowDown",
            code: "ArrowDown",
            keyCode: 40,
          })
          _listener.apply(input, [e])
        }
        _listener.apply(input, [event])
      }
    }
    _addEventListener.apply(input, [type, listener])
  }

  input.addEventListener = addEventListenerWrapper
}
