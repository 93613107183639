import React from "react"
import styled, { css } from "styled-components"
import { useLocation } from "@reach/router"

import { breakpoints, ThemeProvider } from "../../../styles"
import { MAX_WIDTH } from "../../../styles/breakpoints"
import {
  COLOR_BLUE,
  COLOR_STONE,
  SPACE_1XL,
  SPACE_2XL,
  SPACE_3XL,
  SPACE_M,
  SPACE_S,
  SPACE_XL,
} from "../../../styles/primitives"
import { CTAButton } from "../../CTAButton"

import { Text } from "../../Text"
import { LoadingDecoratedMedia, Media } from "../../Media"
import { HEADER_HEIGHTS } from "../../Header"
import { RichText, SRichTextLink } from "../../RichText"
import { resolveLink } from "../../../utils/linkResolver"
import { useLocale } from "../../../locale"
import {
  ANIMATION_DURATION,
  ANIMATION_EASING,
} from "../../../styles/animations"
import { PageThemeColorSelector } from "./PageThemeColorSelector"

function createGridRows(bottomPadding) {
  return css`
    grid-template-rows: [top] auto [headline-start] min-content [headline-end] 0 [cta-start] min-content [cta-end] ${bottomPadding} [bottom];
  `
}

function createGridColumns(outerPadding) {
  return css`
    grid-template-columns: [left] ${outerPadding} [content-start] auto [content-end] ${outerPadding} [right];
  `
}

const Container = styled.div`
  position: relative;
  display: grid;

  ${breakpoints({
    SM: css`
      ${createGridRows(`${SPACE_1XL}px`)}
      ${createGridColumns(`${SPACE_XL}px`)}
    `,
    MD: css`
      ${createGridRows(`${SPACE_3XL}px`)}
      ${createGridColumns(`${SPACE_XL}px`)}
    `,
    LG: css`
      ${createGridRows(`${SPACE_XL}px`)}
      ${createGridColumns(`${SPACE_XL}px`)}
    `,
    XL: css`
      ${createGridRows(`${SPACE_2XL}px`)}
      ${createGridColumns(`${SPACE_XL}px`)}
    `,
    MAX: css`
      ${createGridRows(`${SPACE_2XL}px`)}
      ${createGridColumns(`calc((100% - ${MAX_WIDTH - SPACE_XL * 2}px) / 2)`)}
    `,
  })}
`

const HeadlineText = styled(Text)`
  grid-row-start: headline-start;
  grid-row-end: headline-end;
  grid-column-start: content-start;
  grid-column-end: content-end;
  white-space: pre-wrap;
  z-index: 4;
`

const SublineText = styled(Text)`
  margin-top: ${SPACE_XL}px;
  white-space: pre-wrap;
`

const CTAContainer = styled.div`
  grid-row-start: cta-start;
  grid-row-end: cta-end;
  grid-column-start: content-start;
  grid-column-end: content-end;
  z-index: 4;

  display: flex;

  > * {
    margin-right: ${SPACE_M}px;
  }

  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_XL}px;
      flex-wrap: wrap;
      > * {
        flex: 0 0 100%;
        margin-top: ${SPACE_S}px;
      }
    `,
    MD: css`
      margin-top: ${SPACE_XL}px;
    `,
    LG: css`
      margin-top: ${SPACE_2XL}px;
    `,
    XL: css`
      margin-top: ${SPACE_2XL}px;
    `,
  })}
`

const SLoadingDecoratedMedia = styled(LoadingDecoratedMedia)`
  grid-row-start: top;
  grid-row-end: bottom;
  grid-column-start: left;
  grid-column-end: right;
  z-index: 1;

  ${({ theme }) =>
    theme.pageThemeColor &&
    css`
      z-index: 2;
    `};

  ${breakpoints({
    SM: css`
      height: calc(100vh - ${HEADER_HEIGHTS.SM}px);
    `,
    MD: css`
      height: calc(100vh - ${HEADER_HEIGHTS.MD}px);
    `,
    LG: css`
      height: calc(100vh - ${HEADER_HEIGHTS.LG}px);
    `,
    XL: css`
      height: calc(100vh - ${HEADER_HEIGHTS.XL}px);
    `,
    MAX: css`
      height: calc(100vh - ${HEADER_HEIGHTS.XL}px);
    `,
  })}
`
const SMedia = styled(Media)`
  grid-row-start: top;
  grid-row-end: bottom;
  grid-column-start: left;
  grid-column-end: right;
  z-index: 1;

  ${({ theme }) =>
    theme.pageThemeColor &&
    css`
      z-index: 2;
    `};

  ${breakpoints({
    SM: css`
      height: calc(100vh - ${HEADER_HEIGHTS.SM}px);
    `,
    MD: css`
      height: calc(100vh - ${HEADER_HEIGHTS.MD}px);
    `,
    LG: css`
      height: calc(100vh - ${HEADER_HEIGHTS.LG}px);
    `,
    XL: css`
      height: calc(100vh - ${HEADER_HEIGHTS.XL}px);
    `,
    MAX: css`
      height: calc(100vh - ${HEADER_HEIGHTS.XL}px);
    `,
  })}
`
const GradientOverlay = styled.div`
  grid-row-start: top;
  grid-row-end: bottom;
  grid-column-start: left;
  grid-column-end: right;
  transition: background-color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;

  ${({ theme }) =>
    theme.pageThemeColor
      ? css`
          z-index: 1;
          background-color: ${theme.pageThemeColor.color};
        `
      : css`
          z-index: 2;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.6) 0%,
            rgba(0, 0, 0, 0.4023984593837535) 30%,
            rgba(0, 0, 0, 0) 60%
          );
        `}
`

export function AboveTheFoldSplash({
  ctaButtons,
  media,
  headline,
  headlineIsH1,
  subline,
  variantLinks,
  pageThemeColorSelectorLabel,
  pageThemeColorSelectorLinks,
  disableColorChange,
}) {
  return (
    <ThemeProvider themeName="coral">
      <Container>
        {disableColorChange ? (
          <SMedia media={media} />
        ) : (
          <SLoadingDecoratedMedia media={media} />
        )}
        <GradientOverlay />
        <HeadlineText type={headlineIsH1 ? "heroH1" : "hero"}>
          {headline.headline}
          {subline?.subline && (
            <SublineText type="subHeadline">{subline.subline}</SublineText>
          )}
        </HeadlineText>
        {variantLinks && (
          <SVariantLinksRichText
            data={variantLinks}
            components={variantLinksRichTextComponents}
          />
        )}
        <CTAContainer>
          {ctaButtons &&
            ctaButtons.map((buttonCTA, index) => (
              <CTAButton cta={buttonCTA} key={index} />
            ))}
        </CTAContainer>

        {pageThemeColorSelectorLabel && pageThemeColorSelectorLinks && (
          <SPageThemeColorSelector
            label={pageThemeColorSelectorLabel}
            links={pageThemeColorSelectorLinks}
          />
        )}
      </Container>
    </ThemeProvider>
  )
}

const SVariantLinksRichText = styled(RichText)`
  position: absolute;
  top: ${SPACE_XL}px;
  right: ${SPACE_XL}px;
  transform: rotate(-8deg);
  transform-origin: bottom right;
  z-index: 4;
`

const SVariantLinksRichTextLink = styled(SRichTextLink)`
  text-decoration: none;
  display: block;
  transition: transform ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
  transform-origin: center right;
  transform: scale(1);
  padding: 0.5em;
  margin: -0.5em;
  font-weight: 600;

  &:hover {
    transform: scale(1.2);
  }
`

function RichTextVariantLink({ to, ...otherProps }) {
  const { slugPrefix } = useLocale()
  const href = typeof to === "object" ? resolveLink(slugPrefix, to) : null
  const location = useLocation()
  const locationPathnameWithoutTrailingSlash = location.pathname.replace(
    /\/$/,
    ""
  )
  const hrefWithoutTrailingSlash = href.replace(/\/$/, "")

  return (
    <SVariantLinksRichTextLink
      to={to}
      style={{
        color:
          hrefWithoutTrailingSlash === locationPathnameWithoutTrailingSlash
            ? COLOR_BLUE
            : COLOR_STONE,
      }}
      {...otherProps}
    />
  )
}

const variantLinksRichTextComponents = {
  Link: RichTextVariantLink,
}

const SPageThemeColorSelector = styled(PageThemeColorSelector)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
`
