import React, { useMemo } from "react"
import { MetaText, Text } from "../../../components/Text"
import { LocationOverlay } from "../../../components/map/LocationOverlay"
import { GarageDetails } from "./GarageDetails"
import { usePrettyDistance } from "../../map/usePrettyDistance"
import { useLocale } from "../../../locale"
import { SPACE_S } from "../../../styles/primitives"
import styled from "styled-components"
import { interpolateVariablesInTranslation } from "../../../globalValues"
import { COLOR_TEXT_DISABLED } from "../../../styles"

export function GarageMarkerOverlay({
  slice,
  distance,
  location,
  requestClose,
}) {
  const { locale } = useLocale()
  const prettyDistance = usePrettyDistance(locale, distance)
  const distanceTextLine = useMemo(
    () =>
      prettyDistance
        ? interpolateVariablesInTranslation(slice.markerOverlayDistanceText, {
            distance: prettyDistance,
          })
        : null,
    [prettyDistance, slice.markerOverlayDistanceText]
  )

  return (
    <LocationOverlay
      topRow={
        <Text type="copyStrongSmall">
          {location.data.name || location.displayText}
        </Text>
      }
      location={location}
      requestClose={requestClose}
    >
      <GarageDetails
        location={location}
        pickUpAvailableLabel={slice.pickUpAvailableLabel}
        unuScooterClassicOnlyLabel={slice.unuScooterClassicOnlyLabel}
      />

      <SDistanceText>{distanceTextLine}</SDistanceText>
    </LocationOverlay>
  )
}

const SDistanceText = styled(MetaText)`
  display: block;
  margin-top: ${SPACE_S}px;
  color: ${COLOR_TEXT_DISABLED};
`
