import React from "react"
import styled, { css } from "styled-components"
import {
  breakpoints,
  COLOR_TEXT_PRIMARY,
  COLOR_UI_BACKGROUND_INVERTED,
  COLOR_UI_BACKGROUND_PRIMARY,
  ThemeProvider,
} from "../../styles"
import {
  FONT_SIZE_9XL,
  FONT_SIZE_3XL,
  SPACE_M,
  SPACE_TOKEN_INLINE_3XL,
  SPACE_TOKEN_INLINE_L,
  SPACE_TOKEN_INLINE_XL,
  SPACE_TOKEN_STACK_1XL,
  SPACE_TOKEN_STACK_2XL,
  SPACE_TOKEN_STACK_3XL,
  SPACE_TOKEN_STACK_4XL,
  SPACE_TOKEN_STACK_L,
  SPACE_TOKEN_STACK_M,
  SPACE_TOKEN_STACK_XL,
  SPACE_XL,
  SPACE_L,
  COLOR_CORAL,
  COLOR_GREEN,
  COLOR_STONE,
} from "../../styles/primitives"
import { Image } from "../Image"
import { RichText } from "../RichText"
import { Text } from "../Text"
import {
  useCarrouselSwitching,
  getProgressText,
  SWITCH_DURATION,
  ImageCarrouselBox,
  CarrouselArrowButton,
} from "../imageCarrousel"
import { SliceContainer } from "./sliceContainers"
import { getSliceRootElementProps } from "./utils"

const StyledSliceContainer = styled(SliceContainer)`
  position: relative;

  ${breakpoints({
    LG: css`
      padding-left: ${SPACE_TOKEN_INLINE_XL}px;
      padding-right: ${SPACE_TOKEN_INLINE_XL}px;
    `,
    XL: css`
      padding-left: ${SPACE_TOKEN_INLINE_3XL}px;
      padding-right: ${SPACE_TOKEN_INLINE_3XL}px;
    `,
  })}
`
const ActivationBackground = styled.div`
  ${({ bgcolor }) =>
    ({
      coral: css`
        background-color: ${COLOR_CORAL};
      `,
      stone: css`
        background-color: ${COLOR_STONE};
      `,
      green: css`
        background-color: ${COLOR_GREEN};
      `,
    }[bgcolor])};

  position: absolute;
  left: 0;

  ${breakpoints({
    SM: css`
      height: ${SPACE_TOKEN_STACK_2XL}px;
      width: 100%;
    `,
    MD: css`
      height: ${SPACE_TOKEN_STACK_2XL}px;
      width: 100%;
    `,
    LG: css`
      top: 0;
      width: 50%;
      height: 100%;
    `,
    XL: css`
      top: 0;
      width: 50%;
      height: 100%;
    `,
    MAX: css`
      top: 0;
      width: 50%;
      height: 100%;
    `,
  })}
`
const Caption = styled(Text)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  ${breakpoints({
    SM: css`
      padding-top: ${SPACE_TOKEN_STACK_XL}px;
      padding-bottom: ${SPACE_TOKEN_STACK_2XL}px;
      padding-left: ${SPACE_L}px;
      padding-right: ${SPACE_L}px;
    `,
    MD: css`
      min-height: 212px;
      padding-top: ${SPACE_TOKEN_STACK_1XL}px;
      padding-bottom: ${SPACE_TOKEN_STACK_1XL}px;
      padding-left: ${SPACE_L}px;
      padding-right: ${SPACE_L}px;
    `,
    LG: css`
      min-height: ${SPACE_TOKEN_STACK_3XL}px;
      padding-top: ${SPACE_TOKEN_STACK_M}px;
      padding-bottom: ${SPACE_TOKEN_STACK_M}px;
      padding-left: calc(50% + ${SPACE_TOKEN_STACK_L}px);
    `,
    XL: css`
      min-height: ${SPACE_TOKEN_STACK_4XL}px;
      padding-top: ${SPACE_TOKEN_STACK_XL}px;
      padding-bottom: ${SPACE_TOKEN_STACK_XL}px;
      padding-left: calc(50% + ${SPACE_TOKEN_INLINE_L}px);
    `,
    MAX: css`
      min-height: ${SPACE_TOKEN_STACK_4XL}px;
      padding-top: ${SPACE_TOKEN_STACK_XL}px;
      padding-bottom: ${SPACE_TOKEN_STACK_XL}px;
      padding-left: calc(50% + ${SPACE_TOKEN_STACK_4XL}px);
    `,
  })}
`

const StyledImageWrapper = styled.div`
  position: relative;
`

const InnerContainer = styled.div`
  position: relative;
  z-index: 0;

  ${breakpoints({
    SM: css`
      padding-top: ${SPACE_TOKEN_STACK_2XL}px;
      padding-bottom: ${SPACE_TOKEN_STACK_2XL}px;
    `,
    MD: css`
      padding-top: ${SPACE_TOKEN_STACK_2XL}px;
    `,
    LG: css`
      padding-top: ${SPACE_TOKEN_STACK_3XL}px;
    `,
    XL: css`
      padding-top: ${SPACE_TOKEN_STACK_4XL}px;
    `,
  })}
`

const IMAGE_OVERLAY_HEIGHT = 88

const ImageOverlay = styled.div`
  position: relative;
  left: 0;
  display: flex;
  align-items: center;

  ${breakpoints({
    SM: css`
      position: relative;
      padding: ${SPACE_M}px;
    `,
    MD: css`
      position: relative;
      padding: ${SPACE_M}px;
    `,
    LG: css`
      margin-top: -${IMAGE_OVERLAY_HEIGHT + SPACE_XL}px;
      margin-bottom: ${SPACE_XL}px;
      padding: 0 ${SPACE_XL}px;
    `,
    XL: css`
      margin-top: -${IMAGE_OVERLAY_HEIGHT + SPACE_XL}px;
      margin-bottom: ${SPACE_XL}px;
      padding: 0 ${SPACE_XL}px;
    `,
    MAX: css`
      margin-top: -${IMAGE_OVERLAY_HEIGHT + SPACE_XL}px;
      margin-bottom: ${SPACE_XL}px;
      padding: 0 ${SPACE_XL}px;
    `,
  })};
`

const CounterText = styled(Text)`
  flex: 1;
  font-size: ${FONT_SIZE_9XL}rem;
  color: ${COLOR_UI_BACKGROUND_INVERTED};
  line-height: 1em;

  ${breakpoints({
    SM: css`
      color: ${COLOR_TEXT_PRIMARY};
      font-size: ${FONT_SIZE_3XL}rem;
    `,
    MD: css`
      color: ${COLOR_TEXT_PRIMARY};
      font-size: ${FONT_SIZE_3XL}rem;
    `,
  })};
`

const StyledCarrouselArrowButton = styled(CarrouselArrowButton)`
  margin-left: ${SPACE_M}px;
`

export function ImageCarrouselSlice({ slice }) {
  const { showDescription, isActivated, bgcolor, images, caption, duration } = slice

  const carrouselState = useCarrouselSwitching(images, duration)
  const { currentItemIndex } = carrouselState
  const currentImage = images[currentItemIndex]

  return (
    <StyledSliceContainer {...getSliceRootElementProps(slice)}>
      <ActivationBackground isActivated={isActivated} bgcolor={bgcolor} />
      <InnerContainer>
        <StyledImageWrapper>
          <ImageCarrouselBox currentItemKey={currentItemIndex.toString()} duration={duration}>
            <Image asset={currentImage} />
          </ImageCarrouselBox>
        </StyledImageWrapper>
        <ImageOverlay>
          <CounterText type="meta">
            {getProgressText(currentItemIndex, images.length)}
          </CounterText>
          <ThemeProvider themeName="dark">
            <StyledCarrouselArrowButton
              carrouselState={carrouselState}
              switchDuration={SWITCH_DURATION}
              reverse={true}
            />
            <StyledCarrouselArrowButton
              carrouselState={carrouselState}
              switchDuration={SWITCH_DURATION}
            />
          </ThemeProvider>
        </ImageOverlay>
        <Caption type="copy">
          <RichText
            data={
              showDescription
                ? {
                    raw: {
                      data: {},
                      content: [
                        {
                          data: {},
                          content: [
                            {
                              data: {},
                              marks: [],
                              value: currentImage.description,
                              nodeType: "text",
                            },
                          ],
                          nodeType: "paragraph",
                        },
                      ],
                      nodeType: "document",
                    },
                    references: [],
                  }
                : caption
            }
          />
        </Caption>
      </InnerContainer>
    </StyledSliceContainer>
  )
}
