import React from "react"
import styled, { keyframes } from "styled-components"
import {} from "../styles"
import { ANIMATION_DURATION } from "../styles/animations"

const SIZE = 88
const STROKE_WIDTH = 3
const RADIUS = SIZE / 2 - STROKE_WIDTH / 2
const CIRCUMFERENCE = Math.PI * (RADIUS * 2)

const animationKeyframes = {
  fillingCircle: keyframes`
    0% {
      stroke-dashoffset: ${CIRCUMFERENCE};
    }
    50% {
      stroke-dashoffset:0;
    }
    100% {
      stroke-dashoffset: ${-CIRCUMFERENCE};
    }
  `,
  unfillingCircle: keyframes`
    0% {
      stroke-dashoffset:0;
      transform: rotate(-90deg);
    }
    50% {
      stroke-dashoffset: ${CIRCUMFERENCE};
      transform: rotate(${-90 + 360}deg);
    }
    100% {
      stroke-dashoffset:${2 * CIRCUMFERENCE};
      transform: rotate(${-90 + 360 + 360}deg);
    }
  `,
}

const ChildrenContainer = styled.div`
  position: absolute;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  transition: background-color linear ${ANIMATION_DURATION}ms;
`

const SVGRoot = styled.svg`
  display: block;
  width: 100%;
  height: 100%;
  stroke: ${({ theme }) => theme.backgroundColor};
`

const StyledFillCircle = styled.circle`
  animation-name: ${animationKeyframes.fillingCircle};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: paused;

  stroke: ${({ theme }) => theme.links.defaultColor};

  transform: rotate(-90deg);
  transform-origin: center;
  transition: transform linear ${500}ms;
}
`
const StyledUnfillCircle = styled.circle`
  animation-name: ${animationKeyframes.unfillingCircle};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: paused;

  stroke: ${({ theme }) => theme.textColor};

  transform-origin: center;
  transition: transform linear ${500}ms;
`

const Container = styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    > ${SVGRoot} {
      stroke: ${({ theme }) => theme.textColor};
    }
    > ${ChildrenContainer} {
      background-color: ${({ theme }) => theme.textColor};
    }
  }
`

export function ProgressCircle({
  autoFillCycleDuration,
  children,
  ...otherProps
}) {
  return (
    <Container {...otherProps}>
      <SVGRoot
        width={SIZE}
        height={SIZE}
        viewBox={`0 0 ${SIZE} ${SIZE}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <StyledUnfillCircle
          cx={SIZE / 2}
          cy={SIZE / 2}
          r={RADIUS}
          fill="none"
          strokeWidth={STROKE_WIDTH}
          strokeDasharray={`${CIRCUMFERENCE} ${CIRCUMFERENCE}`}
          style={{
            animationDuration: `${autoFillCycleDuration * 2}ms`,
          }}
        />
        <StyledFillCircle
          cx={SIZE / 2}
          cy={SIZE / 2}
          r={RADIUS}
          fill="none"
          strokeWidth={STROKE_WIDTH}
          strokeDasharray={`${CIRCUMFERENCE} ${CIRCUMFERENCE}`}
          style={{
            animationDuration: `${autoFillCycleDuration * 2}ms`,
          }}
        />
      </SVGRoot>
      <ChildrenContainer duration={ANIMATION_DURATION}>
        {children}
      </ChildrenContainer>
    </Container>
  )
}
