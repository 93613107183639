import React, { useCallback, useState } from "react"
import styled, { css } from "styled-components"
import AnimateHeight from "react-animate-height"

import { Text } from "../../Text"
import { breakpoints, useTheme } from "../../../styles"
import { MinusIcon } from "../../../icons/icons"

import {
  SPACE_L,
  SPACE_XL,
  SPACE_TOKEN_STACK_M,
  SPACE_TOKEN_STACK_L,
  SPACE_TOKEN_STACK_XL,
  SPACE_TOKEN_STACK_1XL,
} from "../../../styles/primitives"
import {
  ANIMATION_DURATION,
  ANIMATION_EASING,
} from "../../../styles/animations"
import { RichText } from "../../RichText"

const StyledTextContainer = styled.header`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${breakpoints({
    SM: css`
      padding-top: ${SPACE_L}px;
      padding-bottom: ${SPACE_L}px;
    `,
    MD: css`
      padding-top: ${SPACE_L}px;
      padding-bottom: ${SPACE_L}px;
    `,
    LG: css`
      padding-top: ${SPACE_L}px;
      padding-bottom: ${SPACE_L}px;
    `,
    XL: css`
      padding-top: ${SPACE_XL}px;
      padding-bottom: ${SPACE_XL}px;
    `,
  })};
`

const StyledText = styled(Text)`
  flex: 1;
`

const ContentContainer = styled.div`
  padding-top: ${SPACE_TOKEN_STACK_M}px;

  ${breakpoints({
    SM: css`
      padding-bottom: ${SPACE_TOKEN_STACK_L}px;
    `,
    MD: css`
      padding-bottom: ${SPACE_TOKEN_STACK_XL}px;
    `,
    LG: css`
      padding-bottom: ${SPACE_TOKEN_STACK_XL}px;
    `,
    XL: css`
      padding-bottom: ${SPACE_TOKEN_STACK_1XL}px;
    `,
  })}
`

export function AccordionItem({ item }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleIsExpanded = useCallback(
    () => setIsExpanded(current => !current),
    []
  )

  const { theme } = useTheme()

  return (
    <>
      <StyledTextContainer onClick={toggleIsExpanded}>
        <StyledText type="tertiary">{item.title}</StyledText>
        <IconContainer isExpanded={isExpanded}>
          <SMinusIcon color={theme.iconColor} />
          <SMinusIcon color={theme.iconColor} />
        </IconContainer>
      </StyledTextContainer>
      <AnimateHeight
        height={isExpanded ? "auto" : 0}
        easing={ANIMATION_EASING}
        duration={ANIMATION_DURATION}
      >
        <ContentContainer>
          <RichText data={item.content} keepTopLevelParagraph={true} />
        </ContentContainer>
      </AnimateHeight>
    </>
  )
}

const SMinusIcon = styled(MinusIcon)`
  transition: transform 300ms ${ANIMATION_EASING};
`

const IconContainer = styled.div`
  position: relative;

  ${SMinusIcon}:nth-child(1) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: rotate(90deg);
  }

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      ${SMinusIcon}:nth-child(1) {
        transform: rotate(180deg);
      }

      ${SMinusIcon}:nth-child(2) {
        transform: rotate(180deg);
      }
    `}
`
