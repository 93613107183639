import React from "react"
import styled, { css } from "styled-components"

import { breakpoints } from "../../styles"
import { MAX_WIDTH } from "../../styles/breakpoints"
import {
  SPACE_TOKEN_INLINE_1XL,
  SPACE_TOKEN_INLINE_L,
} from "../../styles/primitives"

const SliceRoot = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MaxWidth = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH}px;
`

export const horizontalSlicePaddings = {
  SM: css`
    padding-left: ${SPACE_TOKEN_INLINE_L}px;
    padding-right: ${SPACE_TOKEN_INLINE_L}px;
  `,
  MD: css`
    padding-left: ${SPACE_TOKEN_INLINE_1XL}px;
    padding-right: ${SPACE_TOKEN_INLINE_1XL}px;
  `,
  LG: css`
    padding-left: ${SPACE_TOKEN_INLINE_1XL}px;
    padding-right: ${SPACE_TOKEN_INLINE_1XL}px;
  `,
  XL: css`
    padding-left: ${SPACE_TOKEN_INLINE_1XL}px;
    padding-right: ${SPACE_TOKEN_INLINE_1XL}px;
  `,
}

export function SliceContainer({ children, ...otherProps }) {
  return (
    <SliceRoot {...otherProps}>
      <MaxWidth>{children}</MaxWidth>
    </SliceRoot>
  )
}
