import React, { useMemo } from "react"
import { memo } from "react"
import styled, { css } from "styled-components"
import { breakpoints } from "../../../styles"
import { COLOR_BLUE, COLOR_GRAY_100 } from "../../../styles/primitives"
import { MARKER_SIZES_PX } from "./config"

export const MarkerLocation = memo(({ isVisible, ...otherProps }) => {
  const scaleTransformStyle = useMemo(
    () => ({
      transform: isVisible ? "scale(1)" : "scale(0)",
    }),
    [isVisible]
  )

  return (
    <SMarkerPositionRoot {...otherProps}>
      <SMarkerVisibleRoot style={scaleTransformStyle}>
        <SMarkerLocationDot />
      </SMarkerVisibleRoot>
    </SMarkerPositionRoot>
  )
})

const SMarkerPositionRoot = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
`

const SMarkerVisibleRoot = styled.div`
  ${breakpoints({
    SM: css`
      width: ${MARKER_SIZES_PX.SM}px;
      height: ${MARKER_SIZES_PX.SM}px;
    `,
    MD: css`
      width: ${MARKER_SIZES_PX.MD}px;
      height: ${MARKER_SIZES_PX.MD}px;
    `,
    LG: css`
      width: ${MARKER_SIZES_PX.LG}px;
      height: ${MARKER_SIZES_PX.LG}px;
    `,
    XL: css`
      width: ${MARKER_SIZES_PX.XL}px;
      height: ${MARKER_SIZES_PX.XL}px;
    `,
  })}

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: ${COLOR_GRAY_100};
  filter: drop-shadow(-2px 6px 12px rgba(0, 0, 0, 0.25));
  transform: scale(0);
  transition: transform cubic-bezier(0.53, 1.87, 0.68, 0.92) ${400}ms;
`

const SMarkerLocationDot = styled.div`
  width: ${(12 / 44) * 100}%;
  height: ${(12 / 44) * 100}%;
  border-radius: 50%;
  background-color: ${COLOR_BLUE};
`
