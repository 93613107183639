import React, { useCallback, useState } from "react"
import styled, { css } from "styled-components"

import { getSliceRootElementProps } from "../utils"
import { breakpoints, ThemeProvider, usePageThemeColor } from "../../../styles"
import { MAX_WIDTH } from "../../../styles/breakpoints"
import {
  COLOR_GREEN,
  COLOR_CORAL,
  SPACE_1XL,
  SPACE_2XL,
  SPACE_3XL,
  SPACE_4XL,
  SPACE_M,
  SPACE_L,
  SPACE_XL,
  SPACE_TOKEN_STACK_XS,
  SPACE_TOKEN_STACK_S,
  SPACE_TOKEN_STACK_M,
  SPACE_TOKEN_STACK_XL,
} from "../../../styles/primitives"
import { Image } from "../../Image"
import { Text } from "../../Text"
import { Topline } from "../../Topline"
import { useColorSelectorState } from "../../ColorSelector"
import { CTAButton } from "../../CTAButton"
import { ScooterModelControls } from "./ScooterModelControls"
import {
  ScooterModel,
  MODEL_ASPECT_RATIO,
  useColorIdsFromScooterImages,
} from "./ScooterModel"
import { modelColorsBySKUColorId } from "../../../styles/scooterModelColors"
import {
  ANIMATION_DURATION,
  ANIMATION_EASING,
} from "../../../styles/animations"
import { RichText } from "../../RichText"

const themeNameByPageThemeColor = {
  MPNK: "coral",
  GBLU: "green",
  GRED: "coral",
  MGRN: "green",
  MWHT: "stone",
  MBGE: "coral",
  MBLK: "dark",
}

export function Scooter3DModelSlice({ slice, ...otherProps }) {
  const {
    backgroundImage,
    headline,
    topline,
    scooterImages,
    customCta,
    logoTopLeft,
    bubbleMainText,
  } = slice

  const scooterColors = useColorIdsFromScooterImages(scooterImages).map(
    skuColorId => {
      return modelColorsBySKUColorId[skuColorId]
    }
  )

  const colorSelectorState = useColorSelectorState(scooterColors)
  const pageThemeColor = usePageThemeColor()

  return (
    <ThemeProvider
      themeName={
        pageThemeColor ? themeNameByPageThemeColor[pageThemeColor.id] : "green"
      }
    >
      <Root {...getSliceRootElementProps(slice)}>
        <SBackgroundImageWrapper>
          <SLeftContainer>
            {logoTopLeft && <Image asset={logoTopLeft} />}
          </SLeftContainer>

          <SBackgroundImage asset={backgroundImage} />
        </SBackgroundImageWrapper>
        <SRightContainer>
          {bubbleMainText && (
            <TextBubble>
              <RichText data={bubbleMainText} />
            </TextBubble>
          )}
        </SRightContainer>
        <SBackgroundColor />
        <STexts>
          <STopline topline={topline} />
          <SHeadlineText type="hero">{headline.headline}</SHeadlineText>
        </STexts>
        <SScooterModelWrapper>
          <SScooterModel
            scooterModelSlice={slice}
            currentColorId={
              pageThemeColor
                ? pageThemeColor.id
                : scooterColors[colorSelectorState.currentColorIndex].id
            }
          />
        </SScooterModelWrapper>
        <ThemeProvider themeName="stone">
          {customCta ? (
            <SCustomCTAButton cta={customCta} />
          ) : (
            <SScooterModelControls
              slice={slice}
              colorSelectorState={colorSelectorState}
            />
          )}
        </ThemeProvider>
      </Root>
    </ThemeProvider>
  )
}

function createGridRows(bgImageTopSpacing, controlsYPullOntoModel) {
  return css`
    grid-template-rows:
      [bgimage-start] ${bgImageTopSpacing}
      [bgcolor-start] 0
      [texts-start] max-content
      [model-start] ${SPACE_2XL}px
      [texts-end] max-content
      [bgcolor-end] 0 [bgimage-end] ${SPACE_3XL}px
      [controls-start] ${controlsYPullOntoModel} [model-end] auto [controls-end];
  `
}

function createGridColumns(bgImageSpacing) {
  return css`
    grid-template-columns:
      [bgimage-start] calc(
        max(0px, (100vw - ${MAX_WIDTH}px) / 2) + ${bgImageSpacing}
      )
      [content-start] auto [content-end] calc(
        max(0px, (100vw - ${MAX_WIDTH}px) / 2) + ${bgImageSpacing}
      )
      [bgimage-end];
  `
}

const Root = styled.section`
  display: grid;
  z-index: 0;

  ${breakpoints({
    SM: css`
      ${createGridRows("80px", `${SPACE_L}px`)};
      ${createGridColumns(`0px`)};
      padding-bottom: ${SPACE_XL}px;
    `,
    MD: css`
      ${createGridRows("166px", `${SPACE_XL}px`)};
      ${createGridColumns(`${SPACE_L}px`)};
      padding-bottom: ${SPACE_4XL}px;
    `,
    LG: css`
      ${createGridRows("264px", `${SPACE_1XL}px`)};
      ${createGridColumns(`${SPACE_XL}px`)};
      padding-bottom: ${SPACE_4XL}px;
    `,
    XL: css`
      ${createGridRows("402px", `${SPACE_2XL}px`)};
      ${createGridColumns(`${SPACE_XL}px`)};
      padding-bottom: ${SPACE_4XL}px;
    `,
  })}
`

const SLeftContainer = styled.div`
  position: absolute;
  z-index: 1;

  ${breakpoints({
    SM: css`
      left: 12px;
      top: 12px;
      width: 107.8px;
    `,
    MD: css`
      left: ${SPACE_L}px;
      top: ${SPACE_L}px;
      width: 150px;
    `,
    LG: css`
      left: ${SPACE_XL}px;
      top: ${SPACE_XL}px;
      width: 250px;
    `,
    XL: css`
      left: calc(max(0px, (100vw - ${MAX_WIDTH}px) / 2) + ${SPACE_XL}px);
      top: ${SPACE_XL}px;
      width: 250px;
    `,
  })}
`

const SRightContainer = styled.div`
  position: absolute;
  z-index: 11;
  display: flex;
  align-items: center;
  color: white;

  ${breakpoints({
    SM: css`
      right: calc(max(0px, (100vw - ${MAX_WIDTH}px) / 2) + 12px);
      top: 68px;
      font-size: 10px;
    `,
    MD: css`
      right: calc(max(0px, (100vw - ${MAX_WIDTH}px) / 2) + 16px);
      top: 185px;
      font-size: 14px;
    `,
    LG: css`
      right: calc(max(0px, (100vw - ${MAX_WIDTH}px) / 2) + 16px);
      top: 269px;
      font-size: 24px;
    `,
    XL: css`
      right: calc(max(0px, (100vw - ${MAX_WIDTH}px) / 2) + 16px);
      top: 407px;
      font-size: 24px;
    `,
  })}
`

const TextBubble = styled.div`
  border-radius: 50%;
  background: ${COLOR_CORAL};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: ${SPACE_TOKEN_STACK_M}px;
  padding-right: ${SPACE_TOKEN_STACK_M}px;
  z-index: 1;
  div {
    margin-bottom: 0;
  }
  h3 {
    padding: 0;
  }

  ${breakpoints({
    SM: css`
      width: 120px;
      height: 120px;
      padding-top: ${SPACE_TOKEN_STACK_XS}px;
    `,
    MD: css`
      width: 120px;
      height: 120px;
      padding-top: ${SPACE_TOKEN_STACK_S}px;
    `,
    LG: css`
      width: 150px;
      height: 150px;
      padding-top: ${SPACE_TOKEN_STACK_M}px;
    `,
    XL: css`
      width: 150px;
      height: 150px;
      padding-top: ${SPACE_TOKEN_STACK_M}px;
    `,
  })}
`

const SBackgroundImage = styled(Image)`
  &.gatsby-image-wrapper {
    position: absolute;
  }
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${breakpoints({
    SM: css`
      background-color: ${COLOR_GREEN};
      img {
        opacity: 0 !important;
      }
    `,
  })}
`

const SBackgroundImageWrapper = styled.div`
  grid-area: bgimage-start / bgimage-start / bgimage-end / bgimage-end;
  z-index: 1;
  position: relative;
`

const SBackgroundColor = styled.div`
  grid-row: bgcolor-start / bgcolor-end;
  grid-column: content-start / content-end;
  z-index: 2;

  transition: background-color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
  background-color: ${({ theme }) =>
    theme.pageThemeColor ? theme.pageThemeColor.color : COLOR_GREEN};
`

const STexts = styled.div`
  grid-row: texts-start / texts-end;
  grid-column: content-start / content-end;
  z-index: 10;
`

const SHeadlineText = styled(Text)`
  padding-left: 24px;
  padding-right: 24px;
`

const STopline = styled(Topline)`
  margin-right: ${SPACE_XL}px;

  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_2XL}px;
      font-size: 1.2rem;
      margin-left: ${SPACE_M}px;
    `,
    MD: css`
      margin-top: ${SPACE_3XL}px;
    `,
    LG: css`
      margin-top: ${SPACE_3XL}px;
    `,
    XL: css`
      margin-top: ${SPACE_4XL}px;
    `,
  })}
`

const controlsPositionStyles = css`
  justify-self: center;
  grid-row: controls-start / controls-end;
  grid-column: content-start / content-end;
  z-index: 5;
`

const SCustomCTAButton = styled(CTAButton)`
  ${controlsPositionStyles};
`

const SScooterModelControls = styled(ScooterModelControls)`
  ${controlsPositionStyles};
  min-width: 33%;
  max-width: 66%;
  width: auto;
`

const SScooterModel = styled(ScooterModel)``

const SScooterModelWrapper = styled.div`
  grid-row: model-start / model-end;
  grid-column: content-start / content-end;
  z-index: 4;

  width: 100%;
  justify-self: center;
  max-width: ${MODEL_ASPECT_RATIO * 75}vh;
`
