import React from "react"

import "./fragments"
import { InterweavingPadder } from "./components/InterweavingPadder"
import { RichTextSlice, Accordion } from "./components/slices"
import { ThemeProvider } from "./styles"
import { Divider } from "./components/slices/Divider"
import { HeroMediaSlice } from "./components/slices/HeroMediaSlice"
import { QuotesSlice } from "./components/slices/QuotesSlice"
import { IntroSlice } from "./components/slices/IntroSlice"
import { BannerSlice } from "./components/slices/BannerSlice"
import { ColumnTeaserSlice } from "./components/slices/columnTeaserSlice"
import { ImageCarrouselSlice } from "./components/slices/ImageCarrouselSlice"
import { EditorialSlice } from "./components/slices/EditorialSlice"
import { ImageAndTextSlice } from "./components/slices/ImageAndTextSlice"
import { BigTeaserSlice } from "./components/slices/BigTeaserSlice"
import { SharingSlice } from "./components/slices/SharingSlice"
import { BigEditorialSlice } from "./components/slices/BigEditorialSlice"
import { BigDisruptorSlice } from "./components/slices/BigDisruptorSlice"
import { ImageColumnsSlice } from "./components/slices/ImageColumnsSlice"
import { HeadlineAndCopySlice } from "./components/slices/HeadlineAndCopySlice"
import { ContentfulAutoModal } from "./components/ContentfulAutoModal"
import { ContentfulModal } from "./components/ContentfulModal"
import {
  DualImageCarrouselSlice,
  dualImageCarrouselSliceIterweaveConfig,
} from "./components/slices/DualImageCarrouselSlice"
import { ImageBackgroundCopySlice } from "./components/slices/ImageBackgroundCopySlice"
import { ModelComparisonSlice } from "./components/slices/ModelComparisonSlice"
import { TechnicalSpecsSlice } from "./components/slices/TechnicalSpecsSlice"
import { NewsletterSignUpSlice } from "./components/slices/NewsletterSignUpSlice"
import { HubspotFormSlice } from "./components/slices/HubspotFormSlice"
import { UnuCheckoutBuilder } from "./components/slices/UnuCheckoutBuilder"
import { BackgroundImageParallaxSlice } from "./components/slices/backgroundImageParallaxSlice"
import { HeroSplashVideoSlice } from "./components/slices/heroSplashVideoSlice"
import { GarageFinderSlice } from "./components/slices/garageFinderSlice"
import { ParallaxMediaSlice } from "./components/slices/parallaxMediaSlice"
import { Scooter3DModelSlice } from "./components/slices/scooter3DModelSlice"
import { AnimatedMapSlice } from "./components/slices/animatedMapSlice"
import { PostsSlice } from "./components/slices/PostsSlice"

import { resolveLink } from "./utils/linkResolver"
import { SPACE_2XL } from "./styles/primitives"
import { useLocale } from "./locale"
import styled, { css } from "styled-components"
import { SLICE_BACKGROUND_VARIANTS } from "./components/slices/utils"

export const Slices = ({ slices, page, additionalEntriesBySlice = {} }) => {
  const { slugPrefix } = useLocale()
  const realSlices = slices || []
  return (
    <SRoot>
      {realSlices
        .map((slice, index) => {
          return {
            sliceData: slice,
            componentConfig: buildSliceConfig(page, slice, slugPrefix),
            additionalEntries:
              additionalEntriesBySlice[slice.contentful_id] || [],
          }
        })
        .filter(config => !!config.componentConfig)
        .reduce((result, currentItem, index, allItems) => {
          const previousItem = allItems[index - 1] || null

          const rendered = (
            <currentItem.componentConfig.Component
              key={index}
              {...currentItem.componentConfig.props}
              additionalEntries={currentItem.additionalEntries}
              topInterweavingPadder={
                previousItem?.componentConfig.interweaving ? (
                  <InterweavingPadder
                    values={previousItem?.componentConfig.interweaving.bottom}
                  />
                ) : null
              }
            />
          )

          if (currentItem.componentConfig.withTheme) {
            result.push(
              <ThemeProvider
                themeName={currentItem.sliceData.theme}
                key={index}
              >
                {rendered}
              </ThemeProvider>
            )
          } else {
            result.push(rendered)
          }

          return result
        }, [])}
    </SRoot>
  )
}

const SRoot = styled.div`
  ${[
    SLICE_BACKGROUND_VARIANTS.map(
      variantName =>
        css`
          > *[data-slice-background-variant="${variantName}"]
            + *[data-slice-background-variant="${variantName}"] {
            padding-top: 0;
          }
        `
    ),
  ]}
`

function buildSliceConfig(page, slice, slugPrefix) {
  switch (slice.__typename) {
    case "ContentfulRichtextSlice":
      return {
        Component: RichTextSlice,
        props: { slice },
        withTheme: true,
      }

    case "ContentfulQuoteSlice":
      return {
        Component: QuotesSlice,
        props: { slice },
        withTheme: true,
      }
    case "ContentfulHeroMediaSlice":
      return {
        Component: HeroMediaSlice,
        props: { slice },
        withTheme: true,
      }
    case "ContentfulIntroSlice":
      return {
        Component: IntroSlice,
        props: {
          slice,
          publishedAt:
            page.__typename === "ContentfulCmsPage"
              ? page.publishedAt
              : page.publicationDate,
        },
        withTheme: true,
      }
    case "ContentfulAccordion":
      return {
        Component: Accordion,
        props: {
          slice: slice,
        },
        withTheme: true,
      }
    case "ContentfulUnuCheckoutBuilder":
      return {
        Component: UnuCheckoutBuilder,
        props: { slice },
      }
    case "ContentfulDividerSlice":
      if (slice.role === "divider") {
        return {
          Component: Divider,
          props: { slice },
        }
      } else {
        return {
          Component: HeadlineAndCopySlice,
          props: {
            slice,
          },
        }
      }
    case "ContentfulBannerSlice":
      return {
        Component: BannerSlice,
        props: {
          slice,
        },
        withTheme: true,
        interweaving: slice.inline
          ? { top: 140, bottom: { SM: SPACE_2XL, MD: 129, LG: 117, XL: 186 } }
          : null,
      }
    case "ContentfulColumnTeaserSlice":
      return {
        Component: ColumnTeaserSlice,
        props: {
          slice,
        },
      }
    case "ContentfulImageCarrouselSlice":
      return {
        Component: ImageCarrouselSlice,
        props: { slice },
        withTheme: true,
      }
    case "ContentfulEditorialSlice":
      return {
        Component: EditorialSlice,
        props: { slice },
      }

    case "ContentfulImageAndTextSlice":
      return {
        Component: ImageAndTextSlice,
        props: {
          slice,
        },
      }

    case "ContentfulBigTeaserSlice":
      return {
        Component: BigTeaserSlice,
        props: {
          slice,
        },
      }
    case "ContentfulSharingSlice":
      return {
        Component: SharingSlice,
        props: {
          slice,
          sharedTitle: page.title,
          sharedDescription: page.teaserText.teaserText,
          href: resolveLink(slugPrefix, page),
        },
      }
    case "ContentfulBigDisruptorSlice":
      return {
        Component: BigDisruptorSlice,
        props: {
          slice,
        },
      }
    case "ContentfulBigEditorialSlice":
      return {
        Component: BigEditorialSlice,
        props: {
          slice,
        },
      }
    case "ContentfulImageColumnsSlice":
      return {
        Component: ImageColumnsSlice,
        props: {
          slice,
        },
      }

    case "ContentfulDualImageCarrouselSlice":
      return {
        Component: DualImageCarrouselSlice,
        props: { slice },
        interweaving: {
          top: null,
          bottom: dualImageCarrouselSliceIterweaveConfig,
        },
      }

    case "ContentfulImageBackgroundCopySlice":
      return {
        Component: ImageBackgroundCopySlice,
        props: { slice },
      }

    case "ContentfulModal":
      if (slice.autoOpen) {
        return {
          Component: ContentfulAutoModal,
          props: { ...slice },
        }
      }
      return {
        Component: ContentfulModal,
        props: { ...slice },
      }

    case "ContentfulModelComparisonSlice":
      return {
        Component: ModelComparisonSlice,
        props: { slice },
      }

    case "ContentfulTechnicalSpecsSlice":
      return {
        Component: TechnicalSpecsSlice,
        props: {
          slice,
        },
      }

    case "ContentfulNewsletterSignUpSlice":
      return {
        Component: NewsletterSignUpSlice,
        props: { slice },
      }

    case "ContentfulBackgroundImageParallaxSlice":
      return {
        Component: BackgroundImageParallaxSlice,
        props: { slice },
      }

    case "ContentfulHeroSplashVideoSlice":
      return {
        Component: HeroSplashVideoSlice,
        props: { slice },
      }

    case "ContentfulGarageFinderSlice":
      return {
        Component: GarageFinderSlice,
        props: { slice },
      }

    case "ContentfulHubspotFormSlice":
      return {
        Component: HubspotFormSlice,
        props: { slice },
      }

    case "ContentfulParallaxMediaSlice":
      return {
        Component: ParallaxMediaSlice,
        props: { slice },
      }

    case "ContentfulScooterModelSlice":
      return {
        Component: Scooter3DModelSlice,
        props: { slice },
      }

    case "ContentfulAnimatedMapSlice":
      return {
        Component: AnimatedMapSlice,
        props: { slice },
      }

    case "ContentfulPostsSlice":
      return {
        Component: PostsSlice,
        props: { slice },
      }

    default:
      console.error(`Unsupported slice type ${slice.__typename}`)
      return null
  }
}
