import { useLayoutEffect, useMemo, useState } from "react"

export function getProgressText(currentItemIndex, numberOfItems) {
  return `${(currentItemIndex + 1)
    .toString()
    .padStart(2, "0")} – ${numberOfItems.toString().padStart(2, "0")}`
}

export const SWITCH_DURATION = 5000

export function useCarrouselSwitching(items, duration) {
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const nextItemIndex =
    currentItemIndex + 1 === items.length ? 0 : currentItemIndex + 1

  const previousItemIndex =
    currentItemIndex - 1 < 0 ? items.length - 1 : currentItemIndex - 1

  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      setCurrentItemIndex(nextItemIndex)
    }, duration ?? SWITCH_DURATION)
    return () => clearTimeout(timeoutId)
  }, [nextItemIndex])

  return useMemo(
    () => ({
      currentItemIndex,
      previousItemIndex,
      nextItemIndex,
      switchToItemIndex: setCurrentItemIndex,
    }),
    [currentItemIndex, nextItemIndex, previousItemIndex]
  )
}
