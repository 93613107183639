import React, { useMemo } from "react"
import styled, { css } from "styled-components"

import { breakpoints, ThemeProvider } from "../../styles"
import { MAX_WIDTH } from "../../styles/breakpoints"
import {
  SPACE_2XL,
  SPACE_L,
  SPACE_TOKEN_STACK_2XL,
  SPACE_TOKEN_STACK_4XL,
  SPACE_XL,
} from "../../styles/primitives"
import { TeaserColumns } from "../TeaserColumns"
import { Text } from "../Text"
import { getSliceRootElementProps } from "./utils"

const Root = styled.section`
  padding: ${SPACE_TOKEN_STACK_4XL}px
    calc(max(0px, 100vw - ${MAX_WIDTH}px) / 2 + ${SPACE_XL}px);

  ${breakpoints({
    MD: css`
      padding: ${SPACE_TOKEN_STACK_2XL}px ${SPACE_L}px;
    `,
  })}
`

export function PostsSlice({ slice, additionalEntries, ...otherProps }) {
  const { headline, readLabel } = slice
  const postsAsTeasers = useMemo(
    () =>
      additionalEntries
        .filter(({ __typename }) => __typename === "ContentfulPost")
        .map(post => ({
          contentful_id: post.contentful_id,
          entryLink: post,
          customLinkLabel: readLabel,
          teaserImage: post.teaserImage,
        })),
    [additionalEntries, readLabel]
  )

  return (
    <ThemeProvider themeName="stone">
      <Root
        {...getSliceRootElementProps(slice, otherProps, {
          sliceBackgroundVariant: "stone",
        })}
      >
        {headline?.headline && (
          <SHeader>
            {headline?.headline && (
              <Text type="primary">{headline.headline}</Text>
            )}
          </SHeader>
        )}
        <TeaserColumns
          showLinks={true}
          showDates={false}
          numberOfColumns={3}
          teasers={postsAsTeasers}
        />
      </Root>
    </ThemeProvider>
  )
}

const SHeader = styled.header`
  margin-bottom: ${SPACE_2XL}px;
`
