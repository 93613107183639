import React from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import styled from "styled-components"
import { ANIMATION_EASING } from "../../../styles/animations"
import { modelColorsBySKUColorId } from "../../../styles/scooterModelColors"
import { Image } from "../../Image"

const TRANSITION_DURATION = 400

export const scooterColors = Object.keys(modelColorsBySKUColorId).map(
  skuColorId => {
    return modelColorsBySKUColorId[skuColorId]
  }
)

export function ScooterImageModel({ images, activeIndex, ...otherProps }) {
  return (
    <Root {...otherProps}>
      {images.map((image, index) => (
        <SHiddenPreloadImage key={index} asset={image} />
      ))}

      <TransitionGroup>
        <CSSTransition
          classNames="transition"
          timeout={{ enter: TRANSITION_DURATION, exit: TRANSITION_DURATION }}
          key={activeIndex.toString()}
        >
          <SImage asset={images[activeIndex]} objectFit="contain" />
        </CSSTransition>
      </TransitionGroup>
    </Root>
  )
}

const Root = styled.section`
  > * {
    height: 100%;
    width: 100%;

    display: grid;
    grid-template-areas: "image";
    grid-template-rows: minmax(auto, 90vh);
    grid-template-columns: minmax(auto, 90vh);
    justify-content: center;
  }
`

const SImage = styled(Image)`
  grid-area: image;
  transition: clip-path ${TRANSITION_DURATION}ms ${ANIMATION_EASING};

  &.transition-enter {
    clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%);
  }
  &.transition-enter-active {
    z-index: 1;
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
  }
  &.transition-exit {
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
  }
  &.transition-exit-active {
    clip-path: polygon(100% 0%, 100% 100%, 100% 100%, 100% 0%);
  }
`

const SHiddenPreloadImage = styled(Image)`
  &.gatsby-image-wrapper {
    position: absolute;
  }

  opacity: 0;
  pointer-events: none;
`
