import { useMemo } from "react"

export function usePrettyDistance(locale, distance) {
  const decimalNumberFormat = useMemo(
    () =>
      new Intl.NumberFormat(locale, {
        style: "decimal",
        maximumFractionDigits: 1,
      }),
    [locale]
  )
  return useMemo(
    () => (distance ? `${decimalNumberFormat.format(distance)}km` : null),
    [decimalNumberFormat, distance]
  )
}
