/* global google */
import React from "react"
import { OverlayView } from "@react-google-maps/api"
import styled from "styled-components"
import { CloseIcon, SpeechBubbleTriangleIcon } from "../../icons/icons"
import { COLOR_GRAY_100, SPACE_L, SPACE_S } from "../../styles/primitives"

const StyledSpeechBubbleTriangleIcon = styled(SpeechBubbleTriangleIcon)`
  margin-top: -8px;
  margin-left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
  position: relative;
`

const Container = styled.div`
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.16));

  /* Workaround for this overlay to be rendered on top of marker clusterer icons */
  position: relative;
  z-index: 1;
`

const InnerContainer = styled.div`
  background-color: ${COLOR_GRAY_100};
  border-radius: 8px;
  padding: ${SPACE_L}px;
  max-width: 350px;

  ${StyledSpeechBubbleTriangleIcon} {
  }
`

const TopRow = styled.header`
  margin-bottom: ${SPACE_S}px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const TopRowContent = styled.div`
  flex: 1;

  display: flex;
  align-items: center;
`

const StyledCloseIcon = styled(CloseIcon)`
  margin-left: ${SPACE_S}px;
  width: 16px;
  height: 16px;
`

export function LocationOverlay({ location, topRow, children, requestClose }) {
  return (
    <OverlayView
      position={{ lat: location.latitude, lng: location.longitude }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getLocationOverlayPixelPositionOffset}
    >
      <Container
        ref={ref => ref && google.maps.OverlayView.preventMapHitsFrom(ref)}
      >
        <InnerContainer>
          <TopRow>
            <TopRowContent>{topRow}</TopRowContent>
            <StyledCloseIcon onClick={requestClose} />
          </TopRow>
          {children}
        </InnerContainer>
        <StyledSpeechBubbleTriangleIcon />
      </Container>
    </OverlayView>
  )
}

function getLocationOverlayPixelPositionOffset(width, height) {
  return { x: -(width / 2), y: -height }
}
