import React, { useCallback, useMemo, useState } from "react"
import { Fragment } from "react"

import styled, { css } from "styled-components"
import { getSliceRootElementProps } from "./utils"
import {
  breakpoints,
  COLOR_TEXT_DISABLED,
  TEXT_TOKEN_COPY_STRONG_BREAKPOINT_SM,
  TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_MD,
  TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_SM,
  TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_XL,
  ThemeProvider,
} from "../../styles"
import { MAX_WIDTH } from "../../styles/breakpoints"
import {
  COLOR_GRAY_700,
  SPACE_1XL,
  SPACE_2XL,
  SPACE_4XL,
  SPACE_L,
  SPACE_M,
  SPACE_S,
  SPACE_XL,
} from "../../styles/primitives"
import { RichText } from "../RichText"
import { Button } from "../button"
import { Text, SHeadlineTertiaryText, createTextStylesFromToken } from "../Text"

function createGridRows(numberOfRows, outerPadding, rowGap) {
  return css`
    grid-template-rows:
      [top] ${outerPadding} [headline-start] auto [headline-end] ${rowGap} repeat(
        ${numberOfRows},
        [row-start] auto [row-end] calc(${rowGap} / 2) [divider-start] auto
          [divider-end] calc(${rowGap} / 2)
      )
      [button-start] auto [button-end] ${outerPadding} [bottom];
  `
}

function createGridColumns(numberOfColumns, outerPadding, columnGap) {
  return css`
    grid-template-columns:
      [left] calc(${outerPadding} - (${columnGap} / 2)) repeat(
        ${numberOfColumns},
        calc(${columnGap} / 2) [column-start] 1fr [column-end]
          calc(${columnGap} / 2)
      )
      calc(${outerPadding} - (${columnGap} / 2)) [right];
  `
}

const ItemContainer = styled.div`
  ${({ itemIndex }) => css`
    grid-column-start: column-start ${(itemIndex % 3) + 1};
    grid-column-end: column-end ${(itemIndex % 3) + 1};
    grid-row-start: row-start ${Math.ceil((itemIndex + 1) / 3)};
    grid-row-end: row-end ${Math.ceil((itemIndex + 1) / 3)};

    ${breakpoints({
      SM: css`
        grid-column-start: column-start 1;
        grid-column-end: column-end 1;
        grid-row-start: row-start ${itemIndex + 1};
        grid-row-end: row-end ${itemIndex + 1};
      `,
    })}
  `};
`

const Container = styled.section`
  display: grid;
  background-color: ${({ theme }) => theme.backgroundColor};

  ${({ numberOfItems }) =>
    breakpoints({
      SM: css`
        ${createGridRows(numberOfItems, `${80}px`, `${40}px`)}
        ${createGridColumns(1, `${SPACE_L}px`, `${0}px`)}
      `,
      MD: css`
        ${createGridRows(
          Math.ceil(numberOfItems / 3),
          `${SPACE_1XL}px`,
          `${88}px`
        )}
        ${createGridColumns(3, `${SPACE_L}px`, `${SPACE_M}px`)}
      `,
      LG: css`
        ${createGridRows(
          Math.ceil(numberOfItems / 3),
          `${120}px`,
          `${SPACE_4XL}px`
        )}
        ${createGridColumns(3, `${SPACE_XL}px`, `${SPACE_L}px`)}
      `,
      XL: css`
        ${createGridRows(
          Math.ceil(numberOfItems / 3),
          `${SPACE_4XL}px`,
          `${SPACE_2XL}px`
        )}
        ${createGridColumns(3, `${SPACE_XL}px`, `${SPACE_2XL}px`)}
      `,
      MAX: css`
        ${createGridRows(
          Math.ceil(numberOfItems / 3),
          `${SPACE_4XL}px`,
          `${SPACE_2XL}px`
        )}
        ${createGridColumns(
          3,
          `calc(max(100% - ${MAX_WIDTH}px, 0px) / 2 + ${SPACE_XL}px)`,
          `${SPACE_2XL}px`
        )}
      `,
    })}
`

const HeadlineText = styled(Text)`
  grid-row-start: headline-start;
  grid-row-end: headline-end;
  grid-column-start: column-start 1;
  grid-column-end: right;
`

const Divider = styled.div`
  height: 1px;
  background-color: ${COLOR_GRAY_700};

  ${breakpoints({
    SM: css`
      display: none;
    `,
  })}

  ${({ rowIndex }) => css`
    grid-row-start: divider-start ${rowIndex};
    grid-row-end: divider-end ${rowIndex};
  `}
  grid-column-start: left;
  grid-column-end: right;
`

const INITIALLY_DISPLAYED_ITEMS = 3 * 3

export function TechnicalSpecsSlice({ slice }) {
  const {
    headline,
    technicalSpecItems: allTechnicalSpecItems,
    showMoreButtonLabel,
    showLessButtonLabel,
  } = slice
  const [isExpanded, setIsExpanded] = useState(false)
  const toggleExpanded = useCallback(
    () => setIsExpanded(current => !current),
    []
  )
  const visibleSpecItems = useMemo(
    () =>
      isExpanded
        ? allTechnicalSpecItems
        : allTechnicalSpecItems.slice(0, INITIALLY_DISPLAYED_ITEMS),
    [isExpanded, allTechnicalSpecItems]
  )

  return (
    <ThemeProvider themeName="dark">
      <Container
        numberOfItems={visibleSpecItems.length}
        {...getSliceRootElementProps(slice)}
      >
        <HeadlineText type="hero">{headline.headline}</HeadlineText>
        {visibleSpecItems.map((specItem, itemIndex) => (
          <Fragment key={itemIndex}>
            <SpecItem
              key={specItem.id}
              specItem={specItem}
              itemIndex={itemIndex}
            />
            {itemIndex % 3 === 2 &&
              visibleSpecItems.length !== itemIndex + 1 && (
                <Divider rowIndex={Math.ceil(itemIndex / 3)} />
              )}
          </Fragment>
        ))}
        {allTechnicalSpecItems.length > INITIALLY_DISPLAYED_ITEMS && (
          <SToggleExpandButton variant="secondary" onClick={toggleExpanded}>
            {isExpanded ? showLessButtonLabel : showMoreButtonLabel}
          </SToggleExpandButton>
        )}
      </Container>
    </ThemeProvider>
  )
}

const ItemHeadlineText = styled(SHeadlineTertiaryText)`
  ${createTextStylesFromToken({
    SM: TEXT_TOKEN_COPY_STRONG_BREAKPOINT_SM,
    MD: TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_SM,
    LG: TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_MD,
    XL: TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_MD,
  })};

  ${breakpoints({
    SM: css`
      margin-bottom: ${SPACE_S}px;
    `,
    MD: css`
      margin-bottom: ${SPACE_M}px;
    `,
    LG: css`
      margin-bottom: ${SPACE_L}px;
    `,
    XL: css`
      margin-bottom: ${SPACE_L}px;
    `,
  })}
`

const ItemDescriptionText = styled.div`
  ${createTextStylesFromToken({
    SM: TEXT_TOKEN_COPY_STRONG_BREAKPOINT_SM,
    MD: TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_SM,
    LG: TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_MD,
    XL: TEXT_TOKEN_HEADLINE_TERTIARY_BREAKPOINT_MD,
  })};

  color: ${COLOR_TEXT_DISABLED};
  white-space: pre-wrap;
`

function SpecItem({ specItem, numberOfItems, itemIndex }) {
  const { headline, content } = specItem

  return (
    <ItemContainer itemIndex={itemIndex}>
      <ItemHeadlineText type="primary">{headline.headline}</ItemHeadlineText>
      <ItemDescriptionText>
        <RichText data={content} />
      </ItemDescriptionText>
    </ItemContainer>
  )
}

const SToggleExpandButton = styled(Button)`
  grid-area: button-start / left / button-end / right;
  justify-self: center;
`
