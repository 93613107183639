import React, { useMemo } from "react"
import styled, { css } from "styled-components"
import { breakpoints, ThemeProvider } from "../../styles"
import { COLOR_GREEN, SPACE_L, SPACE_XL } from "../../styles/primitives"
import { Map, GOOGLE_API_MAP_ID } from "../../components/map/Map"
import { OVERLAY_PADDING_IN_MAP } from "../../components/map/OverlayWrapper"
import { useJsApiLoader } from "@react-google-maps/api"

const jsAPIMapIds = [GOOGLE_API_MAP_ID]

export function LocationSearchMap({ ...otherProps }) {
  const { isLoaded } = useJsApiLoader(
    useMemo(
      () => ({
        id: "google-map-script",
        googleMapsApiKey: process.env.GATSBY_GOOGLE_API_TOKEN,
        mapIds: jsAPIMapIds,
        version: "3",
        libraries: ["places"],
      }),
      []
    )
  )

  return isLoaded && <LocationSearchMapWithJSAPILoaded {...otherProps} />
}
function LocationSearchMapWithJSAPILoaded({
  locations,
  autocompleteLocation,
  renderLocationOverlay,
  children,
  overlayRef,
  mapState,
  locationSearchState,
  responsiveTopPadding,
  hideMapOnSmallScreens = false,

  ...otherProps
}) {
  const { selectedLocationId } = locationSearchState

  return (
    <>
      <Container {...otherProps}>
        <ThemeProvider themeName="stone">
          <MapContainer
            responsiveTopPadding={responsiveTopPadding}
            hideMapOnSmallScreens={hideMapOnSmallScreens}
          >
            <Map
              locations={locations}
              autocompleteLocation={autocompleteLocation}
              selectedLocationId={selectedLocationId}
              overlayRef={overlayRef}
              renderLocationOverlay={renderLocationOverlay}
              mapState={mapState}
              locationSearchState={locationSearchState}
            />
          </MapContainer>

          {children}
        </ThemeProvider>
      </Container>
    </>
  )
}

const Container = styled.section`
  display: grid;

  ${breakpoints({
    SM: css`
      grid-template-rows: [map-start] 0 [overlay-1-start] auto [overlay-1-end] 0 [overlay-2-start] auto [overlay-2-end] 0 [overlay-3-start] auto [overlay-3-end] 0 [map-end];
      grid-template-columns: [map-start] 0 [map-end] 0 [overlay-1-start] 0 [overlay-2-start] 0 [overlay-3-start] auto [overlay-1-end] 0 [overlay-2-end] 0 [overlay-3-end];
    `,
    MD: css`
      grid-template-rows: [map-start] ${OVERLAY_PADDING_IN_MAP}px [overlay-1-start] auto [overlay-1-end] ${OVERLAY_PADDING_IN_MAP}px [map-end] ${SPACE_L}px [overlay-2-start] 0 [overlay-3-start] auto [overlay-2-end] 0 [overlay-3-end] ${OVERLAY_PADDING_IN_MAP}px;
      grid-template-columns: [map-start] ${SPACE_XL}px [overlay-1-start] 0 [overlay-2-start] 1fr [overlay-2-end] ${SPACE_L}px [overlay-3-start] 1fr [overlay-3-end] 0 [overlay-1-end] ${SPACE_XL}px [map-end];
    `,
    LG: css`
      grid-template-rows:
        [map-start] ${OVERLAY_PADDING_IN_MAP}px [overlay-1-start] 0 [overlay-2-start] minmax(
          auto,
          50%
        )
        [overlay-1-end] ${SPACE_L}px [overlay-3-start] auto [overlay-2-end] auto [overlay-3-end] ${OVERLAY_PADDING_IN_MAP}px [map-end];
      grid-template-columns: [map-start] ${SPACE_XL}px [overlay-1-start] 0 [overlay-3-start] 1fr [overlay-3-end] 0 [overlay-1-end] ${SPACE_L}px [overlay-2-start] 1fr [overlay-2-end] ${SPACE_XL}px [map-end];
    `,
    XL: css`
      grid-template-rows:
        [map-start] ${OVERLAY_PADDING_IN_MAP}px [overlay-1-start] 0 [overlay-2-start] minmax(
          auto,
          50%
        )
        [overlay-1-end] ${SPACE_L}px [overlay-3-start] auto [overlay-2-end] auto [overlay-3-end] ${OVERLAY_PADDING_IN_MAP}px [map-end];
      grid-template-columns: [map-start] ${SPACE_XL}px [overlay-1-start] 0 [overlay-3-start] 1fr [overlay-3-end] 0 [overlay-1-end] ${SPACE_L}px [overlay-2-start] 1fr [overlay-2-end] ${SPACE_XL}px [map-end];
    `,
    MAX: css`
      grid-template-rows: [map-start] ${OVERLAY_PADDING_IN_MAP}px [overlay-1-start] 0 [overlay-2-start] 0 [overlay-3-start] auto [overlay-1-end] 0 [overlay-2-start] 0 [overlay-3-end] ${OVERLAY_PADDING_IN_MAP}px [map-end];
      grid-template-columns: [map-start] ${SPACE_XL}px [overlay-1-start] 1fr [overlay-1-end] ${SPACE_L}px [overlay-2-start] 1fr [overlay-2-end] ${SPACE_L}px [overlay-3-start] 1fr [overlay-3-end] ${SPACE_XL}px [map-end];
    `,
  })};
`

const hiddenMapStyles = css`
  background-color: ${COLOR_GREEN};
  > * {
    display: none;
  }
`

const MapContainer = styled.div`
  grid-row-start: map-start;
  grid-row-end: map-end;
  grid-column-start: map-start;
  grid-column-end: map-end;
  z-index: 0;

  ${({ responsiveTopPadding, hideMapOnSmallScreens }) =>
    breakpoints({
      SM: css`
        min-height: calc(100vh - ${responsiveTopPadding.SM}px);
        ${hideMapOnSmallScreens && hiddenMapStyles};
      `,
      MD: css`
        min-height: calc(100vh - ${responsiveTopPadding.MD}px);
        ${hideMapOnSmallScreens && hiddenMapStyles};
      `,
      LG: css`
        min-height: calc(100vh - ${responsiveTopPadding.LG}px);
      `,
      XL: css`
        min-height: calc(100vh - ${responsiveTopPadding.XL}px);
      `,
    })}

  display: grid;
  grid-template-areas: "content";
  grid-template-rows: 100%;
  grid-template-columns: 100%;

  > * {
    grid-area: content;
  }
`
