import React from "react"

import styled from "styled-components"
import { BeyondTheFoldAppend } from "./BeyondTheFoldAppend"
import { AboveTheFoldSplash } from "./AboveTheFoldSplash"
import { getSliceRootElementProps } from "../utils"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.backgroundColor};
`
export function HeroSplashVideoSlice({ slice }) {
  const {
    orderBenefitText,
    deliveryBenefitText,
    ctaButtons,
    video,
    image,
    showTruspilotScore,
    trustedShopsHeadline,
    headline,
    headlineIsH1,
    subline,
    variantLinks,
    pageThemeColorSelectorLabel,
    pageThemeColorSelectorLinks,
    disableColorChange,
  } = slice

  return (
    <Container {...getSliceRootElementProps(slice)}>
      {(video || image) && (
        <>
          <AboveTheFoldSplash
            headline={headline}
            headlineIsH1={headlineIsH1}
            subline={subline}
            media={video || image}
            ctaButtons={ctaButtons}
            variantLinks={variantLinks}
            pageThemeColorSelectorLabel={pageThemeColorSelectorLabel}
            pageThemeColorSelectorLinks={pageThemeColorSelectorLinks}
            disableColorChange={disableColorChange}
          />
          {orderBenefitText && deliveryBenefitText && trustedShopsHeadline && (
            <BeyondTheFoldAppend
              orderBenefitText={orderBenefitText}
              deliveryBenefitText={deliveryBenefitText}
              showTruspilotScore={showTruspilotScore}
              trustedShopsHeadline={trustedShopsHeadline}
            />
          )}
        </>
      )}
    </Container>
  )
}
