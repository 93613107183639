import React from "react"
import styled, { css } from "styled-components"
import prettyBytes from "pretty-bytes"

import { breakpoints } from "../styles"
import {
  SPACE_TOKEN_STACK_M,
  SPACE_TOKEN_STACK_S,
  SPACE_TOKEN_STACK_XL,
  SPACE_TOKEN_STACK_XS,
} from "../styles/primitives"

import { Text } from "./Text"
import { LoadingDecoratedImage } from "./Image"
import { Link } from "./links"
import { DownloadIcon } from "../icons/icons"
import { RichText } from "./RichText"

const MetaText = styled(Text)`
  display: block;
  margin-top: ${SPACE_TOKEN_STACK_M}px;

  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_TOKEN_STACK_S + SPACE_TOKEN_STACK_XS}px;
    `,
  })}
`

const ImageContainerLink = styled(Link)`
  position: relative;
  height: 100%;
`

const ImageContainerPlain = styled.div`
  position: relative;
  height: 100%;
`

const HeadlineText = styled(Text)`
  margin: ${SPACE_TOKEN_STACK_M}px 0;
`
const PreviewLinkList = styled(RichText)`
  ul {
    padding: 0px;
    margin-top: 12px;
  }
  li {
    margin-top: 10px !important;
  }
  path {
    stroke: #ff554c !important;
  }
  svg {
    height: 24px;
    width: 24px;
  }
  div {
    margin-right: 6px;
  }
`

const PreviewText = styled(Text)``

const LinkText = styled(Text)`
  display: block;
  margin-top: ${SPACE_TOKEN_STACK_XL}px;
`
const StyledDownloadIcon = styled(DownloadIcon)`
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;

  margin: ${SPACE_TOKEN_STACK_M}px;

  ${breakpoints({
    MD: css`
      margin: ${SPACE_TOKEN_STACK_S}px;
    `,
  })}
`

export function AssetTeaser({
  teaser,
  locale,
  showLink,
  showMeta,
  columnIndex,
  rowIndex,
}) {
  const { entryLink, customLink, customLinkLabel, teaserImage, asset } = teaser
  const title = teaser.title || entryLink?.title
  const teaserLinkList = teaser.linkList
  const showLinkList = teaser.showLinkList
  const teaserText =
    teaser.description?.description || entryLink?.teaserText?.teaserText
  const linkTarget = entryLink || customLink || asset

  return (
    <TeaserBox
      teaserImage={teaserImage || entryLink?.teaserImage}
      teaserImageLink={linkTarget}
      locale={locale}
      metaText={
        !title && asset
          ? `${asset.title}, ${prettyBytes(asset.file.details.size, {
              locale,
            })}`
          : null
      }
      title={title || null}
      teaserText={showLinkList ? null : teaserText || null}
      teaserLinkList={showLinkList ? teaserLinkList || null : null}
      imageOverlay={
        asset && (
          <Link to={asset}>
            <StyledDownloadIcon />
          </Link>
        )
      }
      bottomLinkElement={
        showLink && linkTarget ? (
          <Link to={linkTarget}>
            {customLinkLabel ||
              (typeof customLink === "string" ? customLink : "Link")}
          </Link>
        ) : null
      }
      columnIndex={columnIndex}
      rowIndex={rowIndex}
    />
  )
}

function TeaserBox({
  teaserImage,
  teaserImageLink,
  locale,
  metaText,
  title,
  teaserText,
  teaserLinkList,
  imageOverlay,
  bottomLinkElement,
  columnIndex,
  rowIndex,
}) {
  return (
    <>
      <SGridTop columnIndex={columnIndex} rowIndex={rowIndex}>
        {teaserImage &&
          (teaserImageLink ? (
            <ImageContainerLink to={teaserImageLink}>
              <SLoadingDecoratedImage
                asset={teaserImage}
                objectFit="contain"
                objectPosition="left"
              />
              {imageOverlay}
            </ImageContainerLink>
          ) : (
            <ImageContainerPlain>
              <SLoadingDecoratedImage
                asset={teaserImage}
                objectFit="contain"
                objectPosition="left"
              />
              {imageOverlay}
            </ImageContainerPlain>
          ))}
      </SGridTop>
      <SGridBottom columnIndex={columnIndex} rowIndex={rowIndex}>
        {metaText && <MetaText type="meta">{metaText}</MetaText>}
        {title && <HeadlineText type="tertiary">{title}</HeadlineText>}
        {teaserText && <PreviewText type="copy">{teaserText}</PreviewText>}
        {teaserLinkList && (
          <PreviewLinkList data={teaserLinkList} />

          // <PreviewLinkList type="copy">{teaserLinkList}</PreviewLinkList>
        )}
        {bottomLinkElement && (
          <LinkText type="button">{bottomLinkElement}</LinkText>
        )}
      </SGridBottom>
    </>
  )
}

const SGridTop = styled.div`
  grid-row-start: row-start 1;
  grid-row-end: row-end 1;
  grid-column-start: column-start ${({ columnIndex }) => columnIndex + 1};
  grid-column-end: column-end ${({ columnIndex }) => columnIndex + 1};

  ${breakpoints({
    SM: css`
      grid-row-start: top-start ${({ columnIndex }) => columnIndex + 1};
      grid-row-end: top-end ${({ columnIndex }) => columnIndex + 1};
    `,
  })}
`

const SGridBottom = styled.div`
  grid-row-start: row-start 2;
  grid-row-end: row-end 2;
  grid-column-start: column-start ${({ columnIndex }) => columnIndex + 1};
  grid-column-end: column-end ${({ columnIndex }) => columnIndex + 1};

  ${breakpoints({
    SM: css`
      grid-row-start: bottom-start ${({ columnIndex }) => columnIndex + 1};
      grid-row-end: bottom-end ${({ columnIndex }) => columnIndex + 1};
    `,
  })}
`

const SLoadingDecoratedImage = styled(LoadingDecoratedImage)`
  height: 100%;
`
