import React from "react"

import { RichText, SRichTextHeadline1Text } from "../RichText"
import styled, { css } from "styled-components"
import { breakpoints } from "../../styles"
import {
  SPACE_2XL,
  SPACE_L,
  SPACE_TOKEN_STACK_2XL,
  SPACE_TOKEN_STACK_3XL,
  SPACE_TOKEN_STACK_4XL,
  SPACE_XL,
} from "../../styles/primitives"
import { Text } from "../Text"
import { getSliceRootElementProps } from "./utils"
import { MAX_WIDTH } from "../../styles/breakpoints"

const SRoot = styled.section`
  ${({disablePadding}) => disablePadding
    ? ""
    : breakpoints({
        SM: css`
          padding: ${SPACE_TOKEN_STACK_2XL}px ${SPACE_L}px;
        `,
        MD: css`
          padding: ${SPACE_TOKEN_STACK_2XL}px ${SPACE_L}px;
        `,
        LG: css`
          padding: ${SPACE_TOKEN_STACK_3XL}px ${SPACE_XL}px;
        `,
        XL: css`
          padding: ${SPACE_TOKEN_STACK_4XL}px
            calc(max(0px, 100vw - ${MAX_WIDTH}px) / 2 + ${SPACE_XL}px);
        `,
      })}
`

export const RichTextSlice = ({ slice }) => {
  return (
    <SRoot {...getSliceRootElementProps(slice)} disablePadding={slice.disablePadding}>
      {slice.headline?.headline && (
        <HeadlineText type="primary">{slice.headline.headline}</HeadlineText>
      )}
      <SRichText data={slice.content} />
    </SRoot>
  )
}

const HeadlineText = styled(Text)`
  margin-bottom: ${SPACE_2XL}px;
`

const SRichText = styled(RichText)`
  > *:not(${SRichTextHeadline1Text}) {
    ${breakpoints({
      LG: css`
        margin-left: 82px;
        margin-right: 82px;
      `,
      XL: css`
        max-width: 802px;
        margin-left: 104px;
      `,
    })}
  }
`
