import React from "react"
import styled, { css } from "styled-components"
import { ArrowRightIcon } from "../../icons/ArrowRightIcon"
import { breakpoints, COLOR_UI_BACKGROUND_PRIMARY } from "../../styles"
import { COLOR_STONE } from "../../styles/primitives"
import { ProgressCircle } from "../ProgressCircle"

const StyledArrowIcon = styled(ArrowRightIcon)`
  width: 32px;
  height: 32px;

  ${breakpoints({
    SM: css`
      width: 16px;
      height: 16px;
    `,
  })}

  ${({ reverse }) =>
    reverse &&
    css`
      transform: rotate(180deg);
      transform-origin: center;
    `}
`

const StyledProgressCircle = styled(ProgressCircle)`
  width: 88px;
  height: 88px;

  ${breakpoints({
    SM: css`
      width: 44px;
      height: 44px;
    `,
  })}

  ${StyledArrowIcon} {
    stroke: ${({ colorTheme }) => colorTheme.arrowDefault};
  }

  &:hover {
    ${StyledArrowIcon} {
      stroke: ${({ colorTheme }) => colorTheme.arrowHover};
    }
  }
`

export function CarrouselArrowButton({
  carrouselState: { previousItemIndex, nextItemIndex, switchToItemIndex },
  switchDuration,
  reverse = false,
  colorTheme = {
    backgroundDefault: "transparent",
    arrowDefault: COLOR_UI_BACKGROUND_PRIMARY,
    backgroundHover: COLOR_STONE,
    arrowHover: COLOR_UI_BACKGROUND_PRIMARY,
  },
  ...otherProps
}) {
  return (
    <StyledProgressCircle
      onClick={() =>
        switchToItemIndex(reverse ? previousItemIndex : nextItemIndex)
      }
      autoFillCycleDuration={switchDuration}
      colorTheme={colorTheme}
      {...otherProps}
    >
      <StyledArrowIcon reverse={reverse} />
    </StyledProgressCircle>
  )
}
