import React, { useCallback } from "react"
import styled, { css } from "styled-components"

import { CopyText } from "../../../components/Text"
import { useLocale } from "../../../locale"
import {
  breakpoints,
  TEXT_TOKEN_COPY_BREAKPOINT_LG,
  TEXT_TOKEN_COPY_BREAKPOINT_MD,
  TEXT_TOKEN_COPY_BREAKPOINT_SM,
  TEXT_TOKEN_COPY_BREAKPOINT_XL,
} from "../../../styles"
import {
  ANIMATION_DURATION,
  ANIMATION_EASING,
} from "../../../styles/animations"
import {
  COLOR_BLUE,
  COLOR_GRAY_200,
  COLOR_GRAY_400,
  SPACE_M,
  SPACE_S,
} from "../../../styles/primitives"
import { usePrettyDistance } from "../../map/usePrettyDistance"
import { GarageDetails } from "./GarageDetails"

export function GarageLocationItem({
  location,
  distanceToAutocompleteLocation,
  isSelected,
  onSelect,
  pickUpAvailableLabel,
  unuScooterClassicOnlyLabel,
  ...otherProps
}) {
  const handleClick = useCallback(() => onSelect(location.id), [
    location.id,
    onSelect,
  ])
  const { locale } = useLocale()
  const prettyDistanceToUser = usePrettyDistance(
    locale,
    distanceToAutocompleteLocation
  )

  return (
    <Root
      key={location.id}
      isSelected={isSelected}
      onClick={handleClick}
      {...otherProps}
    >
      <StatusBubble />
      <STitle>{location.data.name}</STitle>
      <SDistanceText type="copy">{prettyDistanceToUser}</SDistanceText>
      <SGarageDetails
        location={location}
        distanceToAutocompleteLocation={distanceToAutocompleteLocation}
        pickUpAvailableLabel={pickUpAvailableLabel}
        unuScooterClassicOnlyLabel={unuScooterClassicOnlyLabel}
      />
    </Root>
  )
}

const StatusBubble = styled.div`
  grid-area: status;

  width: 16px;
  height: 16px;
  margin-right: ${SPACE_M}px;
  border-radius: 50%;
  background-color: ${COLOR_BLUE};

  ${breakpoints({
    SM: css`
      margin-top: calc(${TEXT_TOKEN_COPY_BREAKPOINT_SM.lineHeight}em / 2 - 8px);
    `,
    MD: css`
      margin-top: calc(${TEXT_TOKEN_COPY_BREAKPOINT_MD.lineHeight}em / 2 - 8px);
    `,
    LG: css`
      margin-top: calc(${TEXT_TOKEN_COPY_BREAKPOINT_LG.lineHeight}em / 2 - 8px);
    `,
    XL: css`
      margin-top: calc(${TEXT_TOKEN_COPY_BREAKPOINT_XL.lineHeight}em / 2 - 8px);
    `,
  })}
`
const STitle = styled(CopyText)`
  grid-area: title;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Root = styled.li`
  display: grid;
  grid-template-areas:
    "status title   distance"
    ".      details details";
  grid-template-columns: min-content auto min-content;
  border-radius: 8px;
  padding: 6px 6px 6px 20px;

  cursor: pointer;
  transition: background-color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;

  &:not(:last-child) {
    margin-bottom: ${SPACE_M}px;
  }

  &:hover {
    background-color: ${COLOR_GRAY_200};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${COLOR_GRAY_200};
    `}
`

const SGarageDetails = styled(GarageDetails)`
  grid-area: details;
`

const SDistanceText = styled(CopyText)`
  grid-area: distance;
  margin-left: ${SPACE_S}px;
  color: ${COLOR_GRAY_400};
  text-align: right;
`
