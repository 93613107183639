import React from "react"

import styled, { css } from "styled-components"
import { getSliceRootElementProps } from "./utils"
import { useGlobalTranslation } from "../../globalValues"
import { breakpoints, ThemeProvider } from "../../styles"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../../styles/animations"
import { MAX_WIDTH } from "../../styles/breakpoints"
import {
  COLOR_GRAY_600,
  SPACE_1XL,
  SPACE_3XL,
  SPACE_4XL,
  SPACE_L,
  SPACE_M,
  SPACE_S,
  SPACE_XL,
} from "../../styles/primitives"
import { Image } from "../Image"
import { RichText } from "../RichText"

import { Text } from "../Text"
import { modelColorsBySKUColorId } from "../../styles/scooterModelColors"

const ScrollContainer = styled.section`
  overflow-x: auto;
  padding-bottom: ${SPACE_XL}px;
`

const BackgroundBoxImage = styled(Image)`
  &.gatsby-image-wrapper {
    position: absolute;
  }

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  height: 100%;
  opacity: 0;

  transition: opacity ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
`

const ItemContainer = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  scroll-snap-align: center;

  ${breakpoints({
    SM: css`
      padding: 36px ${SPACE_M}px 0;
    `,
    MD: css`
      padding: 88px ${SPACE_M}px 0;
    `,
    LG: css`
      padding: 88px ${SPACE_XL}px 0;
    `,
    XL: css`
      padding: 88px ${SPACE_XL}px 0;
    `,
  })}

  border: 1px solid ${COLOR_GRAY_600};

  &:hover {
    ${BackgroundBoxImage} {
      opacity: 1;
    }
  }
`

const OuterContainer = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor};

  ${breakpoints({
    SM: css`
      padding-top: 80px;
    `,
    MD: css`
      padding-top: 88px;
    `,
    LG: css`
      padding-top: ${SPACE_4XL}px;
    `,
    XL: css`
      padding-top: ${SPACE_4XL}px;
    `,
  })};
`
const Container = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  width: fit-content;

  ${breakpoints({
    SM: css`
      padding: ${SPACE_L}px ${SPACE_L}px;
      grid-column-gap: ${SPACE_L}px;
      grid-auto-columns: calc(100vw - ${2 * SPACE_L}px);
    `,
    MD: css`
      padding: ${SPACE_L}px ${SPACE_L}px;
      grid-column-gap: ${SPACE_M}px;
      grid-auto-columns: minmax(351px, 1fr);
    `,
    LG: css`
      padding: 112px ${SPACE_4XL}px;
      grid-column-gap: ${SPACE_L}px;
      grid-auto-columns: calc((100vw - ${2 * 112}px - ${SPACE_L}px) / 2);
    `,
    XL: css`
      padding: 0 calc(max(100% - ${MAX_WIDTH}px, 0px) / 2 + ${SPACE_XL}px);
      grid-column-gap: ${SPACE_L}px;
      grid-auto-columns: minmax(389px, 1fr);
    `,
  })}
`

const HeadlineText = styled(Text)`
  margin: auto;
  max-width: ${MAX_WIDTH}px;

  ${breakpoints({
    SM: css`
      margin-bottom: ${SPACE_1XL}px;
      padding: 0 ${SPACE_M}px;
    `,
    MD: css`
      padding: 0 ${SPACE_L}px;
      text-align: center;
      margin-bottom: ${SPACE_3XL}px;
    `,
    LG: css`
      padding: 0 ${112}px;
      margin-bottom: ${80}px;
    `,
    XL: css`
      padding: 0 ${SPACE_XL}px;
      margin-bottom: ${80}px;
    `,
  })}
`

const TitleText = styled(Text)`
  text-align: center;
  display: block;
  padding-bottom: ${SPACE_M}px;

  ${breakpoints({
    SM: css`
      padding-bottom: ${SPACE_S}px;
    `,
  })}
`

const DescriptionText = styled(Text)`
  margin-bottom: ${SPACE_XL}px;

  text-align: center;
`

const SectionTitleText = styled(Text)`
  text-align: center;
  margin-bottom: ${SPACE_M}px;
`

const SectionContent = styled.div`
  height: 116px;
  margin-bottom: ${SPACE_XL}px;
`

const Section = styled.div``

const ColorSection = styled(Section)`
  margin-top: auto;

  height: 158px;
  > ${SectionContent} {
    height: auto;
    width: fit-content;
    margin: auto;
    display: flex;
    row-gap: ${SPACE_S}px;
    column-gap: ${SPACE_M}px;
    flex-wrap: wrap;
    justify-content: center;
  }
`

const PowerSection = styled(Section)``

const PowerText = styled(Text)`
  width: fit-content;
  margin: auto;
`

const Color = styled.div`
  border-radius: 100%;
  width: 32px;
  height: 32px;
  background-color: ${({ rbgCode }) => rbgCode};
`

export function ModelComparisonSlice({
  slice,
  topInterweavingPadder,
  themeName = "dark",
}) {
  const { headline, scooterModels } = slice

  return (
    <ThemeProvider themeName={themeName}>
      <OuterContainer {...getSliceRootElementProps(slice)}>
        {topInterweavingPadder}
        <HeadlineText type="hero">{headline.headline}</HeadlineText>
        <ScrollContainer>
          <Container>
            {scooterModels.map((model, columnIndex) => (
              <ModelItem key={columnIndex} model={model} />
            ))}
          </Container>
        </ScrollContainer>
      </OuterContainer>
    </ThemeProvider>
  )
}

function ModelItem({ model, columnIndex }) {
  const colorsLabel = useGlobalTranslation("colorsLabel")
  const powerLabel = useGlobalTranslation("powerLabel")

  const {
    title,
    description,
    colorIds,
    tileBackgroundImage,
    powerInWatt,
  } = model

  return (
    <ItemContainer columnIndex={columnIndex}>
      <BackgroundBoxImage asset={tileBackgroundImage} />

      <TitleText type="primary" columnIndex={columnIndex}>
        {title}
      </TitleText>
      <DescriptionText columnIndex={columnIndex} type="copy">
        <RichText data={description} />
      </DescriptionText>

      {colorIds && (
        <ColorSection columnIndex={columnIndex}>
          <SectionTitleText type="tertiary">{colorsLabel}</SectionTitleText>
          <SectionContent>
            {colorIds.map((colorId, index) => (
              <Color
                key={colorId}
                rbgCode={modelColorsBySKUColorId[colorId].color}
              />
            ))}
          </SectionContent>
        </ColorSection>
      )}
      {powerInWatt && (
        <PowerSection columnIndex={columnIndex}>
          <SectionTitleText type="tertiary">{powerLabel}</SectionTitleText>
          <SectionContent>
            <PowerText type="primary">{`${powerInWatt} W`}</PowerText>
          </SectionContent>
        </PowerSection>
      )}
    </ItemContainer>
  )
}
