import React, { useMemo, useRef } from "react"
import styled from "styled-components"
import { useDimensions } from "../../../useDimensions"
import { ScooterImageModel } from "./ScooterImageModel"

export const MODEL_ASPECT_RATIO = 4 / 3

export function ScooterModel({
  scooterModelSlice: { scooterImages },
  currentColorId,
  aspectRatio = MODEL_ASPECT_RATIO,
  ...otherProps
}) {
  const rootElementRef = useRef()
  const iframeWrapperRect = useDimensions(rootElementRef)

  const currentImageIndex = useMemo(() => {
    const index = scooterImages.findIndex(({ title }) =>
      title.match(`{{color=${currentColorId}`)
    )
    return index >= 0 ? index : 0
  }, [currentColorId, scooterImages])

  return (
    <SRoot ref={rootElementRef} aspectRatio={aspectRatio} {...otherProps}>
      <SScooterImageModel
        images={scooterImages}
        activeIndex={currentImageIndex}
      />
    </SRoot>
  )
}

const SRoot = styled.div`
  padding-bottom: ${({ aspectRatio }) => (1 / aspectRatio) * 100}%;
  position: relative;
`

const SScooterImageModel = styled(ScooterImageModel)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export function useColorIdsFromScooterImages(scooterImages) {
  return useMemo(
    () =>
      scooterImages.map(
        ({ title }) =>
          title.match(new RegExp(`{{color=([a-zA-Z0-9_\\-]+)}}`))?.[1] || null
      ),
    [scooterImages]
  )
}
