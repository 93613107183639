import React, { useLayoutEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { breakpoints } from "../../../styles"
import {
  ANIMATION_DURATION,
  ANIMATION_EASING,
} from "../../../styles/animations"
import { COLOR_BLUE } from "../../../styles/primitives"

export function RouteSegment({
  transitionState,
  pathDataString,
  totalLength,
  transitionDurationMS,
  onStartDrawingAnimation,
  ...otherProps
}) {
  const pathElementRef = useRef()

  useLayoutEffect(() => {
    if (transitionState === "entering") {
      onStartDrawingAnimation?.(transitionDurationMS)
      pathElementRef.current.style.strokeDashoffset = "0"
      pathElementRef.current.style.opacity = "1"
    }
    if (transitionState === "exiting") {
      pathElementRef.current.style.transitionDuration = `${ANIMATION_DURATION}ms`
      pathElementRef.current.style.strokeDashoffset = `${totalLength}`
      setTimeout(() => {
        pathElementRef.current.style.transitionDuration = ""
      }, 0)
    }
  }, [
    onStartDrawingAnimation,
    totalLength,
    transitionDurationMS,
    transitionState,
  ])

  return (
    <SRoot {...otherProps}>
      <SSVG viewBox="0 0 1920 1080">
        <SPath
          d={pathDataString}
          ref={pathElementRef}
          totalLength={totalLength}
          transitionDurationMS={transitionDurationMS}
        />
      </SSVG>
    </SRoot>
  )
}

const SRoot = styled.div``

const SSVG = styled.svg`
  display: block;
  width: 100%;
  height: 100%;
  fill: none;
`

const SPath = styled.path`
  stroke: ${COLOR_BLUE};
  stroke-linecap: round;
  stroke-dashoffset: ${({ totalLength }) => totalLength};
  stroke-dasharray: ${({ totalLength }) => totalLength};
  transition: stroke-dashoffset ${ANIMATION_EASING}
    ${({ transitionDurationMS }) => transitionDurationMS}ms;

  ${breakpoints({
    SM: css`
      stroke-width: 12px;
    `,
    MD: css`
      stroke-width: 10px;
    `,
    LG: css`
      stroke-width: 8px;
    `,
    XL: css`
      stroke-width: 6px;
    `,
  })}
`
