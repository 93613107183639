import styled, { css } from "styled-components"
import {
  breakpoints,
  TEXT_TOKEN_COPY_BREAKPOINT_MD,
  TEXT_TOKEN_COPY_BREAKPOINT_SM,
  TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_MD,
} from "../../../styles"
import {
  FONT_SIZE_1XL,
  FONT_SIZE_6XL,
  LETTER_SPACING_TIGHTER,
  LINE_HEIGHT_NORMAL,
} from "../../../styles/primitives"
import {
  RichText,
  RichTextParagraphText,
  SRichTextHeadline1Text,
  SRichTextHeadline2Text,
  SRichTextHeadline3Text,
  SRichTextHeadline4Text,
  SRichTextHeadline5Text,
  SRichTextHeadline6Text,
} from "../../RichText"
import { createTextStylesFromToken } from "../../Text"

const lgAndXLParagraphConfig = {
  ...TEXT_TOKEN_COPY_BREAKPOINT_MD,
  fontSize: 1.5,
  lineHeight: LINE_HEIGHT_NORMAL,
  letterSpacing: LETTER_SPACING_TIGHTER,
}

export const imagePairCaptionTextToken = {
  SM: TEXT_TOKEN_COPY_BREAKPOINT_SM,
  MD: TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_MD,
  LG: lgAndXLParagraphConfig,
  XL: lgAndXLParagraphConfig,
}

export const SBackgroundImageSliceRichText = styled(RichText)`
  ${RichTextParagraphText} {
    ${createTextStylesFromToken(imagePairCaptionTextToken)};
  }

  ${breakpoints({
    XL: css`
      ${SRichTextHeadline1Text},
      ${SRichTextHeadline2Text},
      ${SRichTextHeadline3Text},
      ${SRichTextHeadline4Text},
      ${SRichTextHeadline5Text},
      ${SRichTextHeadline6Text} {
        font-size: ${FONT_SIZE_6XL}rem;
        letter-spacing: ${LETTER_SPACING_TIGHTER}em;
      }
    `,
  })}
`
