import React, { useCallback, useLayoutEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import { getSliceRootElementProps } from "./utils"
import { breakpoints, ThemeProvider } from "../../styles"
import { MAX_WIDTH } from "../../styles/breakpoints"
import {
  SPACE_1XL,
  SPACE_2XL,
  SPACE_3XL,
  SPACE_4XL,
  SPACE_L,
  SPACE_S,
  SPACE_XL,
  FONT_SIZE_L,
  FONT_SIZE_4XL,
  FONT_SIZE_6XL,
} from "../../styles/primitives"
import { RichText } from "../RichText"
import { SubHeadlineParagraphText, Text } from "../Text"
import { Topline } from "../Topline"

function createGridTemplateRows({
  toTopline,
  toHeadline,
  toSubline,
  toBottom,
}) {
  return css`
    grid-template-rows: [top] ${toTopline}px [topline-start] auto [topline-end] ${toHeadline}px [headline-start] auto [headline-end] ${toSubline}px [subline-start] auto [subline-end] ${toBottom}px [bottom];
  `
}

function createGridTemplateColumns({ outerPadding, sublineIndent }) {
  return css`
    grid-template-columns: [left] ${outerPadding} [topline-start] ${sublineIndent} [subline-start] auto [topline-end] 0 [subline-end] ${outerPadding} [right];
  `
}

const Container = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.backgroundColor};
  overflow: hidden;

  ${breakpoints({
    SM: css`
      ${createGridTemplateColumns({
        outerPadding: `${SPACE_L}px`,
        sublineIndent: `${0}px`,
      })};
      ${createGridTemplateRows({
        toTopline: SPACE_3XL + SPACE_S,
        toHeadline: SPACE_L,
        toSubline: SPACE_L,
        toBottom: SPACE_L,
      })};
    `,
    MD: css`
      ${createGridTemplateColumns({
        outerPadding: `${SPACE_L}px`,
        sublineIndent: `${0}px`,
      })};
      ${createGridTemplateRows({
        toTopline: SPACE_4XL + SPACE_2XL,
        toHeadline: SPACE_1XL,
        toSubline: SPACE_3XL + SPACE_S,
        toBottom: SPACE_2XL,
      })};
    `,
    LG: css`
      ${createGridTemplateColumns({
        outerPadding: `${SPACE_XL}px`,
        sublineIndent: `${82}px`,
      })};
      ${createGridTemplateRows({
        toTopline: SPACE_4XL + SPACE_3XL,
        toHeadline: SPACE_2XL,
        toSubline: SPACE_4XL,
        toBottom: SPACE_4XL,
      })};
    `,
    XL: css`
      ${createGridTemplateColumns({
        outerPadding: `calc(max(0px, 100% - ${MAX_WIDTH}px) / 2 + ${SPACE_XL}px)`,
        sublineIndent: `${206}px`,
      })};
      ${createGridTemplateRows({
        toTopline: 117,
        toHeadline: SPACE_XL,
        toSubline: SPACE_3XL,
        toBottom: SPACE_4XL,
      })};
    `,
  })};
`

const BIG_HEADLINE_OVERFLOW_VW = 2

const HeadlineContainer = styled.div`
  grid-row-start: headline-start;
  grid-row-end: headline-end;
  grid-column-start: left;
  grid-column-end: right;
`

const HeadlineTextSVG = styled.svg`
  white-space: nowrap;
  width: calc(100% + ${2 * BIG_HEADLINE_OVERFLOW_VW}vw);
  margin-left: -${BIG_HEADLINE_OVERFLOW_VW}vw;
  font-weight: 600;
  letter-spacing: -0.04em;
  fill: ${({ theme }) => theme.textColor};
`
const ToplineText = styled(Topline)`
  grid-row-start: topline-start;
  grid-row-end: topline-end;
  grid-column-start: topline-start;
  grid-column-end: topline-end;
`
const SublineRichText = styled(RichText)`
  grid-row-start: subline-start;
  grid-row-end: subline-end;
  grid-column-start: subline-start;
  grid-column-end: subline-end;

  ${breakpoints({
    SM: css`
      font-size: ${FONT_SIZE_L}rem;
    `,
    MD: css`
      font-size: ${FONT_SIZE_4XL}rem;
    `,
    LG: css`
      font-size: ${FONT_SIZE_6XL}rem;
    `,
    XL: css`
      font-size: ${FONT_SIZE_6XL}rem;
    `,
  })}
`

export function BigDisruptorSlice({ slice }) {
  const { theme, headline, topline, subline } = slice

  /* const [fontSize, setFontSize] = useState(null) */
  const [svgTextBBox, setSVGTextBBox] = useState(null)
  /* const headlineTextRef = useRef() */
  const svgTextRef = useRef()

  const updateSVGTextBBox = useCallback(() => {
    setSVGTextBBox(svgTextRef.current.getBBox())
  }, [])

  useLayoutEffect(() => {
    updateSVGTextBBox()
    const timeoutId = setTimeout(updateSVGTextBBox, 0)

    return () => clearTimeout(timeoutId)
  }, [updateSVGTextBBox])

  return (
    <ThemeProvider themeName={theme}>
      <Container {...getSliceRootElementProps(slice)}>
        <HeadlineContainer>
          <HeadlineTextSVG
            viewBox={
              svgTextBBox
                ? `${svgTextBBox.x} ${svgTextBBox.y} ${svgTextBBox.width} ${svgTextBBox.height}`
                : "0 0 0 0"
            }
            xmlns="http://www.w3.org/2000/svg"
          >
            <text ref={svgTextRef} dominantBaseline="hanging">
              {headline.headline}
            </text>
          </HeadlineTextSVG>
        </HeadlineContainer>
        <ToplineText type="primary" topline={topline} fromRight={false} />
        <SublineRichText
          data={subline}
          keepTopLevelParagraph={true}
          components={sublineRichTextComponents}
        />
      </Container>
    </ThemeProvider>
  )
}

const sublineRichTextComponents = {
  ParagraphText: SubHeadlineParagraphText,
}
