import React, { useCallback, useLayoutEffect, useRef } from "react"
import styled, { css } from "styled-components"
import { COLOR_UI_BACKGROUND_PRIMARY } from "../styles"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../styles/animations"

const SIZE = 88
const STROKE_WIDTH = 3
const RADIUS = SIZE / 2 - STROKE_WIDTH / 2
const CIRCUMFERENCE = Math.PI * (RADIUS * 2)

const ChildrenContainer = styled.div`
  position: absolute;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;

  transition: background-color linear ${ANIMATION_DURATION}ms,
    filter ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
`

const SSVGRoot = styled.svg`
  display: block;
  width: 100%;
  height: 100%;
  stroke: ${({ theme }) => theme.backgroundColor};
  transition: filter ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
`

const SPrimaryCircle = styled.circle`
  stroke: ${COLOR_UI_BACKGROUND_PRIMARY};

  transform-origin: center;
  stroke-dashoffset: ${CIRCUMFERENCE};
  transform: rotate(-90deg);
`
const SSecondaryCircle = styled.circle`
  stroke: ${({ theme }) => theme.textColor};

  transform-origin: center;
  stroke-dashoffset: 0;
  transform: rotate(-90deg);
`

const Container = styled.div`
  cursor: pointer;
  width: ${SIZE}px;
  height: ${SIZE}px;

  .colored-fg-stroke {
    stroke: ${({ theme }) => COLOR_UI_BACKGROUND_PRIMARY};
  }

  .colored-fg-fill {
    fill: ${({ theme }) => COLOR_UI_BACKGROUND_PRIMARY};
  }

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          pointer-events: none;
          > ${ChildrenContainer} {
            filter: saturate(0);
          }
        `
      : css`
          > ${ChildrenContainer} {
            filter: saturate(1);
          }
          &:hover {
            > ${SSVGRoot} {
              stroke: ${({ theme }) => theme.textColor};
            }
            > ${ChildrenContainer} {
              background-color: ${({ theme }) => theme.textColor};

              .colored-fg-stroke {
                stroke: ${({ theme }) => COLOR_UI_BACKGROUND_PRIMARY};
              }

              .colored-fg-fill {
                fill: ${({ theme }) => COLOR_UI_BACKGROUND_PRIMARY};
              }
            }
          }
        `}

  transition: filter ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;

  ${({ isPaused }) =>
    isPaused
      ? css`
          ${SSVGRoot} {
            filter: saturate(0);
          }
        `
      : css`
          ${SSVGRoot} {
            filter: saturate(1);
          }
        `};
`

const animationConfigs = {
  forwardsFilling: (cycleDuration, initialValue = CIRCUMFERENCE) => [
    [
      {
        offset: 0,
        strokeDashoffset: initialValue,
      },
      {
        offset: 1,
        strokeDashoffset: 0,
      },
    ],
    {
      duration: cycleDuration,
      delay: 0,
      fill: "forwards",
      iterations: 1,
    },
  ],
  forwardsUnfilling: (cycleDuration, initialValue = 0) => [
    [
      {
        offset: 0,
        strokeDashoffset: initialValue,
      },
      {
        offset: 1,
        strokeDashoffset: -CIRCUMFERENCE,
      },
    ],
    {
      duration: cycleDuration,
      delay: 0,
      fill: "forwards",
      iterations: 1,
    },
  ],
}

export function ProgressCircleButton({
  autoFillCycleDuration,
  children,
  onNextCycle,
  isPaused,
  isDisabled,
  ...otherProps
}) {
  const secondaryCircleElementRef = useRef()
  const primaryCircleElementRef = useRef()
  const currentAnimationTupleRef = useRef(null)
  const mostRecentAnimationIdRef = useRef("evenCycle")
  const isPausedRef = useRef(isPaused)
  isPausedRef.current = isPaused

  const animateNextCycle = useCallback(() => {
    if (currentAnimationTupleRef.current?.[0].playState === "paused") {
      currentAnimationTupleRef.current.forEach(animation => animation.play())
      return
    }

    if (currentAnimationTupleRef.current?.[0].playState === "running") {
      return
    }

    if (mostRecentAnimationIdRef.current === "oddCycle") {
      mostRecentAnimationIdRef.current = "evenCycle"
      currentAnimationTupleRef.current = [
        primaryCircleElementRef.current.animate(
          ...animationConfigs.forwardsUnfilling(autoFillCycleDuration)
        ),
        secondaryCircleElementRef.current.animate(
          ...animationConfigs.forwardsFilling(autoFillCycleDuration)
        ),
      ]
    } else {
      mostRecentAnimationIdRef.current = "oddCycle"
      currentAnimationTupleRef.current = [
        primaryCircleElementRef.current.animate(
          ...animationConfigs.forwardsFilling(autoFillCycleDuration)
        ),
        secondaryCircleElementRef.current.animate(
          ...animationConfigs.forwardsUnfilling(autoFillCycleDuration)
        ),
      ]
    }

    const checkAnimation = currentAnimationTupleRef.current[0]
    Promise.all(
      currentAnimationTupleRef.current.map(animation => animation.finished)
    )
      .then(() => {
        if (checkAnimation !== currentAnimationTupleRef.current[0]) {
          return
        }
        if (isPausedRef.current) {
          return
        }
        animateNextCycle()
        onNextCycle()
      })
      .catch(() => {})

    return currentAnimationTupleRef.current
  }, [autoFillCycleDuration, onNextCycle])

  useLayoutEffect(() => {
    if (isPaused) {
      currentAnimationTupleRef.current?.forEach(animation => animation.pause())
    } else {
      animateNextCycle()
    }
  }, [animateNextCycle, autoFillCycleDuration, isPaused])

  const handleImmediateNext = useCallback(() => {
    const interrupedAnimations = currentAnimationTupleRef.current
    currentAnimationTupleRef.current = null

    const primaryCircleCurrentDashoffset = getComputedStyle(
      primaryCircleElementRef.current
    ).strokeDashoffset
    const secondaryCircleCurrentDashoffset = getComputedStyle(
      secondaryCircleElementRef.current
    ).strokeDashoffset

    interrupedAnimations?.forEach(animation => animation.cancel())

    const fastForwardAnimations =
      mostRecentAnimationIdRef.current === "evenCycle"
        ? [
            primaryCircleElementRef.current.animate(
              ...animationConfigs.forwardsUnfilling(
                300,
                primaryCircleCurrentDashoffset
              )
            ),
            secondaryCircleElementRef.current.animate(
              ...animationConfigs.forwardsFilling(
                300,
                secondaryCircleCurrentDashoffset
              )
            ),
          ]
        : [
            primaryCircleElementRef.current.animate(
              ...animationConfigs.forwardsFilling(
                300,
                primaryCircleCurrentDashoffset
              )
            ),
            secondaryCircleElementRef.current.animate(
              ...animationConfigs.forwardsUnfilling(
                300,
                secondaryCircleCurrentDashoffset
              )
            ),
          ]

    Promise.all(fastForwardAnimations.map(({ finished }) => finished)).then(
      () => {
        if (isPausedRef.current) {
          return
        }
        animateNextCycle()
      }
    )

    onNextCycle()
  }, [animateNextCycle, onNextCycle])

  return (
    <Container
      {...otherProps}
      onClick={handleImmediateNext}
      isPaused={isPaused}
      isDisabled={isDisabled}
    >
      <SSVGRoot
        width={SIZE}
        height={SIZE}
        viewBox={`0 0 ${SIZE} ${SIZE}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <SSecondaryCircle
          cx={SIZE / 2}
          cy={SIZE / 2}
          r={RADIUS}
          fill="none"
          strokeWidth={STROKE_WIDTH}
          strokeDasharray={`${CIRCUMFERENCE} ${CIRCUMFERENCE}`}
          ref={secondaryCircleElementRef}
        />
        <SPrimaryCircle
          cx={SIZE / 2}
          cy={SIZE / 2}
          r={RADIUS}
          fill="none"
          strokeWidth={STROKE_WIDTH}
          strokeDasharray={`${CIRCUMFERENCE} ${CIRCUMFERENCE}`}
          ref={primaryCircleElementRef}
        />
      </SSVGRoot>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  )
}
