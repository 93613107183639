import React from "react"
import styled, { css } from "styled-components"
import { getSliceRootElementProps } from "../utils"
import { breakpoints, ThemeProvider } from "../../../styles"
import { MAX_WIDTH } from "../../../styles/breakpoints"

import {
  SPACE_2XL,
  SPACE_L,
  SPACE_TOKEN_STACK_2XL,
  SPACE_TOKEN_STACK_4XL,
  SPACE_XL,
} from "../../../styles/primitives"
import { Text } from "../../Text"

import { CTAButton } from "../../CTAButton"
import { TeaserColumns } from "../../TeaserColumns"

const SRoot = styled.section`
  padding: ${SPACE_TOKEN_STACK_4XL}px
    calc(max(0px, 100vw - ${MAX_WIDTH}px) / 2 + ${SPACE_XL}px);

  ${breakpoints({
    MD: css`
      padding: ${SPACE_TOKEN_STACK_2XL}px ${SPACE_L}px;
    `,
  })}
`

const Header = styled.header`
  margin-bottom: ${SPACE_2XL}px;

  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${SPACE_XL}px;
  align-items: center;
`

export const ColumnTeaserSlice = styled(({ slice, ...otherProps }) => {
  const {
    headline,
    teasers = [],
    numberOfColumns,
    showLinks,
    showDates,
    button,
  } = slice

  return (
    <ThemeProvider themeName="stone">
      <SRoot
        {...getSliceRootElementProps(slice, otherProps, {
          sliceBackgroundVariant: "stone",
        })}
      >
        {(headline?.headline || button) && (
          <Header>
            {headline?.headline && (
              <Text type="primary">{headline.headline}</Text>
            )}
            {button && <SCTAButton cta={button} />}
          </Header>
        )}
        <TeaserColumns
          teasers={teasers}
          showLinks={showLinks}
          showDates={showDates}
          numberOfColumns={numberOfColumns}
        />
      </SRoot>
    </ThemeProvider>
  )
})`
  & + & {
    margin-top: -${SPACE_2XL}px;
  }
`

const SCTAButton = styled(CTAButton)`
  justify-self: flex-end;
`
