import { useCallback, useMemo, useRef, useState } from "react"

export function useMapAutoComplete() {
  const [selectedLocation, setSelectedLocation] = useState({ type: "NONE" })
  const autoCompleteRef = useRef(null)

  const handleAutoCompleteLoad = useCallback(autoComplete => {
    autoCompleteRef.current = autoComplete
  }, [])

  const handleAutoCompletePlaceChanged = useCallback(() => {
    const autoCompletedPlace = autoCompleteRef.current.getPlace()

    if (Object.keys(autoCompletedPlace).length === 1) {
      if (autoCompletedPlace.name === "") {
        setSelectedLocation({ type: "NONE" })
      } else {
        setSelectedLocation({ type: "NO_MATCH" })
      }

      return
    }

    const { formatted_address, geometry } = autoCompletedPlace
    setSelectedLocation({
      type: "MATCH",
      readableValue: formatted_address,
      geoLocation: {
        latitude: geometry.location.lat(),
        longitude: geometry.location.lng(),
      },
    })
  }, [])

  function clearAutocompletedLocation() {
    setSelectedLocation({ type: "NONE" })
  }

  return useMemo(
    () => ({
      selectedLocation,
      handleAutoCompleteLoad,
      handleAutoCompletePlaceChanged,
      clearAutocompletedLocation,
    }),
    [handleAutoCompleteLoad, handleAutoCompletePlaceChanged, selectedLocation]
  )
}
