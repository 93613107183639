import React from "react"

import styled, { css } from "styled-components"
import { getSliceRootElementProps } from "./utils"
import {
  breakpoints,
  TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_LG,
  TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_MD,
  TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_SM,
  TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_XL,
  ThemeProvider,
} from "../../styles"
import {
  SPACE_3XL,
  SPACE_L,
  SPACE_M,
  SPACE_TOKEN_STACK_2XL,
  SPACE_TOKEN_STACK_3XL,
  SPACE_TOKEN_STACK_4XL,
  SPACE_TOKEN_STACK_M,
  SPACE_TOKEN_STACK_S,
  SPACE_TOKEN_STACK_XL,
  SPACE_XL,
} from "../../styles/primitives"
import { RichText, RichTextParagraphText } from "../RichText"

import { createTextStylesFromToken, Text } from "../Text"
import { SliceContainer } from "./sliceContainers"

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ sizer }) =>
    breakpoints({
      SM: css`
        padding-top: ${sizer ?? SPACE_TOKEN_STACK_2XL}px;
        padding-bottom: ${sizer ?? SPACE_TOKEN_STACK_2XL}px;
        padding-left: ${SPACE_L}px;
        padding-right: ${SPACE_L}px;
      `,
      MD: css`
        padding-top: ${sizer ?? SPACE_TOKEN_STACK_4XL}px;
        padding-bottom: ${sizer ??
        SPACE_TOKEN_STACK_3XL + SPACE_TOKEN_STACK_S}px;
        padding-left: ${SPACE_L}px;
        padding-right: ${SPACE_L}px;
      `,
      LG: css`
        padding-top: ${sizer ?? SPACE_TOKEN_STACK_4XL}px;
        padding-bottom: ${sizer ??
        SPACE_TOKEN_STACK_2XL + SPACE_TOKEN_STACK_M}px;
        padding-left: ${SPACE_XL}px;
        padding-right: ${SPACE_XL}px;
      `,
      XL: css`
        padding-top: ${sizer ?? SPACE_TOKEN_STACK_4XL}px;
        padding-bottom: ${sizer ?? SPACE_TOKEN_STACK_4XL}px;
        padding-left: ${SPACE_3XL + SPACE_M}px;
        padding-right: ${SPACE_3XL + SPACE_M}px;
      `,
    })}
`

const StyledHeadlineText = styled(Text)`
  width: 50%;
  padding-right: ${SPACE_L / 2}px;

  ${breakpoints({
    SM: css`
      width: 100%;
      padding-right: 0;
    `,
  })};
`
const SRichText = styled(RichText)`
  width: 50%;
  align-self: flex-end;
  padding-left: ${SPACE_L / 2}px;

  ${breakpoints({
    SM: css`
      width: 100%;
      padding-left: 0;
      margin-top: ${SPACE_TOKEN_STACK_M}px;
      ul {
        padding: ${SPACE_L}px;
      }
    `,
    MD: css`
      width: 100%;
      padding-left: 0;
      margin-top: ${SPACE_TOKEN_STACK_XL}px;
      ul {
        padding: ${SPACE_L}px;
      }
    `,
    LG: css`
      margin-top: ${SPACE_TOKEN_STACK_M}px;
      ul {
        padding: 0;
      }
    `,
    XL: css`
      margin-top: ${SPACE_TOKEN_STACK_XL}px;

      ul {
        padding: 0;
      }
    `,
  })}

  ${RichTextParagraphText} {
    ${createTextStylesFromToken({
      SM: TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_SM,
      MD: TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_MD,
      LG: TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_LG,
      XL: TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_XL,
    })}
  }
`

const generateSizer = breakpoint => {
  switch (breakpoint) {
    case "small":
      return SPACE_M
    case "medium":
      return SPACE_L
    case "large":
      return SPACE_XL
    default:
      return undefined
  }
}

export function Divider({ slice }) {
  const { headline, content, theme } = slice

  return (
    <ThemeProvider themeName={theme}>
      <SliceContainer {...getSliceRootElementProps(slice)}>
        <InnerContainer sizer={generateSizer(slice.size)}>
          <StyledHeadlineText type="primary">
            {headline.headline}
          </StyledHeadlineText>
          {content && <SRichText data={content} keepTopLevelParagraph={true} />}
        </InnerContainer>
      </SliceContainer>
    </ThemeProvider>
  )
}
