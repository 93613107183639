import styled, { css } from "styled-components"
import { breakpoints } from "../styles"

export const interweavingPadderGridRows = [
  "top-interweaving-padder-start",
  "top-interweaving-padder-end",
]

export const interweavingGridTemaplteRowStyle =
  "[top-interweaving-padder-start] min-content [top-interweaving-padder-end]"

export const InterweavingPadder = styled.div`
  grid-row-start: ${interweavingPadderGridRows[0]};
  grid-row-end: ${interweavingPadderGridRows[1]};

  ${({ values }) =>
    breakpoints({
      SM: css`
        height: ${values.SM}px;
      `,
      MD: css`
        height: ${values.MD}px;
      `,
      LG: css`
        height: ${values.LG}px;
      `,
      XL: css`
        height: ${values.XL}px;
      `,
    })}
`
