import React from "react"

import styled, { css } from "styled-components"
import { breakpoints } from "../../../styles"
import { MAX_WIDTH } from "../../../styles/breakpoints"
import {
  SPACE_2XL,
  SPACE_3XL,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../../../styles/primitives"
import { RichText } from "../../RichText"
import { SBackgroundImageSliceRichText } from "./richTextComponents"

function createGridRows(spacing) {
  return css`
    grid-template-rows: [headline-left-start] 0 [headline-right-start] min-content [headline-left-end] 0 [headline-right-end] ${spacing}px [copy-left-start] 0 [copy-right-start] min-content [copy-left-end] 0 [copy-right-end];
  `
}

function createGridColumns(spacing) {
  return css`
    grid-template-columns:
      [left-start] 1fr [left-end] calc(${spacing} / 2) [center] calc(
        ${spacing} / 2
      )
      [right-start] 1fr [right-end];
  `
}

const Root = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${breakpoints({
    SM: css`
      grid-template-rows: [headline-left-start] min-content [headline-left-end] ${SPACE_M}px [copy-left-start] min-content [copy-left-end] ${SPACE_2XL}px [headline-right-start] min-content [headline-right-end] ${SPACE_M}px [copy-right-start] min-content [copy-right-end];
      grid-template-columns: [left-start] 0 [right-start] 1fr [center] 1fr [right-end] 0 [left-end];
    `,
    MD: css`
      ${createGridRows(SPACE_L)};
      ${createGridColumns(`${SPACE_M}px`)};
    `,
    LG: css`
      ${createGridRows(SPACE_L)};
      ${createGridColumns(`${SPACE_L}px`)};
    `,
    XL: css`
      ${createGridRows(SPACE_L)};
      ${createGridColumns(`${SPACE_3XL}px`)};
    `,
    MAX: css`
      ${createGridRows(SPACE_L)};
      ${createGridColumns(`calc((100% - ${MAX_WIDTH}px) / 2 + ${SPACE_XL}px)`)};
    `,
  })}
`

const SRichText = styled(SBackgroundImageSliceRichText)`
  flex-grow: 0;
  flex-shrink: 0;

  color: ${({ theme }) => theme.textColor};

  ${breakpoints({
    SM: css`
      flex-basis: 100%;
      &:last-child {
        margin-top: ${SPACE_2XL}px;
      }
    `,
    MD: css`
      flex-basis: calc(50% - ${SPACE_M / 2}px);
    `,
    LG: css`
      flex-basis: calc(50% - ${SPACE_L / 2}px);
    `,
    XL: css`
      flex-basis: calc(50% - ${SPACE_2XL / 2}px);
    `,
  })};
`

const SLeftRichText = styled(SRichText)`
  grid-row-start: copy-left-start;
  grid-row-end: copy-left-end;
  grid-column-start: left-start;
  grid-column-end: left-end;
`

const SRightRichText = styled(SRichText)`
  grid-row-start: copy-right-start;
  grid-row-end: copy-right-end;
  grid-column-start: right-start;
  grid-column-end: right-end;
`

export function CopyPair({ leftText, rightText, ...otherProps }) {
  return (
    <Root {...otherProps}>
      <SLeftRichText data={leftText} />
      <SRightRichText data={rightText} />
    </Root>
  )
}
