import React, { useMemo, useState } from "react"
import styled, { css } from "styled-components"

import { COLOR_INTERACTIVE_LINK } from "../styles"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../styles/animations"
import { SPACE_M, SPACE_S } from "../styles/primitives"
import { Link } from "./links"

export function ColorSelector({ state, getLinkProps, ...otherProps }) {
  const linksProps = useMemo(
    () =>
      getLinkProps ? state.colors.map(color => getLinkProps(color)) : null,
    [getLinkProps, state.colors]
  )
  return (
    <Root {...otherProps}>
      {state.colors.map(({ name, color }, index) => {
        if (linksProps) {
          return (
            <SColorLink
              color={color}
              title={name}
              isSelected={index === state.currentColorIndex}
              onClick={() => state.setCurrentColorIndex(index)}
              {...linksProps[index]}
              {...linksProps}
            />
          )
        }

        return (
          <SColorPlain
            color={color}
            title={name}
            isSelected={index === state.currentColorIndex}
            onClick={() => state.setCurrentColorIndex(index)}
          />
        )
      })}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  padding: ${SPACE_S}px ${SPACE_M}px;
  justify-content: space-between;

  > * {
    margin: 0 ${12 / 2}px;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

const ACTIVE_BORDER_WIDTH = 2
const ACTIVE_BORDER_SPACING = 3

const colorStyles = css`
  display: block;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: ${({ color }) => color};
  transition: transform ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
  transform: scale(1);
  cursor: pointer;
  position: relative;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);

  &:hover {
    &:after {
      border-color: ${COLOR_INTERACTIVE_LINK};
    }
  }

  &:after {
    position: absolute;
    top: -${ACTIVE_BORDER_WIDTH + ACTIVE_BORDER_SPACING}px;
    left: -${ACTIVE_BORDER_WIDTH + ACTIVE_BORDER_SPACING}px;
    bottom: -${ACTIVE_BORDER_WIDTH + ACTIVE_BORDER_SPACING}px;
    right: -${ACTIVE_BORDER_WIDTH + ACTIVE_BORDER_SPACING}px;

    content: " ";
    display: block;
    border-radius: 50%;
    border: ${ACTIVE_BORDER_WIDTH}px solid transparent;
    transition: border-color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      &:after {
        border-color: ${COLOR_INTERACTIVE_LINK};
      }
    `}
`

const SColorLink = styled(Link)`
  ${colorStyles};
`
const SColorPlain = styled.div`
  ${colorStyles};
`

export function useColorSelectorState(colors, initialIndex = 0) {
  const [currentColorIndex, setCurrentColorIndex] = useState(initialIndex)

  return useMemo(
    () => ({
      currentColorIndex,
      currentColor: colors[currentColorIndex],
      setCurrentColorIndex,
      colors,
    }),
    [colors, currentColorIndex]
  )
}
