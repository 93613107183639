/* global google */

import React, { memo, useCallback, useMemo } from "react"
import { GoogleMap, Marker, MarkerClusterer } from "@react-google-maps/api"

import { COLOR_BLUE } from "../../styles/primitives"
import { locationToMapsPosition } from "./helpers"

import { useLocationById } from "./mapHooks"

export const GOOGLE_API_MAP_ID = process.env.GATSBY_GOOGLE_MAPS_MAP_ID

const googleMapOptions = {
  mapId: GOOGLE_API_MAP_ID,
  disableDefaultUI: true,
  disableDoubleClickZoom: false,
}

const clustererOptions = {
  imagePath:
    "data:image/svg+xml;charset=utf-8," +
    encodeURIComponent(`<svg viewBox="0 0 440 440" xmlns="http://www.w3.org/2000/svg">
<circle cx="220" cy="220" r="100"  fill="${COLOR_BLUE}" stroke-width="0" />
</svg>`),
}

export function Map({
  selectedLocationId,
  autocompleteLocation,
  locations,
  overlayRef,
  renderLocationOverlay,
  mapState: { mapOnLoad, mapOnProjectionChanged },
  locationSearchState: {
    shownOverlayLocationId,
    setShownOverlayLocationId,
    setSelectedLocationId,
  },
}) {
  const closeLocationOverlay = useCallback(
    () => setShownOverlayLocationId(null),
    [setShownOverlayLocationId]
  )
  const handleMarkerClick = useCallback(
    location => {
      setShownOverlayLocationId(location.id)
      setSelectedLocationId(location.id)
    },
    [setSelectedLocationId, setShownOverlayLocationId]
  )
  const shownOverlayLocation = useLocationById(
    locations,
    shownOverlayLocationId
  )

  const renderedLocationOverlay = useMemo(
    () =>
      shownOverlayLocation &&
      renderLocationOverlay(shownOverlayLocation, closeLocationOverlay),
    [closeLocationOverlay, renderLocationOverlay, shownOverlayLocation]
  )

  const clustererRenderFunction = useCallback(
    clusterer =>
      locations.map(location => {
        const { id } = location
        return (
          <LocationMarker
            key={id}
            clusterer={clusterer}
            location={location}
            isSelected={id === selectedLocationId}
            handleMarkerClick={handleMarkerClick}
          />
        )
      }),
    [handleMarkerClick, locations, selectedLocationId]
  )

  const userPosition = useMemo(
    () =>
      autocompleteLocation.type === "MATCH"
        ? locationToMapsPosition(autocompleteLocation.geoLocation)
        : null,
    [autocompleteLocation]
  )

  return (
    <GoogleMap
      onLoad={mapOnLoad}
      zoom={8}
      mapContainerStyle={mapContainerStyle}
      options={googleMapOptions}
      onClick={closeLocationOverlay}
      onProjectionChanged={mapOnProjectionChanged}
    >
      {renderedLocationOverlay}
      <MarkerClusterer options={clustererOptions} styles={clustererStyles}>
        {clustererRenderFunction}
      </MarkerClusterer>
      {autocompleteLocation && (
        <Marker
          position={userPosition}
          animation={google.maps.Animation.DROP}
        />
      )}
    </GoogleMap>
  )
}

const mapContainerStyle = { height: "100%" }

const clustererStyles = [
  {
    width: 60,
    height: 60,
    url: getGoogleClusterInlineSvg(),
    textColor: "white",
    textSize: 16,
  },
]

function getGoogleClusterInlineSvg() {
  var encoded = btoa(`<svg viewBox="0 0 440 440" xmlns="http://www.w3.org/2000/svg">
    <circle cx="220" cy="220" r="100"  fill="${COLOR_BLUE}" stroke-width="0" fill-opacity="${0.5}" />
    <circle cx="220" cy="220" r="200" fill="${COLOR_BLUE}" fill-opacity="${0.25}" stroke-width="0" />
    </svg>`)

  return "data:image/svg+xml;base64," + encoded
}

function btoa(data) {
  return typeof window === "undefined"
    ? Buffer.from(data).toString("base64")
    : window.btoa(data)
}

const LocationMarker = memo(
  ({ clusterer, isSelected, location, handleMarkerClick }) => {
    const handleClick = useCallback(() => handleMarkerClick(location), [
      handleMarkerClick,
      location,
    ])
    const position = useMemo(() => locationToMapsPosition(location), [location])
    return (
      <Marker
        clusterer={clusterer}
        position={position}
        animation={google.maps.Animation.DROP}
        icon={getMarkerIcon(isSelected)}
        onClick={handleClick}
      />
    )
  }
)

function createMarkerIcon(isSelected) {
  return {
    url:
      "data:image/svg+xml;charset=utf-8," +
      encodeURIComponent(`<svg viewBox="0 0 440 440" xmlns="http://www.w3.org/2000/svg">
<circle cx="220" cy="220" r="100"  fill="${COLOR_BLUE}" stroke-width="0" />
<circle cx="220" cy="220" r="200" fill="${COLOR_BLUE}" fill-opacity="${
        isSelected ? 0.5 : 0
      }" stroke-width="0" />
</svg>`),
    size: new google.maps.Size(64, 64),
    scaledSize: new google.maps.Size(32, 32),
    anchor: new google.maps.Point(16, 16),
  }
}

function getMarkerIcon(isSelected) {
  let defaultMarkerIcon
  let selectedMarkerIcon

  if (!defaultMarkerIcon) {
    defaultMarkerIcon = createMarkerIcon(false)
    selectedMarkerIcon = createMarkerIcon(true)
  }

  return isSelected ? selectedMarkerIcon : defaultMarkerIcon
}
