export const POI_MARKER_APPEARANCE_BEFORE_DRAWING_END_MS = 500
export const POI_IMAGE_APPEARANCE_AFTER_MARKER = 200
export const MAP_SVG_VIEW_BOX = { width: 1920, height: 1080 }
export const MAP_SVG_ASPECT_RATIO =
  MAP_SVG_VIEW_BOX.width / MAP_SVG_VIEW_BOX.height
export const MARKER_SIZES_PX = { SM: 24, MD: 32, LG: 32, XL: 44 }
export const MAX_PATH_DRAWING_DURATION = 4000

export const Z_INDEXES = {
  map: 1,
  segmentPaths: 2,
  markers: 3,
  poiImages: 4,
  nextButton: 5,
}
