import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import styled, { css } from "styled-components"
import { getURLHashForEntry } from "../../../anchorLinks"
import {
  breakpoints,
  TEXT_TOKEN_COPY_BREAKPOINT_LG,
  ThemeProvider,
} from "../../../styles"
import { MAX_WIDTH } from "../../../styles/breakpoints"
import {
  SPACE_2XL,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../../../styles/primitives"
import { Image, LoadingDecoratedImage } from "../../Image"
import { RichTextParagraphText } from "../../RichText"
import { createTextStylesFromToken } from "../../Text"
import { SBackgroundImageSliceRichText } from "../backgroundImageParallaxSlice/richTextComponents"
import { useIntersection } from "../../useIntersectionObserver"
import { useAnimatedMap } from "./useAnimatedMap"
import { Transition } from "react-transition-group"
import { RouteSegment } from "./RouteSegment"
import { MarkerLocation } from "./markers"
import { HEADER_HEIGHTS } from "../../Header"

import {
  MAP_SVG_ASPECT_RATIO,
  MAP_SVG_VIEW_BOX,
  MAX_PATH_DRAWING_DURATION,
  POI_MARKER_APPEARANCE_BEFORE_DRAWING_END_MS,
  Z_INDEXES,
} from "./config"
import { ProgressCircleButton } from "../../ProgressCircleButton"
import { ArrowRightIcon } from "../../../icons/ArrowRightIcon"
import {
  ANIMATION_DURATION,
  ANIMATION_EASING,
} from "../../../styles/animations"
import { POIImages } from "./POIImages"
import { DistanceText } from "./DistanceText"

export function AnimatedMapSlice({ slice }) {
  const {
    theme,
    totalDistance,
    distanceText,
    copy,
    backgroundImage,
    mapImage,
    routeSegmentsImages,
    routeSegmentsSvgPaths: { routeSegmentsSvgPaths },
    routeSegmentsDistances: {
      routeSegmentsDistances: rawRouteSegmentsDistances,
    },
  } = slice

  const routeSegmentsDistances = useRouteSegmentsDistances(
    rawRouteSegmentsDistances
  )
  const routeSegments = useRouteSegments(routeSegmentsSvgPaths)
  const [isMapImageLoaded, setIsMapImageLoaded] = useState(false)
  const handleMapImageReveal = useCallback(() => {
    setIsMapImageLoaded(true)
  }, [])
  const mapElementRef = useRef()
  const {
    revealedFirstSegmentRef,
    currentSegmentIndex,
    revealNextRouteSegment,
    currentPOIIndex,
    revealNextPointOfInterest,
  } = useAnimatedMap(routeSegmentsImages.length)

  const handleStartRouteSegmentDrawing = useCallback(
    drawingDuration => {
      setIsDrawingSegmentPath(true)
      setTimeout(() => setIsDrawingSegmentPath(false), drawingDuration)

      setTimeout(
        revealNextPointOfInterest,
        drawingDuration - POI_MARKER_APPEARANCE_BEFORE_DRAWING_END_MS
      )
    },
    [revealNextPointOfInterest]
  )

  const { isIntersecting } = useIntersection(mapElementRef)
  useEffect(() => {
    if (
      isIntersecting &&
      !revealedFirstSegmentRef.current &&
      isMapImageLoaded
    ) {
      revealedFirstSegmentRef.current = true
      revealNextRouteSegment()
    }
  }, [
    currentSegmentIndex,
    isIntersecting,
    isMapImageLoaded,
    revealNextRouteSegment,
    revealedFirstSegmentRef,
  ])

  const [isDrawingSegmentPath, setIsDrawingSegmentPath] = useState(null)

  const handleCycleAnimationComplete = useCallback(() => {
    revealNextRouteSegment()
  }, [revealNextRouteSegment])

  const poiItems = useMemo(
    () =>
      routeSegments
        ? routeSegments.map(({ endPosition }, index) => ({
            image: routeSegmentsImages[index],
            anchorPosition: endPosition,
          }))
        : [],
    [routeSegments, routeSegmentsImages]
  )

  return (
    <ThemeProvider themeName={theme}>
      <SRoot id={getURLHashForEntry(slice)}>
        <SBackgroundImage asset={backgroundImage} />
        <SMapArea ref={mapElementRef}>
          <SProgressCircleButton
            autoFillCycleDuration={5_000}
            onNextCycle={handleCycleAnimationComplete}
            isVisible={isMapImageLoaded}
            isPaused={
              !routeSegments ||
              !isIntersecting ||
              !revealedFirstSegmentRef.current ||
              currentSegmentIndex === routeSegments.length - 1 ||
              isDrawingSegmentPath
            }
            isDisabled={isDrawingSegmentPath}
          >
            <SArrowIcon />
          </SProgressCircleButton>
          <SPOIImages
            items={poiItems}
            currentPOIIndex={currentPOIIndex}
            showImages={!isDrawingSegmentPath}
          />
          <SMapImage
            asset={mapImage}
            objectFit="contain"
            onReveal={handleMapImageReveal}
          />
          {routeSegments && (
            <SMarkerLocation
              style={{
                top: `${routeSegments[0].startPosition.y * 100}%`,
                left: `${routeSegments[0].startPosition.x * 100}%`,
              }}
              isVisible={isMapImageLoaded}
            />
          )}
          {routeSegments?.map(({ endPosition: { x, y } }, index) => (
            <SMarkerLocation
              style={{ top: `${y * 100}%`, left: `${x * 100}%` }}
              isVisible={index <= currentPOIIndex}
            />
          ))}
          {routeSegments?.map(
            ({ pathDataString, totalLength, transitionDurationMS }, index) => (
              <Transition key={index} in={currentSegmentIndex >= index}>
                {state => (
                  <SRouteSegment
                    transitionState={state}
                    pathDataString={pathDataString}
                    totalLength={totalLength}
                    transitionDurationMS={transitionDurationMS}
                    onStartDrawingAnimation={handleStartRouteSegmentDrawing}
                  />
                )}
              </Transition>
            )
          )}
        </SMapArea>
        <STextArea>
          <SDistanceText
            totalDistance={totalDistance}
            distanceText={distanceText}
            distances={routeSegmentsDistances}
            currentDistanceIndex={currentSegmentIndex}
            duration={
              routeSegments?.[currentSegmentIndex]?.transitionDurationMS
            }
          />
          <SRichText data={copy} keepTopLevelParagraph={true} />
        </STextArea>
      </SRoot>
    </ThemeProvider>
  )
}

const TEXT_AREA_VISIBLE_MIN_HEIGHTS = {
  SM: 276 - SPACE_L,
  MD: 290 - SPACE_L,
  LG: 340 - SPACE_L,
  XL: 223,
}

const TEXT_AREA_MIN_HEIGHTS_IN_VIEWPORT = { SM: 196, MD: 196, LG: 196, XL: 196 }

const SRoot = styled.div`
  position: relative;
  display: grid;
  grid-template-areas:
    "map map"
    ".   text";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  background-color: ${({ theme }) => theme.backgroundColor};

  ${breakpoints({
    SM: css`
      grid-template-areas:
        "map"
        "text";
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      padding: ${149}px ${SPACE_L}px 0;
    `,
    MD: css`
      padding: ${93}px ${SPACE_L}px 0;
    `,
    LG: css`
      padding: ${174}px ${SPACE_XL}px 0;
    `,
    XL: css`
      padding: ${245}px
        calc(max(0px, 100vw - ${MAX_WIDTH}px) / 2 + ${SPACE_XL}px) 0;
    `,
  })}
`

function calcMapMaxWidth(breakpoint) {
  return `calc(
    (100vh - ${HEADER_HEIGHTS[breakpoint]}px - ${TEXT_AREA_MIN_HEIGHTS_IN_VIEWPORT[breakpoint]}px) *
      ${MAP_SVG_ASPECT_RATIO}
  )`
}

const SMapArea = styled.div`
  grid-area: map;
  justify-self: center;

  ${breakpoints({
    SM: css`
      max-width: ${calcMapMaxWidth("SM")};
    `,
    MD: css`
      max-width: ${calcMapMaxWidth("MD")};
    `,
    LG: css`
      max-width: ${calcMapMaxWidth("LG")};
    `,
    XL: css`
      max-width: ${calcMapMaxWidth("XL")};
    `,
  })}

  position: relative;
`

const SProgressCircleButton = styled(ProgressCircleButton)`
  position: absolute;
  bottom: ${SPACE_XL}px;
  right: ${SPACE_XL}px;
  z-index: ${Z_INDEXES.nextButton};

  transition: opacity ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${breakpoints({
    SM: css`
      opacity: 0;
      pointer-events: none;
    `,
    MD: css`
      transform-origin: bottom right;
      transform: scale(calc(78 / 88));
      bottom: ${SPACE_L}px;
      right: ${SPACE_L}px;
    `,
  })}
`

const SMapImage = styled(LoadingDecoratedImage)`
  z-index: ${Z_INDEXES.map};
`

const STextArea = styled.div`
  grid-area: text;

  ${breakpoints({
    SM: css`
      min-height: ${TEXT_AREA_VISIBLE_MIN_HEIGHTS.SM + SPACE_L}px;
      padding: ${SPACE_L}px 0 ${SPACE_L}px ${SPACE_L}px;
    `,
    MD: css`
      min-height: ${TEXT_AREA_VISIBLE_MIN_HEIGHTS.MD + SPACE_L}px;
      padding: ${SPACE_L}px 0 ${SPACE_L}px ${SPACE_M}px;
    `,
    LG: css`
      min-height: ${TEXT_AREA_VISIBLE_MIN_HEIGHTS.LG + SPACE_L}px;
      padding: ${SPACE_L}px 0 ${SPACE_L}px ${SPACE_L}px;
    `,
    XL: css`
      min-height: ${TEXT_AREA_VISIBLE_MIN_HEIGHTS.XL + 130}px;
      padding: ${SPACE_2XL}px 0 ${115}px ${130}px;
    `,
  })}
`

const SDistanceText = styled(DistanceText)`
  margin-bottom: ${SPACE_M}px;

  ${breakpoints({
    LG: css`
      margin-bottom: ${SPACE_L}px;
    `,
    XL: css`
      margin-bottom: ${SPACE_L}px;
    `,
  })}
`

const SBackgroundImage = styled(Image)`
  &.gatsby-image-wrapper {
    position: absolute;
  }
  top: 0;
  left: 0;
  right: 50%;
  bottom: 0;

  ${breakpoints({
    SM: css`
      top: 0;
      left: 0;
      right: 0;
      bottom: auto;
      height: ${149 + SPACE_2XL}px;
    `,
  })}
`

const SRichText = styled(SBackgroundImageSliceRichText)`
  ${RichTextParagraphText} {
    ${createTextStylesFromToken({
      MD: TEXT_TOKEN_COPY_BREAKPOINT_LG,
    })}
  }
`

function useRouteSegments(routeSegmentsSvgPaths) {
  const [segments, setSegments] = useState(null)

  useEffect(() => {
    setSegments(
      routeSegmentsSvgPaths.split("\n").map(pathDataString => {
        const tempPathElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        )
        tempPathElement.setAttribute("d", pathDataString)
        const totalLength = tempPathElement.getTotalLength()
        const startPositionSVGPoint = tempPathElement.getPointAtLength(0)
        const endPositionSVGPoint = tempPathElement.getPointAtLength(
          totalLength
        )
        return {
          pathDataString,
          totalLength,
          startPosition: {
            x: startPositionSVGPoint.x / MAP_SVG_VIEW_BOX.width,
            y: startPositionSVGPoint.y / MAP_SVG_VIEW_BOX.height,
          },
          endPosition: {
            x: endPositionSVGPoint.x / MAP_SVG_VIEW_BOX.width,
            y: endPositionSVGPoint.y / MAP_SVG_VIEW_BOX.height,
          },
          transitionDurationMS: Math.min(
            (1000 * totalLength) / 200,
            MAX_PATH_DRAWING_DURATION
          ),
        }
      })
    )
  }, [routeSegmentsSvgPaths])
  return segments
}

function useRouteSegmentsDistances(routeSegmentsDistances) {
  return useMemo(
    () =>
      routeSegmentsDistances
        .split("\n")
        .map(distanceString => parseFloat(distanceString)),
    [routeSegmentsDistances]
  )
}

const SRouteSegment = styled(RouteSegment)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: ${Z_INDEXES.segmentPaths};
`

const SMarkerLocation = styled(MarkerLocation)`
  z-index: ${Z_INDEXES.markers};
`

const SArrowIcon = styled(ArrowRightIcon)``

const SPOIImages = styled(POIImages)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${Z_INDEXES.poiImages};
`
