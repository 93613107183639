import React from "react"

import styled, { css } from "styled-components"
import { getSliceRootElementProps } from "./utils"
import { breakpoints, ThemeProvider } from "../../styles"
import { MAX_WIDTH } from "../../styles/breakpoints"
import {
  COLOR_GRAY_900,
  COLOR_STONE,
  FONT_SIZE_10XL,
  FONT_SIZE_12XL,
  FONT_SIZE_4XL,
  FONT_SIZE_5XL,
  FONT_SIZE_L,
  SPACE_1XL,
  SPACE_2XL,
  SPACE_3XL,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../../styles/primitives"
import { Image } from "../Image"
import { RichText } from "../RichText"

import { Text } from "../Text"

function createGridColumnns(outerPadding, copyIndentation) {
  return css`
    grid-template-columns: [left] ${outerPadding} [content-start] auto [content-end] ${outerPadding} [right];
  `
}

function createGridRows(imageToContent, contentToEnd, imageToBottom) {
  return css`
    grid-template-rows: [top] 0 [image-start] ${imageToContent} [content-start] ${contentToEnd} [content-end] ${imageToBottom} [image-end] 0 [bottom];
  `
}

const Container = styled.div`
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: grid;
  z-index: 0;

  ${breakpoints({
    SM: css`
      ${createGridRows("1fr", "1fr", `${SPACE_1XL}px`)};
      ${createGridColumnns(`${SPACE_L}px`, 0)}
    `,
    MD: css`
      ${createGridRows("2fr", "5fr", `${216}px`)};
      ${createGridColumnns(`${SPACE_L}px`, 0)}
    `,
    LG: css`
      ${createGridRows("1fr", "2fr", `${197}px`)};
      ${createGridColumnns(`${SPACE_XL}px`, 80)}
    `,
    XL: css`
      ${createGridRows("1fr", "1fr", `${212}px`)};
      ${createGridColumnns(`${SPACE_3XL + SPACE_M}px`, 207)}
    `,
    MAX: css`
      ${createGridRows("1fr", "1fr", `${212}px`)};
      ${createGridColumnns(
        `calc(max(100% - ${MAX_WIDTH}px, 0px) / 2 + ${SPACE_XL}px)`,
        207
      )}
    `,
  })}
`

const ContentOverlay = styled.div`
  grid-row-start: content-start;
  grid-row-end: content-end;
  grid-column-start: content-start;
  grid-column-end: content-end;
`

const HeadlineText = styled(Text)`
  display: flex;
  align-items: flex-end;

  ${breakpoints({
    SM: css`
      padding-bottom: ${SPACE_XL}px;
      font-size: ${FONT_SIZE_5XL}rem;
    `,
    MD: css`
      padding-bottom: ${SPACE_XL}px;
      font-size: ${FONT_SIZE_10XL}rem;
    `,
    LG: css`
      padding-bottom: ${SPACE_1XL}px;
      font-size: ${FONT_SIZE_12XL}rem;
    `,
    XL: css`
      padding-bottom: ${SPACE_1XL}px;
      font-size: ${FONT_SIZE_12XL}rem;
    `,
  })}
`

const CopyText = styled(Text)`
  ${breakpoints({
    SM: css`
      font-size: ${FONT_SIZE_L}rem;
    `,
    MD: css`
      font-size: ${FONT_SIZE_4XL}rem;
    `,
    LG: css`
      font-size: ${FONT_SIZE_5XL}rem;
      padding-left: 82px;
    `,
    XL: css`
      font-size: ${FONT_SIZE_5XL}rem;
      padding-left: 207px;
    `,
  })}
`

const BackgroundImage = styled(Image)`
  width: 100%;
  z-index: -1;
  grid-row-start: image-start;
  grid-row-end: image-end;
  grid-column-start: left;
  grid-column-end: right;
`

const LogoContainer = styled.div`
  display: flex;

  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_XL}px;
    `,
    MD: css`
      margin-top: ${SPACE_2XL}px;
    `,
    LG: css`
      margin-top: ${SPACE_1XL}px;
    `,
    XL: css`
      margin-top: ${SPACE_1XL}px;
    `,
  })}
`

const LogoImage = styled(Image)`
  img {
    height: 120px;

    ${breakpoints({
      SM: css`
        height: 70px;
        width: auto;
      `,
    })};
  }
`

export function ImageBackgroundCopySlice({ slice }) {
  const { text, logos, backgroundImage, headline, foregroundColors } = slice

  return (
    <ThemeProvider
      themeName={{ light: "dark", dark: "stone" }[foregroundColors]}
    >
      <Container
        {...getSliceRootElementProps(slice)}
        backgroundColor={
          { light: COLOR_GRAY_900, dark: COLOR_STONE }[foregroundColors]
        }
      >
        <BackgroundImage asset={backgroundImage} />
        <ContentOverlay>
          <HeadlineText type="hero">{headline.headline}</HeadlineText>
          <CopyText type="copy">
            <RichText data={text} />
            {logos && (
              <LogoContainer>
                {logos.map((logoAsset, index) => (
                  <LogoImage key={index} asset={logoAsset} />
                ))}
              </LogoContainer>
            )}
          </CopyText>
        </ContentOverlay>
      </Container>
    </ThemeProvider>
  )
}
