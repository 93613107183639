import React from "react"

import styled, { css } from "styled-components"
import {
  breakpoints,
  TEXT_TOKEN_COPY_BREAKPOINT_SM,
  ThemeProvider,
} from "../../../styles"
import { MAX_WIDTH } from "../../../styles/breakpoints"
import {
  SPACE_1XL,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../../../styles/primitives"
import { RichText } from "../../RichText"
import { Rating } from "../../Rating"

import { CopyText, textConfigToCSS } from "../../Text"
import { DeliveryIcon, OrderIcon } from "../../../icons/icons"
import { graphql, useStaticQuery } from "gatsby"

const TrustpilotScoreContainer = styled.div``

const BenefitsContainer = styled.div`
  display: flex;
  align-items: center;
`

const Container = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  max-width: ${MAX_WIDTH}px;
  width: 100%;
  margin: auto;

  display: flex;
  flex-wrap: wrap;

  padding: ${SPACE_1XL}px ${SPACE_XL}px;

  ${breakpoints({
    SM: css`
      padding: ${SPACE_XL}px ${SPACE_L}px ${SPACE_1XL}px;
    `,
  })}

  ${({ showTruspilotScore }) =>
    !showTruspilotScore
      ? css`
          > ${BenefitsContainer} {
            flex: 0 0 100%;
          }
        `
      : breakpoints({
          SM: css`
            justify-content: center;
            > ${TrustpilotScoreContainer} {
              flex: 0 0 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding-bottom: ${SPACE_XL}px;
              text-align: center;
            }
            > ${BenefitsContainer} {
              flex: 0 0 100%;
            }
          `,
          MD: css`
            justify-content: center;
            > ${TrustpilotScoreContainer} {
              flex: 0 0 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding-bottom: ${SPACE_XL}px;
              text-align: center;
            }
            > ${BenefitsContainer} {
              flex: 0 0 100%;
            }
          `,
          LG: css`
            > ${TrustpilotScoreContainer} {
              flex: 0 0 ${100 / 3}%;
            }
            > ${BenefitsContainer} {
              flex: 0 0 ${200 / 3}%;
            }
          `,
          XL: css`
            > ${TrustpilotScoreContainer} {
              flex: 0 0 50%;
            }
            > ${BenefitsContainer} {
              flex: 0 0 50%;
            }
          `,
        })}
`

const TruspilotScoreHeadlineText = styled(CopyText)`
  display: block;
  margin-bottom: ${SPACE_M}px;

  ${breakpoints({
    LG: textConfigToCSS(TEXT_TOKEN_COPY_BREAKPOINT_SM),
    XL: textConfigToCSS(TEXT_TOKEN_COPY_BREAKPOINT_SM),
  })}
`

const BenefitItem = styled.div`
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: ${SPACE_M}px;
    flex-shrink: 0;
  }

  margin-right: ${SPACE_XL / 2}px;
  & ~ & {
    margin-left: ${SPACE_XL / 2}px;
  }

  ${breakpoints({
    SM: css`
      margin-right: ${SPACE_M / 2}px;
      & ~ & {
        margin-left: ${SPACE_M / 2}px;
      }
    `,
  })}
`

export function BeyondTheFoldAppend({
  orderBenefitText,
  deliveryBenefitText,
  // showTruspilotScore,
  trustedShopsHeadline,
}) {
  // Temporarily disabled.
  //
  // const { unuApiRoot } = useStaticQuery(
  //   graphql`
  //     query {
  //       unuApiRoot {
  //         rating
  //       }
  //     }
  //   `
  // )

  return (
    <ThemeProvider themeName="stone">
      <Container showTruspilotScore={false}>
        {/* {showTruspilotScore && (
          <TrustpilotScoreContainer>
            <TruspilotScoreHeadlineText>
              {trustedShopsHeadline}
            </TruspilotScoreHeadlineText>
            <Rating score={unuApiRoot.rating} />
          </TrustpilotScoreContainer>
        )} */}

        <BenefitsContainer>
          <BenefitItem>
            <SOrderIcon />
            <SBenefitItemText>
              <RichText data={orderBenefitText} />
            </SBenefitItemText>
          </BenefitItem>
          <BenefitItem>
            <SDeliveryIcon />
            <SBenefitItemText>
              <RichText data={deliveryBenefitText} />
            </SBenefitItemText>
          </BenefitItem>
        </BenefitsContainer>
      </Container>
    </ThemeProvider>
  )
}

const benefitIconStyles = css`
  ${breakpoints({
    SM: css`
      width: 32px;
      height: 32px;
    `,
  })}
`

const SOrderIcon = styled(OrderIcon)`
  ${benefitIconStyles};
`
const SDeliveryIcon = styled(DeliveryIcon)`
  ${benefitIconStyles};
`

const SBenefitItemText = styled(CopyText)`
  ${breakpoints({
    LG: textConfigToCSS(TEXT_TOKEN_COPY_BREAKPOINT_SM),
    XL: textConfigToCSS(TEXT_TOKEN_COPY_BREAKPOINT_SM),
  })}
`
