import React from "react"

import styled, { css } from "styled-components"
import { COLOR_TEXT_INVERTED, useTheme } from "../styles"
import { COLOR_BLUE } from "../styles/primitives"
import { Text } from "./Text"

const ToplineText = styled(Text)`
  white-space: nowrap;
  align-items: flex-end;

  ${({ fromRight }) =>
    fromRight
      ? css`
          transform: rotate(8deg);
          transform-origin: right;
          display: flex;
          justify-content: flex-end;
          text-align: right;
        `
      : css`
          transform: rotate(-8deg);
          transform-origin: left;
          display: flex;
          justify-content: flex-start;
          text-align: left;
        `}
`

export function Topline({ topline, fromRight = true, ...otherProps }) {
  const { theme } = useTheme()

  return (
    <ToplineText
      style={{
        color: {
          coral: COLOR_TEXT_INVERTED,
          stone: COLOR_BLUE,
          green: COLOR_BLUE,
          dark: COLOR_BLUE,
        }[theme.themeName],
      }}
      type="secondary"
      fromRight={fromRight}
      {...otherProps}
    >
      {topline.topline}
    </ToplineText>
  )
}
