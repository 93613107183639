import { useCallback, useMemo, useRef, useState } from "react"

export function useAnimatedMap(numberOfSegments) {
  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(-1)
  const [currentPOIIndex, setCurrentPOIIndex] = useState(-1)
  const revealedFirstSegmentRef = useRef(false)
  const currentIndexesRef = useRef()
  currentIndexesRef.current = {
    poi: currentPOIIndex,
    segment: currentSegmentIndex,
  }

  const revealNextPointOfInterest = useCallback(() => {
    const current = currentIndexesRef.current.poi

    setCurrentPOIIndex(current + 1 === numberOfSegments ? -1 : current + 1)
  }, [numberOfSegments])

  const revealNextRouteSegment = useCallback(() => {
    revealedFirstSegmentRef.current = true
    const current = currentIndexesRef.current.segment
    if (current + 1 === numberOfSegments) {
      setCurrentSegmentIndex(-1)
      setCurrentPOIIndex(-1)
    } else {
      setCurrentSegmentIndex(current + 1)
    }
  }, [numberOfSegments])

  return useMemo(
    () => ({
      revealedFirstSegmentRef,
      currentSegmentIndex,
      revealNextRouteSegment,
      currentPOIIndex,
      revealNextPointOfInterest,
    }),
    [
      currentSegmentIndex,
      revealNextRouteSegment,
      currentPOIIndex,
      revealNextPointOfInterest,
    ]
  )
}
