import React, { useCallback, useRef } from "react"

import styled, { css } from "styled-components"

import { breakpoints, ThemeProvider, useScreenSizeName } from "../../../styles"
import { MAX_WIDTH } from "../../../styles/breakpoints"
import {
  SPACE_1XL,
  SPACE_2XL,
  SPACE_4XL,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../../../styles/primitives"
import { Image } from "../../Image"
import { RichText } from "../../RichText"
import { SubHeadlineParagraphText, Text } from "../../Text"
import { HEADER_HEIGHTS } from "../../Header"

import { OffsetImagePair } from "./OffsetImagePair"
import { CopyPair } from "./CopyPair"
import { getSliceRootElementProps } from "../utils"
import { useScrollEventListenerWhileIntersected } from "../../useIntersectionObserver"

const BACKGROUND_COLOR = "#111110"

const IMAGE_SCROLL_PARALLAX_RATIO = 0.6
const INITIAL_IMAGE_PEEK_HEIGHT_VH = 20
const ULTIMATE_HIDDEN_TOP_HEIGHT_VH = 10
const fadeEffectThresholds = {
  SM: {
    start: 0.3,
    end: 0.5,
  },
  MD: {
    start: 0.7,
    end: 0.8,
  },
  LG: {
    start: 0.7,
    end: 0.8,
  },
  XL: {
    start: 0.8,
    end: 0.9,
  },
}

export function BackgroundImageParallaxSlice({ slice }) {
  const {
    headline,
    description,
    backgroundImage,
    fadeInBackgroundImage,

    leftImage,
    leftImageCaption,
    rightImage,
    rightImageCaption,

    bottomLeftText,
    bottomRightText,
  } = slice

  const rootElementRef = useRef()
  const blockBeforeImageRef = useRef()
  const backgroundAnchorElementRef = useRef()
  const screenSizeName = useScreenSizeName()

  const handleScroll = useCallback(
    entry => {
      const blockRect = blockBeforeImageRef.current.getBoundingClientRect()
      const distanceBlockToBackgroundImage =
        ((100 - INITIAL_IMAGE_PEEK_HEIGHT_VH) / 100) * window.innerHeight -
        blockRect.bottom
      backgroundAnchorElementRef.current.style.transform =
        window.innerWidth > 639
          ? `translateY(max(-${100 - ULTIMATE_HIDDEN_TOP_HEIGHT_VH}vh + ${
              HEADER_HEIGHTS[screenSizeName]
            }px, -${
              Math.max(0, distanceBlockToBackgroundImage) *
              IMAGE_SCROLL_PARALLAX_RATIO
            }px))`
          : `translateY(-${100 - ULTIMATE_HIDDEN_TOP_HEIGHT_VH}vh + ${
              HEADER_HEIGHTS[screenSizeName]
            }px)`

      const imageRect = fadeInElementRef.current.getBoundingClientRect()
      const inViewRatio = 1 - imageRect.top / window.innerHeight
      const inTriggerViewRatio =
        (inViewRatio - fadeEffectThresholds[screenSizeName].start) /
        (fadeEffectThresholds[screenSizeName].end -
          fadeEffectThresholds[screenSizeName].start)
      fadeInElementRef.current.style.opacity = Math.max(
        0,
        Math.min(1, inTriggerViewRatio)
      ).toString()
    },
    [screenSizeName]
  )

  useScrollEventListenerWhileIntersected(rootElementRef, handleScroll)

  const fadeInElementRef = useRef()

  return (
    <ThemeProvider themeName="dark">
      <Container {...getSliceRootElementProps(slice)} ref={rootElementRef}>
        <SBackgroundStickyAnchor ref={backgroundAnchorElementRef}>
          <SBackgroundImageContainer>
            <SBackgroundImageInitial asset={backgroundImage} />
            <SBackgroundImageFadeInWrapper ref={fadeInElementRef}>
              <Image asset={fadeInBackgroundImage} />
            </SBackgroundImageFadeInWrapper>
            <SBottomShadow />
          </SBackgroundImageContainer>
        </SBackgroundStickyAnchor>

        <SBlockBeforeImage ref={blockBeforeImageRef}>
          <HeadlineText type="hero">{headline.headline}</HeadlineText>
          <SDescriptionRichText
            data={description}
            components={descriptionRichTextComponents}
            keepTopLevelParagraph={true}
          />
        </SBlockBeforeImage>

        <SBlockAfterImage>
          <OffsetImagePair
            leftImage={leftImage}
            leftImageCaption={leftImageCaption}
            rightImage={rightImage}
            rightImageCaption={rightImageCaption}
          />
        </SBlockAfterImage>

        <StyledCopyPair leftText={bottomLeftText} rightText={bottomRightText} />
      </Container>
    </ThemeProvider>
  )
}

const descriptionRichTextComponents = {
  ParagraphText: SubHeadlineParagraphText,
}

const SBackgroundStickyAnchor = styled.div`
  position: sticky;
  z-index: 0;
  transition: transform ease-out 100ms;

  ${breakpoints({
    SM: css`
      top: ${5 + INITIAL_IMAGE_PEEK_HEIGHT_VH}vh;
      height: calc(
        ${100 - INITIAL_IMAGE_PEEK_HEIGHT_VH}vh + ${HEADER_HEIGHTS.SM}px
      );
      margin-bottom: calc(
        ${INITIAL_IMAGE_PEEK_HEIGHT_VH}vh - ${HEADER_HEIGHTS.SM}px
      );
    `,
    MD: css`
      top: -${HEADER_HEIGHTS.MD}px;
      height: calc(
        ${100 - INITIAL_IMAGE_PEEK_HEIGHT_VH}vh + ${HEADER_HEIGHTS.MD}px
      );
      margin-bottom: calc(
        ${INITIAL_IMAGE_PEEK_HEIGHT_VH}vh - ${HEADER_HEIGHTS.MD}px
      );
    `,
    LG: css`
      top: -${HEADER_HEIGHTS.LG}px;
      height: calc(
        ${100 - INITIAL_IMAGE_PEEK_HEIGHT_VH}vh + ${HEADER_HEIGHTS.LG}px
      );
      margin-bottom: calc(
        ${INITIAL_IMAGE_PEEK_HEIGHT_VH}vh - ${HEADER_HEIGHTS.LG}px
      );
    `,
    XL: css`
      top: -${HEADER_HEIGHTS.XL}px;
      height: calc(
        ${100 - INITIAL_IMAGE_PEEK_HEIGHT_VH}vh + ${HEADER_HEIGHTS.XL}px
      );
      margin-bottom: calc(
        ${INITIAL_IMAGE_PEEK_HEIGHT_VH}vh - ${HEADER_HEIGHTS.XL}px
      );
    `,
  })}
`

const SBackgroundImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  ${breakpoints({
    SM: css`
      padding-top: ${HEADER_HEIGHTS.SM}px;
    `,
    MD: css`
      padding-top: ${HEADER_HEIGHTS.MD}px;
    `,
    LG: css`
      padding-top: ${HEADER_HEIGHTS.LG}px;
    `,
    XL: css`
      padding-top: ${HEADER_HEIGHTS.XL}px;
    `,
  })}
`

const Container = styled.section`
  background-color: ${BACKGROUND_COLOR};
  position: relative;

  ${breakpoints({
    SM: css`
      padding-bottom: ${80}px;
      > *:not(${SBackgroundImageContainer}) {
        margin-left: ${SPACE_L}px;
        margin-right: ${SPACE_L}px;
      }
    `,
    MD: css`
      padding-bottom: ${SPACE_4XL}px;
      > *:not(${SBackgroundImageContainer}) {
        margin-left: ${SPACE_XL}px;
        margin-right: ${SPACE_XL}px;
      }
    `,
    LG: css`
      padding-bottom: ${SPACE_4XL + SPACE_2XL}px;
      > *:not(${SBackgroundImageContainer}) {
        margin-left: ${SPACE_XL}px;
        margin-right: ${SPACE_XL}px;
      }
    `,
    XL: css`
      padding-bottom: ${SPACE_4XL + SPACE_2XL}px;
      > *:not(${SBackgroundImageContainer}) {
        margin-left: calc(
          max(0px, (100% - ${MAX_WIDTH}px) / 2) + ${SPACE_XL}px
        );
        margin-right: calc(
          max(0px, (100% - ${MAX_WIDTH}px) / 2) + ${SPACE_XL}px
        );
      }
    `,
  })}
`

const SBackgroundImageInitial = styled(Image)`
  z-index: 1;
`

const SBackgroundImageFadeInWrapper = styled.div`
  position: absolute;
  transition: opacity ease-in-out 100ms;
  z-index: 2;
`
const SBottomShadow = styled.div`
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  right: 0;
  z-index: 3;

  box-shadow: inset 0 -${SPACE_4XL}px ${SPACE_2XL}px -${SPACE_2XL}px
    ${BACKGROUND_COLOR};
`

const HeadlineText = styled(Text)`
  ${breakpoints({
    SM: css`
      margin-bottom: ${SPACE_M}px;
    `,
    MD: css`
      margin-bottom: ${SPACE_2XL}px;
    `,
    LG: css`
      margin-bottom: ${SPACE_2XL}px;
    `,
    XL: css`
      margin-bottom: ${SPACE_1XL}px;
    `,
  })}
`

const SDescriptionRichText = styled(RichText)`
  ${breakpoints({
    LG: css`
      padding-left: 84px;
    `,
    XL: css`
      padding-left: 206px;
    `,
  })}
`

const SBlock = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
`

const SBlockBeforeImage = styled(SBlock)`
  ${breakpoints({
    SM: css`
      margin-top: ${HEADER_HEIGHTS.SM}px;
      margin-bottom: ${25}vh;
    `,
    MD: css`
      margin-top: calc(
        ${INITIAL_IMAGE_PEEK_HEIGHT_VH}vh + ${HEADER_HEIGHTS.MD}px
      );
      margin-bottom: ${100 + 100 * IMAGE_SCROLL_PARALLAX_RATIO}vh;
    `,
    LG: css`
      margin-top: calc(
        ${INITIAL_IMAGE_PEEK_HEIGHT_VH}vh + ${HEADER_HEIGHTS.LG}px
      );
      margin-bottom: ${100 + 100 * IMAGE_SCROLL_PARALLAX_RATIO}vh;
    `,
    XL: css`
      margin-top: calc(
        ${INITIAL_IMAGE_PEEK_HEIGHT_VH}vh + ${HEADER_HEIGHTS.XL}px
      );
      margin-bottom: ${100 + 100 * IMAGE_SCROLL_PARALLAX_RATIO}vh;
    `,
  })}
`

const SBlockAfterImage = styled(SBlock)`
  ${breakpoints({
    SM: css`
      margin-bottom: calc(
        ${100 - INITIAL_IMAGE_PEEK_HEIGHT_VH - ULTIMATE_HIDDEN_TOP_HEIGHT_VH}vh -
          ${2 * HEADER_HEIGHTS.SM}px
      );
    `,
    MD: css`
      margin-bottom: calc(
        ${100 - INITIAL_IMAGE_PEEK_HEIGHT_VH - ULTIMATE_HIDDEN_TOP_HEIGHT_VH}vh -
          ${2 * HEADER_HEIGHTS.MD}px
      );
    `,
    LG: css`
      margin-bottom: calc(
        ${100 - INITIAL_IMAGE_PEEK_HEIGHT_VH - ULTIMATE_HIDDEN_TOP_HEIGHT_VH}vh -
          ${2 * HEADER_HEIGHTS.LG}px
      );
    `,
    XL: css`
      margin-bottom: calc(
        ${100 - INITIAL_IMAGE_PEEK_HEIGHT_VH - ULTIMATE_HIDDEN_TOP_HEIGHT_VH}vh -
          ${2 * HEADER_HEIGHTS.XL}px
      );
    `,
  })}
`

const StyledCopyPair = styled(CopyPair)`
  position: relative;
  z-index: 1;
`
