import React from "react"

import styled, { css } from "styled-components"
import { breakpoints } from "../../../styles"
import {
  SPACE_1XL,
  SPACE_2XL,
  SPACE_4XL,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../../../styles/primitives"
import { Image } from "../../Image"
import { SBackgroundImageSliceRichText } from "./richTextComponents"

const Container = styled.section`
  display: grid;
  grid-column-gap: ${SPACE_L}px;
  grid-template-areas:
    "image-1          image-1-caption"
    "image-1          image-2        "
    "image-2-caption  image-2        ";
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;

  ${breakpoints({
    SM: css`
      grid-template-areas:
        "image-1"
        "image-1-caption"
        "image-2"
        "image-2-caption";
      grid-template-rows: repeat(4, auto);
      grid-template-columns: 1fr;
    `,
    MD: css`
      grid-column-gap: ${SPACE_M}px;
    `,
  })}
`
const LeftImage = styled(Image)`
  grid-area: image-1;
`

const RightImage = styled(Image)`
  grid-area: image-2;
`

const SLeftImageRichText = styled(SBackgroundImageSliceRichText)`
  grid-area: image-1-caption;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${breakpoints({
    SM: css`
      padding-top: ${SPACE_L}px;
      padding-bottom: ${SPACE_2XL}px;
    `,
    MD: css`
      padding-bottom: ${SPACE_XL}px;
    `,
    LG: css`
      padding-bottom: ${SPACE_1XL}px;
      padding-left: ${80}px;
    `,
    XL: css`
      padding-bottom: ${SPACE_1XL}px;
      padding-left: ${SPACE_4XL - SPACE_L}px;
    `,
  })}
`

const SRightImageRichText = styled(SBackgroundImageSliceRichText)`
  grid-area: image-2-caption;

  ${breakpoints({
    SM: css`
      padding-top: ${SPACE_L}px;
    `,
    MD: css`
      padding-top: ${SPACE_XL}px;
    `,
    LG: css`
      padding-top: ${SPACE_1XL}px;
      padding-right: ${80}px;
    `,
    XL: css`
      padding-top: ${SPACE_1XL}px;
      padding-right: ${SPACE_4XL - SPACE_L}px;
    `,
  })}
`

export function OffsetImagePair({
  leftImage,
  leftImageCaption,
  rightImage,
  rightImageCaption,
  ...otherProps
}) {
  return (
    <Container {...otherProps}>
      <LeftImage asset={leftImage} />
      <SLeftImageRichText data={leftImageCaption} />

      <RightImage asset={rightImage} />
      <SRightImageRichText data={rightImageCaption} />
    </Container>
  )
}
