import React, { useMemo } from "react"
import styled, { css } from "styled-components"
import {
  breakpoints,
  COLOR_UI_BACKGROUND_INVERTED,
  getThemeByName,
  TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_MD,
  TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_SM,
  ThemeProvider,
  usePageThemeColor,
  useTheme,
} from "../../styles"
import {
  COLOR_STONE,
  SPACE_1XL,
  SPACE_2XL,
  SPACE_3XL,
  SPACE_4XL,
  SPACE_L,
  SPACE_M,
  SPACE_S,
  SPACE_XL,
} from "../../styles/primitives"
import { LoadingDecoratedImage } from "../Image"
import { RichText } from "../RichText"
import { createTextStylesFromToken, Text } from "../Text"
import { Topline } from "../Topline"
import { Button } from "../button"
import { getSliceRootElementProps } from "./utils"
import { MAX_WIDTH } from "../../styles/breakpoints"
import { ANIMATION_DURATION, ANIMATION_EASING } from "../../styles/animations"
import { LoadingDecoratedMedia } from "../Media"

const TOPLINE_HEIGHT = SPACE_1XL - SPACE_S
const TOPLINE_HEIGHT_XL = SPACE_1XL

const Container = styled.div`
  position: relative;
  z-index: 0;
  display: grid;
  transition: background-color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;
  background-color: ${({ theme }) =>
    theme.pageThemeColor
      ? theme.pageThemeColor.color
      : COLOR_UI_BACKGROUND_INVERTED};

  ${breakpoints({
    SM: css`
      grid-template-rows: [top] ${SPACE_2XL}px [slot-1-start] ${SPACE_2XL}px [background-start] auto [slot-1-end] ${SPACE_2XL}px [slot-2-start] auto [slot-2-end] ${SPACE_S}px [slot-3-start] auto [slot-3-end] ${SPACE_2XL}px [background-end] 0 [bottom];
      grid-template-columns: [left] 0 [background-start] ${SPACE_L}px [slot-1-start] 0 [slot-2-start] 0 [slot-3-start] auto [slot-1-end] 0 [slot-2-end] 0 [slot-3-end] ${SPACE_L}px [background-end] 0 [right];
    `,
    MD: css`
      grid-template-rows: [top] ${SPACE_4XL}px [slot-1-start] ${SPACE_4XL}px [background-start] auto [slot-1-end] ${SPACE_4XL -
        TOPLINE_HEIGHT}px [slot-2-start] auto [slot-2-end] 0 [slot-3-start] auto [slot-3-end] ${SPACE_4XL}px 0 [background-end] auto [bottom];
      grid-template-columns: [left] 0 [background-start] ${147}px [slot-1-start] 0 [slot-2-start] 0 [slot-3-start] auto [slot-1-end] 0 [slot-2-end] ${147 -
        SPACE_L}px [slot-3-end] ${SPACE_L}px [background-end] 0 [right];
    `,
    LG: css`
      grid-template-rows: [top] 0 [background-start] ${SPACE_3XL}px [slot-2-start] ${SPACE_4XL -
        SPACE_3XL}px [slot-1-start] ${TOPLINE_HEIGHT}px [slot-2-end] ${SPACE_2XL}px [slot-3-start] auto [slot-3-end] ${TOPLINE_HEIGHT}px [slot-1-end] ${SPACE_4XL}px [background-end] 0[bottom];
      ${({ sideVariant }) =>
        ({
          imageLeft: css`
            grid-template-columns:
              [left] ${SPACE_XL}px [slot-1-start] 0.8502fr
              [background-start] ${60}px [slot-1-end] ${107}px [slot-2-start] 0 [slot-3-start] 1fr [slot-2-end] ${84}px [slot-3-end] ${SPACE_XL}px [background-end] 0 [right];
          `,
          imageRight: css`
            grid-template-columns:
              [left] 0 [background-start] ${SPACE_XL}px
              [slot-1-start] ${84}px [slot-2-start] 1fr [slot-1-end] 0 [slot-2-end] ${107}px [slot-3-start] ${60}px [background-end] 0.8502fr [slot-3-end] ${SPACE_XL}px [right];
          `,
        }[sideVariant])}
    `,
    // INVERT LIKE LG
    XL: css`
      grid-template-rows: [top] 0 [background-start] ${SPACE_3XL}px [slot-2-start] ${SPACE_4XL -
        SPACE_3XL}px [slot-1-start] ${TOPLINE_HEIGHT_XL}px [slot-2-end] ${SPACE_3XL}px [slot-3-start] auto [slot-3-end] ${TOPLINE_HEIGHT_XL}px [slot-1-end] ${SPACE_4XL}px [background-end] 0 [bottom];
      ${({ sideVariant }) =>
        ({
          imageLeft: css`
            grid-template-columns:
              [left] calc(max(100% - ${MAX_WIDTH}px, 0px) / 2 + ${SPACE_XL}px)
              [slot-1-start] 0.836fr
              [background-start] ${78}px
              [slot-1-end] ${SPACE_4XL + 2}px
              [slot-2-start] 0 [slot-3-start] 1fr [slot-2-end] ${103}px
              [slot-3-end] calc(
                max(100% - ${MAX_WIDTH}px, 0px) / 2 + ${SPACE_XL}px
              )
              [background-end] 0 [right];
          `,
          imageRight: css`
            grid-template-columns:
              [left] 0 [background-start]
              calc(max(100% - ${MAX_WIDTH}px, 0px) / 2 + ${SPACE_XL}px)
              [slot-1-start] ${103}px [slot-2-start] 1fr [slot-1-end] 0
              [slot-2-end] ${SPACE_4XL + 2}px [slot-3-start] ${78}px
              [background-end] 0.836fr [slot-3-end]
              calc(max(100% - ${MAX_WIDTH}px, 0px) / 2 + ${SPACE_XL}px) [right];
          `,
        }[sideVariant])}
    `,
  })};
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  grid-row-start: slot-3-start;
  grid-row-end: slot-3-end;
  ${({ sideVariant }) =>
    ({
      imageLeft: css`
        grid-column-start: slot-2-start;
        grid-column-end: slot-2-end;
      `,
      imageRight: css`
        grid-column-start: slot-1-start;
        grid-column-end: slot-1-end;
      `,
    }[sideVariant])}

  ${breakpoints({
    LG: css`
      margin-top: -${TOPLINE_HEIGHT}px;
    `,
    XL: css`
      margin-top: -${TOPLINE_HEIGHT_XL}px;
    `,
  })}
`

const Header = styled.header`
  padding-bottom: ${SPACE_M}px;
`

const PrimaryMedia = styled(LoadingDecoratedMedia)`
  grid-row-start: slot-1-start;
  grid-row-end: slot-1-end;
  ${({ sideVariant }) =>
    ({
      imageLeft: css`
        grid-column-start: slot-1-start;
        grid-column-end: slot-1-end;
      `,
      imageRight: css`
        grid-column-start: slot-3-start;
        grid-column-end: slot-3-end;
      `,
    }[sideVariant])}
`

const SBackground = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
  z-index: -1;

  grid-row-start: background-start;
  grid-row-end: background-end;
  grid-column-start: background-start;
  grid-column-end: background-end;
`

const StyledTopline = styled(Topline)`
  grid-row-start: slot-2-start;
  grid-row-end: slot-2-end;
  ${({ sideVariant }) =>
    ({
      imageLeft: css`
        grid-column-start: slot-1-start;
        grid-column-end: slot-1-end;
      `,
      imageRight: css`
        grid-column-start: slot-3-start;
        grid-column-end: slot-3-end;
      `,
    }[sideVariant])}

  ${breakpoints({
    SM: css`
      display: none;
    `,
  })}

  ${createTextStylesFromToken({
    SM: TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_SM,
    MD: TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_SM,
    LG: TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_MD,
    XL: TEXT_TOKEN_HEADLINE_PRIMARY_BREAKPOINT_MD,
  })}
`

const StyledButton = styled(Button)`
  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_1XL}px;
      width: 100%;
    `,
    MD: css`
      margin-top: ${SPACE_1XL}px;
    `,
    LG: css`
      margin-top: ${SPACE_2XL}px;
    `,
    XL: css`
      margin-top: ${SPACE_2XL}px;
    `,
  })}
`

const innerThemeNameByPageThemeColor = {
  MPNK: "stone",
  GBLU: "coral",
  GRED: "stone",
  MGRN: "coral",
  MWHT: "coral",
  MBGE: "coral",
  MBLK: "coral",
}

export function BigTeaserSlice({ slice }) {
  const { headline, topline, cta, image, video, content, sideVariant } = slice
  const pageThemeColor = usePageThemeColor()
  const loadingDecorationAvoidColors = useMemo(
    () => [pageThemeColor ? pageThemeColor.color : COLOR_STONE],
    [pageThemeColor]
  )

  const innerThemeName = useMemo(
    () =>
      pageThemeColor
        ? innerThemeNameByPageThemeColor[pageThemeColor.id]
        : slice.theme,
    [pageThemeColor, slice.theme]
  )

  return (
    <ThemeProvider themeName="stone">
      <Container
        {...getSliceRootElementProps(slice)}
        sideVariant={sideVariant || "imageLeft"}
      >
        <ThemeProvider themeName={innerThemeName}>
          <SBackground />
          <PrimaryMedia
            media={video || image}
            autoPlay={true}
            //onLoad={videoAPIOrImage => handleMediaLoad(index, videoAPIOrImage)}
            autoScale={false}
            sideVariant={sideVariant || "imageLeft"}
            avoidColors={loadingDecorationAvoidColors}
          />
          <StyledTopline
            topline={topline}
            type="tertiary"
            sideVariant={sideVariant || "imageLeft"}
          />
          <TextContainer sideVariant={sideVariant || "imageLeft"}>
            <Header>
              <Text type="primary">{headline.headline}</Text>
            </Header>
            <Text type="copy">
              <RichText data={content} />
            </Text>

            {cta && (
              <StyledButton
                variant="primary"
                linkTo={cta.internalLink || cta.externalLink}
              >
                {cta.label}
              </StyledButton>
            )}
          </TextContainer>
        </ThemeProvider>
      </Container>
    </ThemeProvider>
  )
}
