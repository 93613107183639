import React, { useMemo } from "react"

import styled, { css } from "styled-components"
import {
  breakpoints,
  TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_LG,
  TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_MD,
  TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_SM,
  TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_XL,
} from "../../styles"
import {
  SPACE_L,
  SPACE_TOKEN_STACK_2XL,
  SPACE_TOKEN_STACK_3XL,
  SPACE_TOKEN_STACK_4XL,
  SPACE_TOKEN_STACK_L,
  SPACE_TOKEN_STACK_M,
  SPACE_TOKEN_STACK_XL,
  SPACE_XL,
} from "../../styles/primitives"

import { createTextStylesFromToken, heroTextStyles, Text } from "../Text"
import { RichText, RichTextParagraphText } from "../RichText"
import { Topline } from "../Topline"
import { useGlobalTranslation } from "../../globalValues"
import { Button } from "../button"
import { getSliceRootElementProps } from "./utils"
import { MAX_WIDTH } from "../../styles/breakpoints"
import { Link } from "../links"

const SRoot = styled.section`
  ${breakpoints({
    SM: css`
      padding: 0 ${SPACE_L}px;
    `,
    MD: css`
      padding: 0 ${SPACE_L}px;
    `,
    LG: css`
      padding: 0 ${SPACE_XL}px;
    `,
    XL: css`
      padding: 0 calc(max(0px, 100vw - ${MAX_WIDTH}px) / 2 + ${SPACE_XL}px);
    `,
  })}
`

const HeadlineText = styled.h1`
  ${heroTextStyles};

  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_TOKEN_STACK_L}px;
    `,
    MD: css`
      margin-top: ${SPACE_TOKEN_STACK_M}px;
    `,
  })}
`

const SSublineRichText = styled(RichText)`
  margin-top: ${SPACE_TOKEN_STACK_XL}px;

  ${RichTextParagraphText} {
    ${createTextStylesFromToken({
      SM: TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_SM,
      MD: TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_MD,
      LG: TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_LG,
      XL: TEXT_TOKEN_SUB_HEADLINE_BREAKPOINT_XL,
    })}
  }
`

const StyledTopline = styled(Topline)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  margin-right: 0;

  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_TOKEN_STACK_3XL}px;
    `,
    MD: css`
      margin-top: ${SPACE_TOKEN_STACK_3XL}px;
    `,
    LG: css`
      margin-top: ${SPACE_TOKEN_STACK_3XL}px;
    `,
    XL: css`
      margin-top: ${SPACE_TOKEN_STACK_4XL}px;
    `,
  })}
`

const MetaText = styled(Text)`
  display: block;
  margin-bottom: ${SPACE_XL}px;

  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_TOKEN_STACK_L}px;
      margin-bottom: ${SPACE_L}px;
    `,
    MD: css`
      margin-top: ${SPACE_TOKEN_STACK_M}px;
    `,
  })}
`

const StyledButton = styled(Button)`
  display: block;
  margin-top: ${SPACE_XL}px;
`

const Container = styled.div`
  position: relative;

  ${breakpoints({
    SM: css`
      padding-top: ${SPACE_TOKEN_STACK_4XL}px;
      padding-bottom: ${SPACE_TOKEN_STACK_2XL}px;
    `,
  })}

  padding-top: ${SPACE_TOKEN_STACK_4XL + SPACE_TOKEN_STACK_2XL}px;
  padding-bottom: ${SPACE_TOKEN_STACK_4XL}px;

  ${HeadlineText}, ${SSublineRichText} {
    ${breakpoints({
      SM: css`
        max-width: 100%;
      `,
      MD: css`
        max-width: 85%;
      `,
      LG: css`
        max-width: 85%;
      `,
      XL: css`
        max-width: ${({ hasTopline }) => (hasTopline ? 66 : 77)}%;
      `,
    })};
  }
`

export function IntroSlice({ slice, additionalEntries, publishedAt }) {
  const { headline, topline, subline, showPublicationDate, locale, cta } = slice

  const publishedLabel = useGlobalTranslation("publishedAtLabel", {
    date: Intl.DateTimeFormat(locale).format(new Date(publishedAt)),
  })

  const parentPageLinkElement = useMemo(() => {
    const parentCMSPage = additionalEntries.filter(
      ({ __typename }) => __typename === "ContentfulCmsPage"
    )[0]
    return parentCMSPage ? (
      <Link to={parentCMSPage}>{parentCMSPage.title}</Link>
    ) : null
  }, [additionalEntries])

  return (
    <SRoot {...getSliceRootElementProps(slice)}>
      <Container hasTopline={topline?.topline}>
        {topline?.topline && <StyledTopline topline={topline} />}
        {(showPublicationDate || parentPageLinkElement) && (
          <MetaText type="meta">
            {publishedLabel && parentPageLinkElement ? (
              <>
                {`${publishedLabel} – `}
                {parentPageLinkElement}
              </>
            ) : publishedLabel ? (
              publishedLabel
            ) : (
              parentPageLinkElement
            )}
          </MetaText>
        )}
        <HeadlineText>{headline.headline}</HeadlineText>
        <SSublineRichText data={subline} keepTopLevelParagraph={true} />
        {cta && (
          <StyledButton
            variant="primary"
            linkTo={cta.internalLink || cta.externalLink}
          >
            {cta.label}
          </StyledButton>
        )}
      </Container>
    </SRoot>
  )
}
