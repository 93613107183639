import React, { Fragment } from "react"
import styled, { css } from "styled-components"
import { getSliceRootElementProps } from "./utils"
import { breakpoints, ThemeProvider } from "../../styles"
import { MAX_WIDTH } from "../../styles/breakpoints"
import {
  SPACE_1XL,
  SPACE_2XL,
  SPACE_L,
  SPACE_M,
  SPACE_S,
  SPACE_XL,
} from "../../styles/primitives"
import { Image } from "../Image"
import { calculateGridWidth, scrollStyles } from "../ScrollableColumns"
import { Text } from "../Text"

const OuterContainer = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor};

  ${breakpoints({
    SM: css`
      padding-bottom: ${SPACE_2XL}px;
    `,
  })};
`

const ScrollContainer = styled.section`
  ${breakpoints({
    SM: css`
      ${scrollStyles};
    `,
  })}
`

function createGridRows(outerPadding, paddingToTitle, paddingToDescription) {
  return css`
    grid-template-rows: [top] ${outerPadding}px [image-start] auto [image-end] ${paddingToTitle}px [title-start] auto [title-end] ${paddingToDescription}px [description-start] auto [description-end] ${outerPadding}px [bottom];
  `
}

function createGridColumnns(numberOfColumns, outerPadding, innerPadding) {
  return css`
    grid-template-columns:
      [left] ${outerPadding}
      repeat(
        ${numberOfColumns},
        [column-start] 1fr [column-end] ${innerPadding}px
      )
      calc(${outerPadding} - ${innerPadding}px)
      [right];
  `
}

const InnerContainer = styled.div`
  display: grid;

  ${({ numberOfColumns }) =>
    breakpoints({
      SM: css`
        ${createGridRows(SPACE_2XL, SPACE_XL, SPACE_M)}
        ${createGridColumnns(numberOfColumns, `${SPACE_L}px`, SPACE_L)};
        ${calculateGridWidth(numberOfColumns, 1)};

        > * {
          scroll-snap-align: center;
        }
      `,
      MD: css`
        ${createGridRows(SPACE_2XL, SPACE_L, SPACE_M)}
        ${createGridColumnns(numberOfColumns, `${SPACE_L}px`, SPACE_L)};
      `,
      LG: css`
        ${createGridRows(SPACE_2XL, SPACE_L, SPACE_XL)}
        ${createGridColumnns(numberOfColumns, `${SPACE_XL}px`, SPACE_L)};
      `,
      XL: css`
        ${createGridRows(SPACE_2XL, SPACE_XL + SPACE_S, SPACE_XL)}
        ${createGridColumnns(numberOfColumns, `${SPACE_1XL}px`, SPACE_L)};
      `,
      MAX: css`
        ${createGridRows(SPACE_2XL, SPACE_XL + SPACE_S, SPACE_XL)}
        ${createGridColumnns(
          numberOfColumns,
          `calc((100% - ${MAX_WIDTH}px) / 2 + ${SPACE_1XL}px)`,
          SPACE_L
        )};
      `,
    })}
`

const columnStartEndStyles = ({ columnIndex }) =>
  css`
    grid-column-start: column-start ${columnIndex + 1};
    grid-column-end: column-end ${columnIndex + 1};
  `

const StyledImage = styled(Image)`
  grid-row-start: image-start;
  grid-row-end: image-end;
  ${columnStartEndStyles};
`
const TitleText = styled(Text)`
  grid-row-start: title-start;
  grid-row-end: title-end;
  ${columnStartEndStyles};
`
const DescriptionText = styled(Text)`
  grid-row-start: description-start;
  grid-row-end: description-end;
  ${columnStartEndStyles};
`

export function ImageColumnsSlice({ slice }) {
  return (
    <ThemeProvider themeName="stone">
      <OuterContainer {...getSliceRootElementProps(slice)}>
        <ScrollContainer>
          <InnerContainer numberOfColumns={slice.assetTeasers.length}>
            {slice.assetTeasers.map((assetTeaser, index) => {
              const { teaserImage, title, description } = assetTeaser
              return (
                <Fragment key={assetTeaser.contentful_id}>
                  <TitleText columnIndex={index} type="tertiary">
                    {title}
                  </TitleText>
                  <DescriptionText columnIndex={index} type="copy">
                    {description?.description}
                  </DescriptionText>
                  <StyledImage columnIndex={index} asset={teaserImage} />
                </Fragment>
              )
            })}
          </InnerContainer>
        </ScrollContainer>
      </OuterContainer>
    </ThemeProvider>
  )
}
