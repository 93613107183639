import React, { useEffect, useRef, createRef } from "react"
import styled, { css } from "styled-components"
import {
  breakpoints,
  TEXT_TOKEN_COPY_BREAKPOINT_MD,
  ThemeProvider,
} from "../../styles"
import { RichText } from "../RichText"
import { Text, textConfigToCSS } from "../Text"
import {
  COLOR_GRAY_100,
  COLOR_STONE,
  SPACE_L,
  SPACE_XL,
  SPACE_1XL,
  SPACE_3XL,
} from "../../styles/primitives"
import { getSliceRootElementProps } from "./utils"
import Postmate from "postmate"

export function UnuCheckoutBuilder({ slice, otherProps }) {
  const { formId, headline, description, disclaimer } = slice
  const containerRef = createRef()
  const iframeRef = useRef()

  useEffect(() => {
    console.debug("Initialize Postmate")
    Postmate.debug = true
    const handshake = new Postmate({
      container: containerRef.current, // Element to inject frame into
      url: formId, // Page to load, must have postmate.js. This will also be the origin used for communication.
      name: "checkoutIframe", // Set Iframe name attribute. Useful to get `window.name` in the child.
      classListArray: ["postmate-iframe"], //Classes to add to the iframe via classList, useful for styling.
    })

    // When parent <-> child handshake is complete, data may be requested from the child
    handshake
      .then(child => {
        iframeRef.current = child

        child.on("gtm-event", data => {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push(data)
        })

        console.debug("Postmate initialized")
      })
      .catch(err => {
        console.error("Postmate init error::", err)
      })

    return () => iframeRef.current && iframeRef.current.destroy()
  }, [])

  return (
    <ThemeProvider themeName="stone">
      <style>
        {`
          .postmate-iframe {
            border: none;
            width: 100%;
            height: 100%; 
          }
        `}
      </style>
      <Root
        {...getSliceRootElementProps(slice, otherProps, {
          sliceBackgroundVariant: "stone",
        })}
      >
        <Container>
          {headline && description && (
            <Section>
              <Headline type="tertiary">{headline.headline}</Headline>
              <Description data={description} keepTopLevelParagraph={false} />
            </Section>
          )}
          <SIFrame ref={containerRef} />
          {disclaimer && (
            <Section>
              <Disclaimer data={disclaimer} keepTopLevelParagraph={false} />
            </Section>
          )}
        </Container>
      </Root>
    </ThemeProvider>
  )
}

const Root = styled.section`
  position: relative;
  z-index: 0;
  display: grid;
  background-color: ${({ theme }) => theme.backgroundColor};
`

const Container = styled.div`
  max-width: none !important;
  margin-left: 0 !important;
  width: 100%;
  height: 100%;
  background: ${COLOR_STONE};
`

const SIFrame = styled.div`
  background: ${COLOR_STONE};
  border: none;
  width: 100%;
  height: calc(100vh - 80px);
`

const Section = styled.section`
  border-radius: 8px;
  padding: ${SPACE_L}px ${SPACE_L}px ${SPACE_1XL}px;
  background-color: ${COLOR_GRAY_100};

  & ~ & {
    margin-top: ${SPACE_XL}px;

    ${breakpoints({
      SM: css`
        margin-top: 0;
      `,
    })}
  }

  ${breakpoints({
    SM: css`
      background-color: transparent;
    `,
  })}
`

const Headline = styled(Text)`
  margin-bottom: ${SPACE_XL}px;
`

const Description = styled(RichText)`
  ${textConfigToCSS(TEXT_TOKEN_COPY_BREAKPOINT_MD)}
`

const Disclaimer = styled(RichText)`
  h5 {
    padding: 0;
  }
  h6 {
    padding: 0;
    font-weight: normal;
    line-height: 16px;
  }
`
