import { css } from "styled-components"
import { breakpoints } from "../../styles"

export function interweaveStyles(options) {
  return css`
    ${breakpoints({
      SM: css`
        margin-bottom: -${options.SM}px;
      `,
      MD: css`
        margin-bottom: -${options.MD}px;
      `,
      LG: css`
        margin-bottom: -${options.LG}px;
      `,
      XL: css`
        margin-bottom: -${options.XL}px;
      `,
      MAX: css`
        margin-bottom: -${options.MAX}px;
      `,
    })}
  `
}
