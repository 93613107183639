import styled, { css } from "styled-components"
import { breakpoints } from "../../styles"
import { SPACE_S, SPACE_XL } from "../../styles/primitives"

export const OVERLAY_PADDING_IN_MAP = SPACE_XL + SPACE_S

export const OverlayWrapper = styled.div`
  z-index: 1;
  overflow: hidden;

  ${({ index, hasFollowingOverlays }) =>
    index === 0 && !hasFollowingOverlays
      ? css`
          grid-row-start: overlay-${index + 1}-start;
          grid-row-end: overlay-3-end;
          grid-column-start: overlay-${index + 1}-start;
          grid-column-end: overlay-${index + 1}-end;
        `
      : css`
          grid-row-start: overlay-${index + 1}-start;
          grid-row-end: overlay-${index + 1}-end;
          grid-column-start: overlay-${index + 1}-start;
          grid-column-end: overlay-${index + 1}-end;
        `}

  ${({ responsiveTopPadding }) =>
    breakpoints({
      SM: css`
        max-height: calc(100vh - ${responsiveTopPadding.SM}px);
      `,
      MD: css`
        max-height: calc(
          100vh - (2 * ${OVERLAY_PADDING_IN_MAP}px) -
            ${responsiveTopPadding.MD}px
        );
      `,
      LG: css`
        max-height: calc(
          100vh - (2 * ${OVERLAY_PADDING_IN_MAP}px) -
            ${responsiveTopPadding.LG}px
        );
      `,
      XL: css`
        max-height: calc(
          100vh - (2 * ${OVERLAY_PADDING_IN_MAP}px) -
            ${responsiveTopPadding.XL}px
        );
      `,
      MAX: css`
        max-height: calc(
          100vh - (2 * ${OVERLAY_PADDING_IN_MAP}px) -
            ${responsiveTopPadding.XL}px
        );
      `,
    })};

  display: grid;
  grid-template-areas: "content";
  grid-template-rows: 100%;
  grid-template-columns: 100%;

  > * {
    grid-area: content;
    max-height: 100vh;
  }

  pointer-events: none;
  > * {
    pointer-events: all;
  }
`
