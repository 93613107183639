import React, { useMemo } from "react"

import styled, { css } from "styled-components"
import { breakpoints, ThemeProvider } from "../../styles"
import {
  COLOR_CORAL,
  COLOR_GREEN,
  COLOR_STONE,
  SPACE_1XL,
  SPACE_2XL,
  SPACE_3XL,
  SPACE_L,
  SPACE_M,
  SPACE_S,
  SPACE_XL,
} from "../../styles/primitives"
import { RichText, RichTextParagraphText } from "../RichText"
import { createTextStylesFromToken, Text } from "../Text"
import { imagePairCaptionTextToken } from "./backgroundImageParallaxSlice/richTextComponents"
import { Image } from "../Image"
import {
  useCarrouselSwitching,
  getProgressText,
  SWITCH_DURATION,
  ImageCarrouselBox,
  CarrouselArrowButton,
} from "../imageCarrousel"

import { interweaveStyles } from "./interweaving"
import { getSliceRootElementProps } from "./utils"
import { MAX_WIDTH } from "../../styles/breakpoints"

export const dualImageCarrouselSliceIterweaveConfig = {
  SM: SPACE_1XL + SPACE_L,
  MD: SPACE_2XL + SPACE_S,
  LG: SPACE_2XL + SPACE_XL,
  XL: SPACE_3XL,
  MAX: SPACE_3XL,
}

function createGridTemplateRows(spacing) {
  return css`
    grid-template-rows:
      [top] ${spacing}px [left-image-start] 0 [right-text-start] minmax(
        auto,
        1fr
      )
      [right-text-end] 0 [right-image-start] minmax(auto, 1fr)
      [left-image-end] 0 [left-text-start]
      minmax(auto, 1fr)
      [left-text-end] 0 [right-image-end] 0 [bottom];
  `
}

function createGridTemplateColumns(outerPadding, innerGaps) {
  return css`
    grid-template-columns:
      [left] ${outerPadding}
      [content-left-start] minmax(auto, 1fr)
      [content-left-end] ${innerGaps / 2}px [center] ${innerGaps / 2}px [content-right-start] minmax(
        auto,
        1fr
      )
      [content-right-end] ${outerPadding} [right];
  `
}

const Container = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor};
  display: grid;

  ${interweaveStyles(dualImageCarrouselSliceIterweaveConfig)};

  ${breakpoints({
    SM: css`
      grid-template-rows: [top] ${SPACE_L}px [left-image-start] auto [left-image-end] ${SPACE_L}px [right-text-start] auto [right-text-end] ${SPACE_1XL}px [right-image-start] auto [right-image-end] ${SPACE_L}px [bottom];
      grid-template-columns:
        [left] ${SPACE_L}px
        [content-left-start] 0 [content-right-start] auto [content-left-end] 0 [content-right-end]
        ${SPACE_L}px
        [right];
    `,
    MD: css`
      ${createGridTemplateRows(SPACE_XL)}
      ${createGridTemplateColumns(`${SPACE_L}px`, SPACE_M)}
    `,
    LG: css`
      ${createGridTemplateRows(SPACE_2XL)}
      ${createGridTemplateColumns(`${SPACE_XL}px`, SPACE_L)}
    `,
    XL: css`
      ${createGridTemplateRows(SPACE_2XL)}
      ${createGridTemplateColumns(
        `calc(max(0px, 100% - ${MAX_WIDTH}px) / 2 + ${SPACE_XL}px)`,
        SPACE_L
      )}
    `,
  })}
`

const StyledLeftImageCarrouselBox = styled(ImageCarrouselBox)`
  grid-row-start: left-image-start;
  grid-row-end: left-image-end;
  grid-column-start: content-left-start;
  grid-column-end: content-left-end;
`

const StyledRightImageCarrouselBox = styled(ImageCarrouselBox)`
  grid-row-start: right-image-start;
  grid-row-end: right-image-end;
  grid-column-start: content-right-start;
  grid-column-end: content-right-end;
`
const SDescriptionRichText = styled(RichText)`
  grid-row-start: right-text-start;
  grid-row-end: right-text-end;
  grid-column-start: content-right-start;
  grid-column-end: content-right-end;

  display: flex;
  align-items: flex-end;

  ${breakpoints({
    MD: css`
      padding-bottom: ${SPACE_1XL}px;
    `,
    LG: css`
      padding-left: 82px;
      padding-bottom: ${SPACE_2XL}px;
    `,
    XL: css`
      padding-left: 104px;
      padding-bottom: 42px;
    `,
  })}

  >${RichTextParagraphText} {
    ${createTextStylesFromToken(imagePairCaptionTextToken)}
  }
`
const ProgressContainer = styled.div`
  grid-row-start: left-text-start;
  grid-row-end: left-text-end;
  grid-column-start: content-left-start;
  grid-column-end: content-left-end;

  display: flex;
  align-items: flex-start;

  ${breakpoints({
    SM: css`
      grid-row-start: initial;
      grid-row-end: initial;
      grid-column-start: initial;
      grid-column-end: initial;
      display: none;
    `,
    MD: css`
      padding-top: ${SPACE_L}px;
      padding-bottom: ${dualImageCarrouselSliceIterweaveConfig.MD + SPACE_L}px;
    `,
    LG: css`
      padding-top: ${SPACE_XL}px;
      padding-bottom: ${dualImageCarrouselSliceIterweaveConfig.LG + SPACE_XL}px;
    `,
    XL: css`
      padding-top: ${SPACE_XL}px;
      padding-bottom: ${dualImageCarrouselSliceIterweaveConfig.XL + SPACE_XL}px;
    `,
  })}
`

const InnerProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const ProgressText = styled(Text)`
  ${breakpoints({
    MD: css`
      font-size: 64px;
    `,
    LG: css`
      font-size: 70px;
    `,
    XL: css`
      font-size: 70px;
    `,
  })}
`

export function DualImageCarrouselSlice({ slice, interweave, ...otherProps }) {
  const {
    description,
    theme,
    leftCarrouselImages,
    rightCarrouselImages,
  } = slice

  const mergedItems = useMemo(() => {
    let result = []
    const maxItemLength = Math.max(
      leftCarrouselImages.length,
      rightCarrouselImages.length
    )
    for (let i = 0; i < maxItemLength; i++) {
      result.push([
        leftCarrouselImages[i] || rightCarrouselImages[i],
        rightCarrouselImages[i] || leftCarrouselImages[i],
      ])
    }

    return result
  }, [leftCarrouselImages, rightCarrouselImages])
  const carrouselState = useCarrouselSwitching(mergedItems)
  const currentLeftImage = mergedItems[carrouselState.currentItemIndex][0]
  const currentRightImage = mergedItems[carrouselState.currentItemIndex][1]

  const arrowButtonTheme = useMemo(
    () =>
      ({
        green: {
          backgroundDefault: "transparent",
          arrowDefault: COLOR_STONE,
          backgroundHover: COLOR_STONE,
          arrowHover: COLOR_GREEN,
        },
        coral: {
          backgroundDefault: "transparent",
          arrowDefault: COLOR_STONE,
          backgroundHover: COLOR_STONE,
          arrowHover: COLOR_CORAL,
        },
      }[theme]),
    [theme]
  )

  return (
    <ThemeProvider themeName={theme}>
      <Container {...getSliceRootElementProps(slice, otherProps)}>
        <StyledLeftImageCarrouselBox
          currentItemKey={carrouselState.currentItemIndex}
        >
          <Image asset={currentLeftImage} />
        </StyledLeftImageCarrouselBox>
        <StyledRightImageCarrouselBox
          currentItemKey={carrouselState.currentItemIndex}
        >
          <Image asset={currentRightImage} />
        </StyledRightImageCarrouselBox>
        <SDescriptionRichText data={description} keepTopLevelParagraph={true} />
        <ProgressContainer>
          <InnerProgressContainer>
            <ProgressText type="copy">
              {getProgressText(
                carrouselState.currentItemIndex,
                mergedItems.length
              )}
            </ProgressText>
            <CarrouselArrowButton
              carrouselState={carrouselState}
              switchDuration={SWITCH_DURATION}
              colorTheme={arrowButtonTheme}
            />
          </InnerProgressContainer>
        </ProgressContainer>
      </Container>
    </ThemeProvider>
  )
}
