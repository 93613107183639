import React, { useCallback, useMemo, useRef } from "react"
import styled from "styled-components"
import { LocationSearchMap } from "../../../components/map/LocationSearchMap"
import {
  useDistancesToLocations,
  useLocationSearchState,
  useMapState,
  usePanMapToCountry,
  useSortedMapLocations,
} from "../../../components/map/mapHooks"
import { GarageFinderMapOverlay } from "./GarageFinderMapOverlay"
import { GarageMarkerOverlay } from "./GarageMarkerOverlay"
import { graphql, useStaticQuery } from "gatsby"
import { responsiveTopPadding } from "./config"
import { useLocale } from "../../../locale"
import { getSliceRootElementProps } from "../utils"
import { useMapAutoComplete } from "../../map/useMapAutoComplete"
import { RichText } from "../../RichText"

function isNumeric(value) {
  return /^\d+\.\d+$/.test(value)
}

export function GarageFinderSlice({ slice, ...otherProps }) {
  const {
    inputPlaceholder,
    unuScooterClassicOnlyLabel,
    pickUpAvailableLabel,
    mapOverlayHeadline,
    noMatchingLocationText,
  } = slice

  const garageData = useStaticQuery(graphql`
    query GarageJSONData {
      contentfulGaragesJson(contentful_id: { eq: "7CoWHkUddD7MHkU4pJWzn7" }) {
        garages {
          name
          Phone
          ShippingCity
          ShippingStreet
          ShippingCountry
          ShippingCountryCode
          ShippingLatitude
          ShippingLongitude
          ShippingPostalCode
          status
        }
      }
    }
  `)
  const garageLocations = garageData.contentfulGaragesJson.garages

  const overlayRef = useRef()
  const mapState = useMapState()

  const { country } = useLocale()

  const {
    selectedLocation: autocompleteLocation,
    handleAutoCompleteLoad,
    handleAutoCompletePlaceChanged,
    clearAutocompletedLocation,
  } = useMapAutoComplete()

  const mapLocations = useMemo(
    () =>
      garageLocations
        .map(garageLocation => ({
          id: `${garageLocation.Name}${garageLocation.ShippingCity}|${garageLocation.ShippingStreet}|${garageLocation.ShippingPostalCode}|${garageLocation.Name}`,
          name: garageLocation.Name,
          displayText: `${garageLocation.ShippingCity}, ${garageLocation.ShippingStreet}`,
          latitude: garageLocation.ShippingLatitude,
          longitude: garageLocation.ShippingLongitude,
          postalCode: garageLocation.ShippingPostalCode,
          countryCode:
            garageLocation.ShippingCountryCode?.toLowerCase() || "de",
          data: { ...garageLocation },
        }))
        .filter(
          ({ longitude, latitude }) =>
            isNumeric(longitude) && isNumeric(latitude)
        ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [garageLocations]
  )

  const locationSearchState = useLocationSearchState(
    mapState,
    autocompleteLocation,
    mapLocations,
    overlayRef
  )

  usePanMapToCountry(mapState, overlayRef, country)

  const locationDistanceMap = useDistancesToLocations(
    mapLocations,
    autocompleteLocation
  )

  const sortedLocations = useSortedMapLocations(
    mapLocations,
    locationDistanceMap,
    country
  )

  const hasAddressNoMatch = autocompleteLocation.type === "NO_MATCH"

  const replacementListContent = useMemo(
    () =>
      hasAddressNoMatch ? (
        <RichText data={noMatchingLocationText} keepTopLevelParagraph={true} />
      ) : null,
    [hasAddressNoMatch, noMatchingLocationText]
  )

  const renderLocationOverlay = useCallback(
    (overlayLocation, requestClose) => (
      <GarageMarkerOverlay
        location={overlayLocation}
        requestClose={requestClose}
        slice={slice}
        distance={
          locationDistanceMap ? locationDistanceMap[overlayLocation.id] : null
        }
      />
    ),
    [locationDistanceMap, slice]
  )

  return (
    <StyledLocationSearchMap
      locations={sortedLocations}
      overlayRef={overlayRef}
      renderLocationOverlay={renderLocationOverlay}
      mapState={mapState}
      locationSearchState={locationSearchState}
      responsiveTopPadding={responsiveTopPadding}
      {...getSliceRootElementProps(slice, otherProps)}
      autocompleteLocation={autocompleteLocation}
    >
      <GarageFinderMapOverlay
        locations={sortedLocations}
        locationDistanceMap={locationDistanceMap}
        replacementListContent={replacementListContent}
        locationSearchState={locationSearchState}
        ref={overlayRef}
        unuScooterClassicOnlyLabel={unuScooterClassicOnlyLabel}
        pickUpAvailableLabel={pickUpAvailableLabel}
        mapOverlayHeadline={mapOverlayHeadline}
        inputPlaceholder={inputPlaceholder}
        handleAutoCompleteLoad={handleAutoCompleteLoad}
        handleAutoCompletePlaceChanged={handleAutoCompletePlaceChanged}
      />
    </StyledLocationSearchMap>
  )
}

const StyledLocationSearchMap = styled(LocationSearchMap)`
  min-height: 100vh;
`
