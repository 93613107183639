import React from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import styled from "styled-components"
import { COLOR_UI_BACKGROUND_SECONDARY } from "../../styles"
import { ANIMATION_EASING } from "../../styles/animations"

const Container = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-color: ${COLOR_UI_BACKGROUND_SECONDARY};
  height: 100%;

  display: flex;
  flex-direction: column;
  > * {
    flex: 1;
  }
`

const TRANSITION_DURATION = 500
export function ImageCarrouselBox({ children, currentItemKey, ...otherProps }) {
  return (
    <Container {...otherProps}>
      <TransitionGroup>
        <CSSTransition
          classNames="transition"
          timeout={{ enter: TRANSITION_DURATION, exit: TRANSITION_DURATION }}
          key={currentItemKey}
        >
          <ImageWrapper>{children}</ImageWrapper>
        </CSSTransition>
      </TransitionGroup>
    </Container>
  )
}

const ImageWrapper = styled.div`
  transition: transform ${ANIMATION_EASING} ${TRANSITION_DURATION}ms,
    opacity ${ANIMATION_EASING} ${TRANSITION_DURATION}ms;
  transform: translateX(0);
  position: relative;
  height: 100%;

  > * {
    height: 100%;
  }

  &.transition-enter {
    z-index: 2;
    transform: translateX(-100%);
  }
  &.transition-enter-active {
    z-index: 2;
    transform: translateX(0);
    transition: transform ${ANIMATION_EASING} ${TRANSITION_DURATION}ms,
      opacity ${ANIMATION_EASING} ${TRANSITION_DURATION}ms;
  }
  &.transition-exit {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }
  &.transition-exit-active {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: transform ${ANIMATION_EASING} ${TRANSITION_DURATION}ms,
      opacity ${ANIMATION_EASING} ${TRANSITION_DURATION}ms;
  }
`
