import React, { useMemo, useRef } from "react"
import bezierEasing from "bezier-easing"

import { useLayoutEffect } from "react"
import {} from "react-transition-group"
import styled from "styled-components"
import { interpolateVariablesInTranslation } from "../../../globalValues"
import {
  breakpoints,
  TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_SM,
  TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_XL,
} from "../../../styles"
import {} from "../../../styles/animations"
import { textConfigToCSS, SHeadlineSecondaryText } from "../../Text"

export function DistanceText({
  totalDistance,
  distanceText,
  currentDistanceIndex,
  distances,
  duration,
  ...otherProps
}) {
  const currentDistanceElementRef = useRef()

  const [rawBeforeCurrentText, rawAfterCurrentText] = useMemo(
    () => distanceText.split("{current}"),
    [distanceText]
  )
  const beforeCurrentText = useMemo(
    () =>
      interpolateVariablesInTranslation(rawBeforeCurrentText, {
        total: totalDistance,
      }),
    [rawBeforeCurrentText, totalDistance]
  )
  const afterCurrentText = useMemo(
    () =>
      interpolateVariablesInTranslation(rawAfterCurrentText, {
        total: totalDistance,
      }),
    [rawAfterCurrentText, totalDistance]
  )
  useLayoutEffect(() => {
    if (currentDistanceIndex === -1) {
      currentDistanceElementRef.current.textContent = "0"
      return
    }
    if (currentDistanceIndex >= distances.length) {
      return
    }

    const from = distances
      .slice(0, currentDistanceIndex)
      .reduce((sum, current) => sum + current, 0)
    const to = from + distances[currentDistanceIndex]
    const delta = to - from
    const frameCount = Math.ceil(duration / 30)
    let frameIndex = 0

    const interpolate = bezierEasing(0.79, 0.28, 0.64, 0.82)

    let intervalId = setInterval(() => {
      currentDistanceElementRef.current.textContent = `${Math.round(
        interpolate(frameIndex / frameCount) * delta + from
      )}`
      frameIndex++

      if (frameIndex === frameCount) {
        clearInterval(intervalId)
      }
    }, 30)
    return () => clearInterval(intervalId)
  }, [currentDistanceIndex, distances, duration])

  return (
    <SRoot {...otherProps}>
      <span>{beforeCurrentText}</span>
      <span ref={currentDistanceElementRef}>0</span>
      <span>{afterCurrentText}</span>
    </SRoot>
  )
}

const TEXT_TOKEN_SM = TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_SM
const TEXT_TOKEN_MD = TEXT_TOKEN_HEADLINE_SECONDARY_BREAKPOINT_XL
const TEXT_TOKEN_LG = { ...TEXT_TOKEN_MD, fontSize: 2.5, lineHeight: 1.21025 }
const TEXT_TOKEN_XL = TEXT_TOKEN_LG

const SRoot = styled(SHeadlineSecondaryText)`
  font-variant-numeric: tabular-nums;
  ${breakpoints({
    SM: textConfigToCSS(TEXT_TOKEN_SM),
    MD: textConfigToCSS(TEXT_TOKEN_MD),
    LG: textConfigToCSS(TEXT_TOKEN_LG),
    XL: textConfigToCSS(TEXT_TOKEN_XL),
  })}
`
