import throttle from "lodash/throttle"
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"

export function useIntersectionObserver(
  intersectingElementRef,
  callback,
  options
) {
  useLayoutEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      intersectionEntries => {
        callback(intersectionEntries[0])
      },
      {
        root: null,
        threshold: 0.5,
        ...options,
      }
    )
    intersectionObserver.observe(intersectingElementRef.current)

    return () => {
      intersectionObserver.disconnect()
    }
  }, [callback, intersectingElementRef, options])
}

export function useIntersection(intersectingElementRef) {
  const [isIntersecting, setIsIntersecting] = useState(false)

  useIntersectionObserver(
    intersectingElementRef,
    useCallback(
      intersectionEntry =>
        setIsIntersecting(intersectionEntry?.isIntersecting || false),
      []
    )
  )

  return useMemo(() => ({ isIntersecting }), [isIntersecting])
}

export function useScrollEventListenerWhileIntersected(
  intersectingElementRef,
  handleScroll
) {
  const throttledHandleScroll = useMemo(() => throttle(handleScroll, 40), [
    handleScroll,
  ])

  useEffect(() => {
    return () =>
      document.removeEventListener("scroll", throttledHandleScroll, {
        passive: true,
      })
  }, [throttledHandleScroll])

  useIntersectionObserver(
    intersectingElementRef,
    useCallback(
      intersectionEntry => {
        if (intersectionEntry?.isIntersecting) {
          document.addEventListener("scroll", throttledHandleScroll, {
            passive: true,
          })
        } else {
          document.removeEventListener("scroll", throttledHandleScroll, {
            passive: true,
          })
        }
      },
      [throttledHandleScroll]
    ),
    { threshold: 0 }
  )
}
