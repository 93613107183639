/* global google */

export function locationToMapsPosition(location) {
  return {
    lat: parseFloat(location.latitude),
    lng: parseFloat(location.longitude),
  }
}

export function shiftMapsPositionByPixels(map, mapsPosition, x, y) {
  var point1 = map
    .getProjection()
    .fromLatLngToPoint(new google.maps.LatLng(mapsPosition))
  var point2 = new google.maps.Point(
    x / Math.pow(2, map.getZoom()),
    y / Math.pow(2, map.getZoom())
  )
  return map
    .getProjection()
    .fromPointToLatLng(
      new google.maps.Point(point1.x - point2.x, point1.y + point2.y)
    )
}

export function getBoundsForPositions(positions) {
  const bounds = new google.maps.LatLngBounds()

  for (const position of positions) {
    bounds.extend(position)
  }
  return bounds
}

// Taken from https://stackoverflow.com/a/1502821
export function getDistanceBetweenTwoPointsInMeters(
  latLngLiteral1,
  latLngLiteral2
) {
  var R = 6378137 // Earth’s mean radius in meter
  var dLat = rad(latLngLiteral2.latitude - latLngLiteral1.latitude)
  var dLong = rad(latLngLiteral2.longitude - latLngLiteral1.longitude)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(latLngLiteral1.latitude)) *
      Math.cos(rad(latLngLiteral2.latitude)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c
  return d // returns the distance in meter
}

function rad(x) {
  return (x * Math.PI) / 180
}

/**
 * // Converts an address to geocordinates.
 * Checks if given address is within expected country
 *
 * @param {String} address
 * @param {String} country
 * //@returns {Promise<any|String>} returns the coordinates or an error string
 * //@returns {Promise<boolean|String>} returns true or an error string
 */
//export async function addressToCoordinates(address) {
export async function checkAddressCountry(address, country) {
  // Note: Make sure the Google lib is loaded before the call,
  //       you can do so via useJsApiLoader({ ... })
  const geocoder = new google.maps.Geocoder()
  return new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        address: address,
      },
      (result, status) => {
        if (status === "OK" && result.length > 0) {
          const address_components = result[0]?.address_components
          if (address_components) {
            const _country = address_components.filter(component =>
              component.types.includes("country")
            )[0]
            if (_country.long_name === country) {
              resolve(true)
              return;
            }
          }
          resolve(false)
          return

          // const { lat, lng } = result?.[0]?.geometry?.location
          // const latitude = lat()
          // const longitude = lng()
          // if (latitude !== undefined && longitude !== undefined) {
          //   return resolve({ latitude, longitude })
          // }
        }

        const errorMessage =
          "Error while checking shipping address compatibility, please enter a valid address or contact our support."

        reject(new Error(errorMessage))
      }
    )
  })
}
