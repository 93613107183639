import React from "react"

import styled, { css } from "styled-components"
import { getSliceRootElementProps } from "./utils"
import {
  breakpoints,
  TEXT_TOKEN_COPY_BREAKPOINT_LG,
  TEXT_TOKEN_COPY_BREAKPOINT_XL,
  ThemeProvider,
} from "../../styles"
import { MAX_WIDTH } from "../../styles/breakpoints"
import {
  FONT_SIZE_4XL,
  FONT_SIZE_5XL,
  FONT_SIZE_6XL,
  FONT_SIZE_L,
  LINE_HEIGHT_LOOSE,
  LETTER_SPACING_TIGHTER,
  SPACE_1XL,
  SPACE_2XL,
  SPACE_3XL,
  SPACE_4XL,
  SPACE_L,
  SPACE_M,
  SPACE_S,
  SPACE_XL,
} from "../../styles/primitives"
import { interweavingGridTemaplteRowStyle } from "../InterweavingPadder"
import { RichText, RichTextParagraphText } from "../RichText"

import { Text, textConfigToCSS } from "../Text"
function createGridRows(innerSpacing) {
  return css`
    grid-template-rows: [top] 0 ${interweavingGridTemaplteRowStyle} 0 [headline-start] auto [headline-end] ${innerSpacing}px [copy-start] auto [copy-end];
  `
}

function createGridColumnns(copyIndentation) {
  return css`
    grid-template-columns: [headline-start] ${copyIndentation}px [copy-start] auto [copy-end] 0 [headline-end];
  `
}

const SRoot = styled.section`
  display: grid;
  background-color: ${({ theme }) => theme.backgroundColor};

  ${({ sizer }) =>
    breakpoints({
      SM: css`
        padding: ${sizer ?? SPACE_1XL}px ${SPACE_L}px;
        ${createGridRows(SPACE_XL)}
        ${createGridColumnns(0)};
      `,
      MD: css`
        padding: ${sizer ?? SPACE_2XL}px ${SPACE_L}px;
        ${createGridRows(SPACE_XL)}
        ${createGridColumnns(0)};
      `,
      LG: css`
        padding: ${sizer ?? SPACE_3XL}px ${SPACE_XL}px;
        ${createGridRows(SPACE_1XL)}
        ${createGridColumnns(80)}
      `,
      XL: css`
        padding: ${sizer ?? SPACE_4XL}px
          calc(max(0px, (100% - ${MAX_WIDTH}px)) / 2 + ${SPACE_XL}px);
        ${createGridRows(SPACE_1XL)}
        ${createGridColumnns(207)}
      `,
    })}
`

const HeadlineText = styled(Text)`
  grid-row-start: headline-start;
  grid-row-end: headline-end;
  grid-column-start: headline-start;
  grid-column-end: headline-end;

  ${({ theme }) =>
    theme.pageThemeColor &&
    theme.pageThemeColor.id !== "MWHT" &&
    css`
      color: ${theme.pageThemeColor.color};
    `}
`

const paragraphTextTokenSM = TEXT_TOKEN_COPY_BREAKPOINT_XL
const paragraphTextTokenMD = {
  fontFamily: "sans",
  fontWeight: 400,
  fontSize: 2.25,
  lineHeight: 1.4,
  letterSpacing: LETTER_SPACING_TIGHTER,
}
const paragraphTextTokenLG = {
  ...paragraphTextTokenMD,
  fontSize: 2.5,
}
const paragraphTextTokenXL = paragraphTextTokenLG

const SRichText = styled(RichText)`
  grid-row-start: copy-start;
  grid-row-end: copy-end;
  grid-column-start: copy-start;
  grid-column-end: copy-end;

  ${breakpoints({
    SM: css`
      ${textConfigToCSS(paragraphTextTokenSM)}
    `,
    MD: css`
      ${RichTextParagraphText} {
        ${textConfigToCSS(paragraphTextTokenMD)}
      }
    `,
    LG: css`
      ${RichTextParagraphText} {
        ${textConfigToCSS(paragraphTextTokenLG)}
      }
    `,
    XL: css`
      ${RichTextParagraphText} {
        ${textConfigToCSS(paragraphTextTokenXL)}
      }
    `,
  })}
`

const generateSizer = breakpoint => {
  switch (breakpoint) {
    case "small":
      return SPACE_M
    case "medium":
      return SPACE_L
    case "large":
      return SPACE_XL
    default:
      return undefined
  }
}

export function HeadlineAndCopySlice({ topInterweavingPadder, slice }) {
  return (
    <ThemeProvider themeName={slice.theme}>
      <SRoot
        {...getSliceRootElementProps(slice)}
        sizer={generateSizer(slice.size)}
      >
        {topInterweavingPadder}
        <HeadlineText type="hero">{slice.headline.headline}</HeadlineText>
        {slice.content && (
          <SRichText data={slice.content} keepTopLevelParagraph={true} />
        )}
      </SRoot>
    </ThemeProvider>
  )
}
