import { getImage } from "gatsby-plugin-image"
import React from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import styled, { css } from "styled-components"
import { breakpoints, useScreenSizeName } from "../../../styles"
import {
  ANIMATION_DURATION,
  ANIMATION_EASING,
} from "../../../styles/animations"
import { SPACE_XL } from "../../../styles/primitives"
import { Image } from "../../Image"
import {
  MAP_SVG_ASPECT_RATIO,
  MARKER_SIZES_PX,
  POI_IMAGE_APPEARANCE_AFTER_MARKER,
} from "./config"

const IMAGE_APPEAR_DURATION = 2 * ANIMATION_DURATION

export function POIImages({
  items,
  currentPOIIndex,
  showImages,
  ...otherProps
}) {
  const screenSizeName = useScreenSizeName()

  return (
    <>
      <STransitionGroup {...otherProps}>
        {items.map((item, index) => {
          const { x, y } = item.anchorPosition
          const { width, height } = getImage(item.image)
          const relativeContainerHeight = 1 / MAP_SVG_ASPECT_RATIO
          const relativeImageWidth = 0.25
          const imageAspectRatio = width / height
          const relativeImageHeight =
            relativeImageWidth / imageAspectRatio / relativeContainerHeight

          const appearOnTop = y > relativeImageHeight + 0.05

          return (
            showImages &&
            index === currentPOIIndex && (
              <CSSTransition
                key={index}
                classNames="transition"
                timeout={{
                  enter:
                    IMAGE_APPEAR_DURATION + POI_IMAGE_APPEARANCE_AFTER_MARKER,
                  exit:
                    IMAGE_APPEAR_DURATION + POI_IMAGE_APPEARANCE_AFTER_MARKER,
                }}
              >
                <SImageWrapper
                  style={{
                    transformOrigin: appearOnTop ? "bottom" : "top",
                    left: `min(max(${
                      (x - relativeImageWidth / 2) * 100
                    }%, ${SPACE_XL}px), ${
                      (1 - relativeImageWidth) * 100
                    }% - ${SPACE_XL}px)`,
                    ...(appearOnTop
                      ? {
                          top: `calc(${(y - relativeImageHeight) * 100}% - ${
                            MARKER_SIZES_PX[screenSizeName]
                          }px)`,
                        }
                      : {
                          top: `calc(${y * 100}% + ${
                            MARKER_SIZES_PX[screenSizeName]
                          }px)`,
                        }),
                  }}
                >
                  <SImage asset={item.image} />
                </SImageWrapper>
              </CSSTransition>
            )
          )
        })}
      </STransitionGroup>
      {items.map(({ image }, index) => (
        <SHiddenPreloadImage key={index} asset={image} />
      ))}
    </>
  )
}

const SImage = styled(Image)`
  ${breakpoints({
    MD: css`
      border-radius: 9px;
    `,
    LG: css`
      border-radius: 12px;
    `,
    XL: css`
      border-radius: 16px;
    `,
  })}
`

const SHiddenPreloadImage = styled(Image)`
  &.gatsby-image-wrapper {
    display: none;
  }
`

const SImageWrapper = styled.div`
  width: 25%;
  transition: transform ${ANIMATION_EASING} ${IMAGE_APPEAR_DURATION}ms;
  transition-delay: ${POI_IMAGE_APPEARANCE_AFTER_MARKER}ms;
  filter: drop-shadow(0px 9px 16px rgba(0, 0, 0, 0.25));
  transform: scale(1);

  position: absolute;

  &.transition-enter {
    transform: scale(0);
  }
  &.transition-enter-active {
    transform: scale(1);
  }

  &.transition-exit {
    transform: scale(1);
  }
  &.transition-exit-active {
    transform: scale(0);
  }

  ${breakpoints({
    SM: css`
      display: none;
    `,
  })}
`

const STransitionGroup = styled(TransitionGroup)``
