import React, { useMemo } from "react"

import styled, { css } from "styled-components"
import {
  breakpoints,
  COLOR_TEXT_PRIMARY,
  getThemeByName,
  ThemeProvider,
} from "../../styles"
import {
  COLOR_STONE,
  SPACE_2XL,
  SPACE_3XL,
  SPACE_4XL,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../../styles/primitives"

import { Text } from "../Text"
import { LoadingDecoratedMedia } from "../Media"
import { Image } from "../Image"
import { MAX_WIDTH } from "../../styles/breakpoints"
import { getSliceRootElementProps } from "./utils"

function createGridTemplateRows(verticalSpacing) {
  return css`
    grid-template-rows:
      [top] 0 [top-slot-start] auto [top-slot-end] 0 [bottom-slot-start] minmax(
        ${verticalSpacing}px,
        auto
      )
      [bottom-slot-end] 0 [bottom];
  `
}

function createGridTemplateColumns(spacing) {
  return css`
    grid-template-columns:
      [left] calc((100% - ${MAX_WIDTH}px) / 2) [content-start] ${spacing}px
      [content-inline-start] 1fr [left-content-end] ${spacing}px [center] ${spacing}px [right-content-start] 1fr [content-inline-end] ${spacing}px [content-end]
      calc((100% - ${MAX_WIDTH}px) / 2)
      [right];
  `
}

const Container = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  z-index: 0;

  display: grid;

  ${({ inline }) =>
    breakpoints({
      SM: css`
        padding: ${SPACE_2XL}px 0 0;
        ${createGridTemplateRows(SPACE_M)};
        ${createGridTemplateColumns(SPACE_L)};
      `,
      MD: css`
        padding: ${SPACE_3XL}px 0 0;
        ${createGridTemplateRows(SPACE_M)};
        ${createGridTemplateColumns(SPACE_L)};
      `,
      LG: css`
        padding: ${SPACE_4XL}px 0 0;
        ${createGridTemplateRows(SPACE_XL)};
        ${createGridTemplateColumns(SPACE_XL)};
      `,
      XL: css`
        padding: ${SPACE_4XL}px 0 0;
        ${createGridTemplateRows(SPACE_XL)};
        ${createGridTemplateColumns(SPACE_3XL + SPACE_M)};
      `,
    })};
`

const Caption = styled(Text)`
  z-index: 1;
  grid-row-start: bottom-slot-start;
  grid-row-end: bottom-slot-end;
  padding: ${SPACE_XL}px 0;
  min-height: ${SPACE_2XL}px;

  ${breakpoints({
    SM: css`
      padding: ${SPACE_M}px 0;
      min-height: ${SPACE_2XL}px;
    `,
    MD: css`
      padding: ${SPACE_M}px 0;
      min-height: ${SPACE_3XL}px;
    `,
    LG: css`
      padding: ${SPACE_XL}px 0;
      min-height: ${SPACE_4XL}px;
    `,
    XL: css`
      padding: ${SPACE_XL}px 0;
      min-height: ${SPACE_4XL}px;
    `,
  })}

  ${({ fillMode }) =>
    ({
      full: css`
        grid-column-start: content-inline-start;
        grid-column-end: content-inline-end;
      `,
      leftHalf: css`
        grid-column-start: content-inline-start;
        grid-column-end: left-content-end;
      `,
      rightHalf: css`
        grid-column-start: content-inline-start;
        grid-column-end: left-content-end;
        color: ${COLOR_TEXT_PRIMARY};
      `,
    }[fillMode])}
`

const ActivationImageContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  z-index: 0;
`

const ActivationBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0;

  ${({ fillMode }) =>
    ({
      full: css`
        left: 0;
        right: 0;
      `,
      leftHalf: css`
        left: 0;
        right: 50%;
      `,
      rightHalf: css`
        left: 50%;
        right: 0;
      `,
    }[fillMode])};

  background-color: ${({ theme }) => theme.backgroundColor};
`

export function HeroMediaSlice({ slice, ...otherProps }) {
  const { image, video, inline, themeVariant, activationImage } = slice
  const realCpation = slice.caption?.caption
  const themeName = themeByVariantName[themeVariant]
  const theme = getThemeByName(themeName)
  const fillMode = fillModeByVariantName[themeVariant]
  const loadingDecoratorAvoidColors = useMemo(() => {
    if (fillMode === "leftHalft" || fillMode === "rightHalf") {
      return [COLOR_STONE, theme.backgroundColor]
    }
    return [theme.backgroundColor]
  }, [fillMode, theme.backgroundColor])

  if (image === null && video === null) {
    return null
  }

  return (
    <Container
      inline={inline}
      hasBottomSlot={!!realCpation}
      {...getSliceRootElementProps(slice, otherProps, {
        sliceBackgroundVariant: fillMode === "full" ? themeName : null,
      })}
    >
      <ActivationBackground fillMode={fillMode} theme={theme} />
      {activationImage && (
        <ActivationImageContainer>
          <Image
            asset={activationImage}
            style={{
              height: "100%",
            }}
          />
        </ActivationImageContainer>
      )}
      <ThemeProvider themeName={themeName}>
        <SLoadingDecoratedMedia
          media={image || video}
          inline={inline}
          avoidColors={loadingDecoratorAvoidColors}
        />

        <Caption type="meta" fillMode={fillMode}>
          {realCpation}
        </Caption>
      </ThemeProvider>
    </Container>
  )
}

const themeByVariantName = {
  stoneFull: "stone",
  coralRightHalf: "coral",
  coralLeftHalf: "coral",
  coralFull: "coral",
  greenRightHalf: "green",
  greenLeftHalf: "green",
  greenFull: "green",
}

const fillModeByVariantName = {
  stoneFull: "full",
  coralRightHalf: "rightHalf",
  coralLeftHalf: "leftHalf",
  coralFull: "full",
  greenRightHalf: "rightHalf",
  greenLeftHalf: "leftHalf",
  greenFull: "full",
}

const SLoadingDecoratedMedia = styled(LoadingDecoratedMedia)`
  z-index: 1;
  grid-row-start: top-slot-start;
  grid-row-end: top-slot-end;

  grid-column-end: content-inline-end;

  ${({ inline }) =>
    inline
      ? css`
          grid-column-start: content-inline-start;
          grid-column-end: content-inline-end;
        `
      : css`
          grid-column-start: left;
          grid-column-end: content-inline-end;

          ${breakpoints({
            SM: css`
              grid-column-end: right;
            `,
          })}
        `}
`
