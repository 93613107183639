import React, { useCallback } from "react"
import styled from "styled-components"
import {
  COLOR_UI_BACKGROUND_DISABLED,
  COLOR_UI_BACKGROUND_PRIMARY,
} from "../styles"
import { SPACE_M } from "../styles/primitives"

import { Text } from "./Text"

const Container = styled.div`
  display: inline-flex;
  align-items: center;
`

const RatingText = styled(Text)`
  margin-left: ${SPACE_M}px;
`

export function Rating({ score, ...otherProps }) {
  const calculateFillValue = useCallback(
    index => Math.max(0, Math.min(1, score - index)),
    [score]
  )

  return (
    <Container>
      <Star fillValue={calculateFillValue(0)} />
      <Star fillValue={calculateFillValue(1)} />
      <Star fillValue={calculateFillValue(2)} />
      <Star fillValue={calculateFillValue(3)} />
      <Star fillValue={calculateFillValue(4)} />
      <RatingText type="meta">{`${score
        .toString()
        .slice(0, 3)} / 5.0`}</RatingText>
    </Container>
  )
}

const StarContainer = styled.div`
  position: relative;

  margin-left: 2px;
`

const FilledStar = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${COLOR_UI_BACKGROUND_PRIMARY};
  clip-path: polygon(
    50% 0%,
    63% 38%,
    100% 38%,
    69% 59%,
    82% 100%,
    50% 75%,
    18% 100%,
    31% 59%,
    0 38%,
    37% 38%
  );
`

const UnfilledStar = styled(FilledStar)`
  background-color: ${COLOR_UI_BACKGROUND_DISABLED};
`

const StarClip = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`

function Star({ fillValue }) {
  return (
    <StarContainer>
      <UnfilledStar />
      <StarClip style={{ clipPath: `inset(0 ${100 * (1 - fillValue)}% 0 0)` }}>
        <FilledStar />
      </StarClip>
    </StarContainer>
  )
}
