import React, { useMemo } from "react"
import styled from "styled-components"

import { Text } from "../../../components/Text"
import {
  PickUpAvailableIcon,
  UnuClassicScooterIcon,
} from "../../../icons/icons"

import { COLOR_GRAY_400, SPACE_S } from "../../../styles/primitives"
import { Link } from "../../links"

export function GarageDetails({
  location,
  pickUpAvailableLabel,
  unuScooterClassicOnlyLabel,
  ...otherProps
}) {
  const normalizedPhoneNumber = useMemo(
    () => location.data.Phone?.replace(/[\s()]/g, "") || null,
    [location.data.Phone]
  )

  const pickupAvailable = location.data.canDoPickups
  // the logic here is either the garage can serve both V2 and Classic
  // or it can serve unu Classic only
  const unuClassicOnly = !location.data.canServiceUnuV2

  return (
    <Root {...otherProps}>
      <AddressLine type="copy">{location.data.ShippingStreet}</AddressLine>
      <AddressLine type="copy">{`${location.data.ShippingPostalCode} ${location.data.ShippingCity}`}</AddressLine>
      <AddressLine type="copy">
        {normalizedPhoneNumber && (
          <Link to={`tel:${normalizedPhoneNumber}`}>
            {normalizedPhoneNumber}
          </Link>
        )}
      </AddressLine>
      <SServices>
        {pickupAvailable && (
          <>
            <SServiceIcon>
              <PickUpAvailableIcon />
            </SServiceIcon>

            <SServiceText type="meta">{pickUpAvailableLabel}</SServiceText>
          </>
        )}
      </SServices>
    </Root>
  )
}

const AddressLine = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SServices = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  grid-auto-row: 1fr;
  align-items: center;
  margin-top: ${SPACE_S}px;
`

const SServiceText = styled(Text)`
  flex: 1;
  color: ${COLOR_GRAY_400};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SServiceIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-right: ${SPACE_S}px;
`

const Root = styled.div`
  border-radius: 8px;
`
