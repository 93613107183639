import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import {
  getBoundsForPositions,
  getDistanceBetweenTwoPointsInMeters,
  locationToMapsPosition,
  shiftMapsPositionByPixels,
} from "./helpers"

export function useLocationById(locations, searchedId) {
  return useMemo(() => locations.find(({ id }) => id === searchedId) || null, [
    locations,
    searchedId,
  ])
}

export function useMapState() {
  const [mapAPI, setMapAPI] = useState(null)
  const [isProjectionReady, setIsProjectionReady] = useState(false)
  const mapOnProjectionChanged = useCallback(() => {
    setIsProjectionReady(mapAPI && !!mapAPI.getProjection())
  }, [mapAPI])
  const mapOnLoad = useCallback(mapAPI => setMapAPI(mapAPI), [])

  return useMemo(
    () => ({ mapOnProjectionChanged, mapOnLoad, mapAPI, isProjectionReady }),
    [isProjectionReady, mapAPI, mapOnLoad, mapOnProjectionChanged]
  )
}

export function useLocationSearchState(
  { mapAPI, isProjectionReady },
  autocompleteLocation,
  locations,
  overlayRef
) {
  const [shownOverlayLocationId, setShownOverlayLocationId] = useState(null)

  const [selectedLocationId, rawSetSelectedLocationId] = useState(null)
  const selectedLocation = useLocationById(locations, selectedLocationId)

  // const lastSelectedLocationId = useRef(null)
  // const lastAutocompleteLocation = useRef(null)
  // "autocomplete" or "selected"
  const [lastLocationChange, setLastLocationChange] = useState(null)

  useEffect(() => {
    setLastLocationChange("autocomplete")
    // lastLocationChange.current = "autocomplete"
  }, [autocompleteLocation])

  useEffect(() => {
    setLastLocationChange("selected")
    // lastLocationChange.current = "selected"
  }, [selectedLocationId])

  function setSelectedLocationId(locationId) {
    // lastLocationChange.current = "selected"
    // lastSelectedLocationId.current = locationId
    rawSetSelectedLocationId(locationId)
  }

  const panToPosition = useCallback(
    location => {
      if (!location || !mapAPI || !overlayRef.current) {
        return
      }

      const overlayRect = overlayRef.current.getBoundingClientRect()

      mapAPI.setZoom(12)

      mapAPI.panTo(
        shiftMapsPositionByPixels(
          mapAPI,
          locationToMapsPosition(location),
          (overlayRect.left + overlayRect.width) / 2,
          0
        )
      )
    },
    [mapAPI, overlayRef]
  )

  useEffect(() => {
    if (lastLocationChange === "autocomplete") {
      panToPosition(
        autocompleteLocation.type === "MATCH"
          ? autocompleteLocation.geoLocation
          : null
      )
    } else if (selectedLocation) {
      panToPosition(selectedLocation)
    }
  }, [
    mapAPI,
    overlayRef,
    panToPosition,
    selectedLocation,
    autocompleteLocation,
    lastLocationChange,
  ])

  return {
    setSelectedLocationId,
    selectedLocationId,
    selectedLocation,

    setShownOverlayLocationId,
    shownOverlayLocationId,
  }
}

function useMapPanToFitPositions(
  { mapAPI, isProjectionReady },
  overlayRef,
  locations
) {
  useLayoutEffect(() => {
    if (!mapAPI || !!mapAPI.getProjection() || !overlayRef.current) {
      return
    }

    const totalBounds = getBoundsForPositions(
      locations.map(locationToMapsPosition)
    )

    mapAPI.fitBounds(totalBounds)

    const timeoutId = setTimeout(() => {
      const overlayRect = overlayRef.current.getBoundingClientRect()
      const mapElementRect = mapAPI.getDiv().getBoundingClientRect()

      // happens on mobile
      if (mapElementRect.width === 0) {
        return
      }

      const xShift = overlayRect.width + overlayRect.left + overlayRect.left
      const xShiftRatio = xShift / mapElementRect.width
      // mapAPI.setZoom(mapAPI.getZoom() - 1)
      mapAPI.panBy(-(xShift * xShiftRatio), 0)
    }, 100)
    return () => clearTimeout(timeoutId)
  }, [locations, mapAPI, overlayRef])
}

const countryPositionBounds = {
  de: [
    { latitude: 54.883431, longitude: 9.097402 }, // North
    { latitude: 47.302179, longitude: 10.218007 }, // South
    { latitude: 51.062663, longitude: 5.911367 }, // East
    { latitude: 51.241834, longitude: 14.9641 }, // West
  ],
  fr: [
    { latitude: 51.053103, longitude: 2.500666 }, // North
    { latitude: 42.470687, longitude: 2.578196 }, // South
    { latitude: 48.891415, longitude: 8.169611 }, // East
    { latitude: 48.416819, longitude: -4.789367 }, // West
  ],
  at: [
    { latitude: 48.997895, longitude: 15.054723 }, // North
    { latitude: 46.410989, longitude: 14.571324 }, // South
    { latitude: 48.015298, longitude: 17.087193 }, // East
    { latitude: 47.073505, longitude: 9.660436 }, // West
  ],
  nl: [
    { latitude: 53.510403, longitude: 7.092053 }, // North
    { latitude: 49.553725, longitude: 5.635986 }, // South
    { latitude: 51.426614, longitude: 6.207275 }, // East
    { latitude: 51.131107, longitude: 2.5708 }, // West
  ],
}

export function usePanMapToCountry(mapState, overlayRef, country) {
  useMapPanToFitPositions(
    mapState,
    overlayRef,
    countryPositionBounds[country.toLowerCase()]
  )
}

export function useDistancesToLocations(locations, autocompleteLocation) {
  return useMemo(() => {
    if (autocompleteLocation.type !== "MATCH") {
      return null
    }

    const distanceMap = {}

    for (const location of locations) {
      distanceMap[location.id] =
        getDistanceBetweenTwoPointsInMeters(
          location,
          autocompleteLocation.geoLocation
        ) / 1000
    }

    return distanceMap
  }, [locations, autocompleteLocation])
}

export function useSortedMapLocations(locations, distanceMap, userCountryCode) {
  function sortCountries(location1, location2) {
    if (distanceMap === null) {
      const countryCode1 = location1.countryCode
      const countryCode2 = location2.countryCode

      if (
        countryCode1 === userCountryCode &&
        countryCode2 !== userCountryCode
      ) {
        return -1
      }

      if (
        countryCode2 === userCountryCode &&
        countryCode1 !== userCountryCode
      ) {
        return 1
      }

      return location1.postalCode?.localeCompare(location2.postalCode)
    } else {
      return distanceMap[location1.id] - distanceMap[location2.id]
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => [...locations].sort(sortCountries), [
    locations,
    distanceMap,
  ])
}
