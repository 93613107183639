import React, { Fragment } from "react"

import styled, { css } from "styled-components"

import { breakpoints, COLOR_UI_BACKGROUND_DISABLED } from "../../../styles"

import { AccordionItem } from "./AccordionItem"
import { horizontalSlicePaddings, SliceContainer } from "../sliceContainers"
import {
  SPACE_TOKEN_STACK_2XL,
  SPACE_TOKEN_STACK_3XL,
  SPACE_TOKEN_STACK_L,
  SPACE_TOKEN_STACK_XL,
  SPACE_XL,
} from "../../../styles/primitives"
import { MAX_WIDTH } from "../../../styles/breakpoints"
import { getSliceRootElementProps } from "../utils"

const Divider = styled.div`
  height: 0;
  border-bottom: solid 1px ${COLOR_UI_BACKGROUND_DISABLED};
`
const Container = styled(SliceContainer)`
  ${breakpoints({
    SM: css`
      padding-top: ${SPACE_TOKEN_STACK_L}px;
      padding-bottom: ${SPACE_TOKEN_STACK_L}px;
    `,
    MD: css`
      padding-top: ${SPACE_TOKEN_STACK_XL}px;
      padding-bottom: ${SPACE_TOKEN_STACK_XL}px;
    `,
    LG: css`
      padding-top: ${SPACE_TOKEN_STACK_2XL}px;
      padding-bottom: ${SPACE_TOKEN_STACK_2XL}px;
    `,
    XL: css`
      padding: ${SPACE_TOKEN_STACK_3XL}px
        calc(max(0px, (100% - ${MAX_WIDTH}px) / 2) + ${SPACE_XL}px);
    `,
  })}
`

const ItemPaddingWithoutDivider = styled.div`
  ${breakpoints({
    SM: horizontalSlicePaddings.SM,
    MD: horizontalSlicePaddings.MD,
    LG: horizontalSlicePaddings.LG,
  })}
`

export function Accordion({ slice, ...otherProps }) {
  return (
    <Container {...getSliceRootElementProps(slice, otherProps)}>
      {slice.items.map((item, index) => (
        <Fragment key={index}>
          {index > 0 && <Divider />}
          <ItemPaddingWithoutDivider>
            <AccordionItem item={item} />
          </ItemPaddingWithoutDivider>
        </Fragment>
      ))}
    </Container>
  )
}
