import React from "react"

import { Modal } from "./Modal"
import { RichText } from "./RichText"

export function ContentfulAutoModal({
  headline,
  content,
  contentful_id,
  count,
  ...otherProps
}) {
  if (!contentful_id) return null

  const [isOpen, setIsOpen] = React.useState(
    typeof localStorage !== "undefined"
      ? JSON.parse(localStorage.getItem(`modal_${contentful_id}_open`)) ?? true
      : false
  )
  const _count =
    typeof localStorage !== "undefined"
      ? JSON.parse(localStorage.getItem(`modal_${contentful_id}_count`)) ?? 0
      : count

  const shallOpenAlways = count === 0 && isOpen
  const shallOpenOnce = count === 1 && isOpen
  const shallOpenMultiple = count > 1 && isOpen && _count < count

  if (shallOpenMultiple) {
    localStorage.setItem(`modal_${contentful_id}_count`, _count + 1)
    if (_count + 0 === count) {
      localStorage.setItem(`modal_${contentful_id}_open`, false)
    }
  }
  if (shallOpenOnce) {
    localStorage.setItem(`modal_${contentful_id}_open`, false)
  }

  if (shallOpenAlways || shallOpenOnce || shallOpenMultiple) {
    return (
      <Modal
        headline={headline.headline}
        {...otherProps}
        onClose={() => setIsOpen(false)}
      >
        {content && <RichText data={content} />}
      </Modal>
    )
  }
  return null
}
