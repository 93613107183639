import React, { Fragment } from "react"
import styled, { css } from "styled-components"
import { SPACE_L, SPACE_M, SPACE_XL } from "../styles/primitives"
import { breakpoints } from "../styles"
import { createScrollbarStyles } from "./scrollHelpers"

export function calculateGridWidth(
  numberOfColumns,
  numberOfVisibleColumns,
  peekWidth = `${2 * SPACE_L}px`
) {
  return css`
    width: calc(
      ${numberOfColumns / numberOfVisibleColumns} * (100% - ${peekWidth})
    );
  `
}

const GridContainer = styled.div`
  display: grid;

  ${breakpoints({
    SM: css`
      ${({ numberOfColumns }) => calculateGridWidth(numberOfColumns, 1)}
    `,
    MD: css`
      ${({ numberOfColumns }) => calculateGridWidth(numberOfColumns, 2)}
    `,
  })}
  grid-template-columns:
    [left]
    ${SPACE_L}px repeat(
      ${({ numberOfColumns }) => numberOfColumns},
      [column-start] 1fr [column-end] ${SPACE_M}px
    )
    ${SPACE_L - SPACE_M}px [right];
  grid-template-rows: [top] 0 [image-start] auto [image-end] ${SPACE_XL}px [text-start] auto [text-end] ${SPACE_L}px [bottom];
`

export const scrollStyles = css`
  ${createScrollbarStyles("0")}
  overflow-x: scroll;

  scroll-snap-type: x proximity;
`

const ScrollContainer = styled.div`
  ${scrollStyles};
`

const ImageWrapper = styled.div`
  grid-row-start: image-start;
  grid-row-end: image-end;
  scroll-snap-align: center;
`

const ContentWrapper = styled.div`
  grid-row-start: text-start;
  grid-row-end: text-end;
`

export function ScrollableColumns({ columns, ...otherProps }) {
  return (
    <ScrollContainer>
      <GridContainer numberOfColumns={columns.length}>
        {columns.map(({ key, data }, index) => (
          <Fragment key={key}>
            <ImageWrapper
              style={{
                gridColumnStart: `column-start ${index + 1}`,
                gridColumnEnd: `column-end ${index + 1}`,
              }}
            >
              {data.image}
            </ImageWrapper>
            <ContentWrapper
              style={{
                gridColumnStart: `column-start ${index + 1}`,
                gridColumnEnd: `column-end ${index + 1}`,
              }}
            >
              {data.content}
            </ContentWrapper>
          </Fragment>
        ))}
      </GridContainer>
    </ScrollContainer>
  )
}
