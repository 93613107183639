import React from "react"
import styled from "styled-components"
import { COLOR_INTERACTIVE_LINK } from "../../../styles"
import {
  ANIMATION_DURATION,
  ANIMATION_EASING,
} from "../../../styles/animations"

import {
  COLOR_GRAY_100,
  COLOR_GRAY_300,
  SPACE_L,
  SPACE_M,
  SPACE_XS,
} from "../../../styles/primitives"
import { ButtonText } from "../../Text"

import { ColorSelector } from "../../ColorSelector"
import {
  SSwapTransition,
  swapTransitionParentStyles,
} from "../../SwapTransition"
import { modelColorsBySKUColorId } from "../../../styles/scooterModelColors"

export const scooterColors = Object.keys(modelColorsBySKUColorId).map(
  skuColorId => {
    return modelColorsBySKUColorId[skuColorId]
  }
)

export function ScooterModelControls({
  slice,
  colorSelectorState,
  ...otherProps
}) {
  return (
    <Root {...otherProps}>
      <SColorSelector state={colorSelectorState} />
    </Root>
  )
}

const Root = styled.section`
  display: grid;
  grid-template-areas:
    "top"
    "bottom";
`

const SColorSelector = styled(ColorSelector)`
  grid-area: top;
  background-color: ${COLOR_GRAY_100};
  border-radius: 50px;
  align-items: center;
`

const SControlText = styled(ButtonText)`
  ${swapTransitionParentStyles};
  padding: ${SPACE_XS}px 0;
  cursor: pointer;
  transition: color ${ANIMATION_EASING} ${ANIMATION_DURATION}ms;

  &:hover {
    color: ${COLOR_INTERACTIVE_LINK};
  }
`

const STrunkControlText = styled(SControlText)`
  text-align: right;
`

const SLightsControlText = styled(SControlText)`
  ${swapTransitionParentStyles};
`

const SVerticalBar = styled.div`
  height: 100%;
  width: 2px;
  background-color: ${COLOR_GRAY_300};
  margin: 0 ${SPACE_M}px;
`
