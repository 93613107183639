import React, { useMemo } from "react"
import styled, { css } from "styled-components"
import {
  breakpoints,
  COLOR_UI_BACKGROUND_INVERTED,
  ThemeProvider,
} from "../../styles"
import {
  COLOR_CORAL,
  COLOR_GREEN,
  COLOR_STONE,
  SPACE_1XL,
  SPACE_2XL,
  SPACE_3XL,
  SPACE_4XL,
  SPACE_L,
  SPACE_M,
  SPACE_XL,
} from "../../styles/primitives"

import { LoadingDecoratedImage } from "../Image"
import { RichText } from "../RichText"
import { Text } from "../Text"
import { Button } from "../button"
import { getSliceRootElementProps } from "./utils"
import { MAX_WIDTH } from "../../styles/breakpoints"

function createGridColumns(
  outerSpacing,
  secondaryCenterSpacing,
  leftToRightRatio,
  sideVariant
) {
  const finalLeftToRightRatio =
    sideVariant === "left" ? leftToRightRatio : 100 - leftToRightRatio
  const centerLeftSpacing = sideVariant === "left" ? 0 : secondaryCenterSpacing
  const centerRightSpacing = sideVariant === "left" ? secondaryCenterSpacing : 0

  return css`
    grid-template-columns:
      [left] ${outerSpacing}
      [left-content-start] ${finalLeftToRightRatio}fr
      [left-content-end] ${centerLeftSpacing} [center] ${centerRightSpacing} [right-content-start] ${100 -
      finalLeftToRightRatio}fr [right-content-end] ${outerSpacing};
  `
}

const Container = styled.div`
  position: relative;
  z-index: 0;
  display: grid;
  background-color: ${COLOR_UI_BACKGROUND_INVERTED};

  ${({ sideVariant }) =>
    breakpoints({
      SM: css`
        grid-template-rows: [top] ${SPACE_2XL}px [primary-start] auto [primary-image-end] ${SPACE_1XL}px [primary-background-end] ${SPACE_2XL}px [text-start] auto [text-end] ${SPACE_2XL}px [secondary-start] auto [secondary-end] ${SPACE_2XL}px;
        grid-template-columns:
          [left] ${SPACE_L}px [content-start] calc(50% - ${SPACE_L}px)
          [center] auto [content-end] ${SPACE_L}px [right];
      `,
      MD: css`
        grid-template-rows: [top] ${SPACE_2XL}px [primary-start] auto [primary-end] ${SPACE_2XL}px [text-start] auto [text-end] 0 [secondary-start] auto [secondary-end] ${SPACE_2XL}px;
        grid-template-columns: [left] ${SPACE_L}px [content-start] 1fr [centerish-left] ${SPACE_M}px [centerish-right] 1fr [content-end] ${SPACE_L}px [right];
      `,
      LG: css`
        grid-template-rows:
          [top] ${SPACE_2XL}px [content-start] auto
          [center] auto [content-end] ${SPACE_2XL}px;
        ${createGridColumns(`${SPACE_XL}px`, `${102}px`, 54.82, sideVariant)}
      `,
      XL: css`
        grid-template-rows:
          [top] ${SPACE_4XL}px [content-start] auto
          [center] auto [content-end] ${SPACE_3XL}px;
        ${createGridColumns(
          `calc(max(0px, (100% - ${MAX_WIDTH}px) / 2) + ${SPACE_XL}px)`,
          `${SPACE_4XL}px`,
          54.7,
          sideVariant
        )}
      `,
    })};
`
export const PrimaryImageBackground = styled.div`
  background-color: ${({ themeColor }) => themeColor};

  ${breakpoints({
    SM: css`
      grid-row-start: primary-start;
      grid-row-end: primary-background-end;
      grid-column-start: left;
      grid-column-end: right;
    `,
    MD: css`
      grid-row-start: primary-start;
      grid-row-end: primary-end;
      grid-column-start: left;
      grid-column-end: right;

      max-height: 456px;
    `,
    LG: css`
      grid-row-start: content-start;
      grid-row-end: end;
      ${({ sideVariant }) =>
        ({
          left: css`
            grid-column-start: left;
            grid-column-end: center;
          `,
          right: css`
            grid-column-start: center;
            grid-column-end: right;
          `,
        }[sideVariant])};
    `,
    XL: css`
      grid-row-start: content-start;
      grid-row-end: end;
      ${({ sideVariant }) =>
        ({
          left: css`
            grid-column-start: left;
            grid-column-end: center;
          `,
          right: css`
            grid-column-start: center;
            grid-column-end: right;
          `,
        }[sideVariant])};
    `,
  })}
`

const OuterBleedingBackground = styled.div`
  background-color: ${({ themeColor }) => themeColor};

  ${({ sideVariant }) =>
    ({
      left: css`
        grid-area: content-start / left / bottom / center;
      `,
      right: css`
        grid-area: content-start / center / bottom / right;
      `,
    }[sideVariant])}

  ${breakpoints({
    XL: css`
      /* position: absolute;
      z-index: 0;
      top: 0;
      bottom: 0;

      ${({ sideVariant }) =>
        ({
          left: css`
            right: 50%;
            width: 50vw;
          `,
          right: css`
            left: 50%;
            width: 50vw;
          `,
        }[sideVariant])} */
    `,
  })}
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${breakpoints({
    SM: css`
      grid-row-start: text-start;
      grid-row-end: text-end;
      grid-column-start: content-start;
      grid-column-end: content-end;
    `,
    MD: css`
      grid-row-start: text-start;
      grid-row-end: text-end;
      ${({ sideVariant }) =>
        ({
          left: css`
            grid-column-start: content-start;
            grid-column-end: centerish-left;
          `,
          right: css`
            grid-column-start: centerish-right;
            grid-column-end: content-end;
          `,
        }[sideVariant])};
    `,
    LG: css`
      grid-row-start: content-start;
      grid-row-end: center;
      padding-bottom: ${SPACE_M}px;

      ${({ sideVariant }) =>
        ({
          left: css`
            grid-column-start: right-content-start;
            grid-column-end: right-content-end;
          `,
          right: css`
            grid-column-start: left-content-start;
            grid-column-end: left-content-end;
          `,
        }[sideVariant])};
    `,
    XL: css`
      grid-row-start: content-start;
      grid-row-end: center;
      ${({ sideVariant }) =>
        ({
          left: css`
            grid-column-start: right-content-start;
            grid-column-end: right-content-end;
          `,
          right: css`
            grid-column-start: left-content-start;
            grid-column-end: left-content-end;
          `,
        }[sideVariant])};
    `,
  })}
`

export const SecondaryImageContainer = styled.div`
  ${breakpoints({
    SM: css`
      grid-row-start: secondary-start;
      grid-row-end: secondary-end;
      grid-column-start: content-start;
      grid-column-end: content-end;

      ${({ sideVariant }) =>
        ({
          left: css`
            padding-left: ${100 - 73.53}%;
          `,
          right: css`
            padding-right: ${100 - 73.53}%;
          `,
        }[sideVariant])};
    `,
    MD: css`
      grid-row-start: secondary-start;
      grid-row-end: secondary-end;

      ${({ sideVariant }) =>
        ({
          left: css`
            grid-column-start: centerish-right;
            grid-column-end: content-end;
          `,
          right: css`
            grid-column-start: content-start;
            grid-column-end: centerish-left;
          `,
        }[sideVariant])};
    `,
    LG: css`
      grid-row-start: center;
      grid-row-end: content-end;
      ${({ sideVariant }) =>
        ({
          left: css`
            grid-column-start: right-content-start;
            grid-column-end: right-content-end;
          `,
          right: css`
            grid-column-start: left-content-start;
            grid-column-end: left-content-end;
          `,
        }[sideVariant])};
    `,
    XL: css`
      grid-row-start: center;
      grid-row-end: content-end;
      ${({ sideVariant }) =>
        ({
          left: css`
            grid-column-start: right-content-start;
            grid-column-end: right-content-end;
          `,
          right: css`
            grid-column-start: left-content-start;
            grid-column-end: left-content-end;
          `,
        }[sideVariant])};
    `,
  })}
`

const Header = styled.header`
  padding-bottom: ${SPACE_M}px;
`

const PrimaryImage = styled(LoadingDecoratedImage)`
  height: 100%;

  ${breakpoints({
    SM: css`
      grid-row-start: primary-start;
      grid-row-end: primary-image-end;
      grid-column-start: content-start;
      grid-column-end: content-end;
    `,
    MD: css`
      grid-row-start: primary-start;
      grid-row-end: primary-end;
      grid-column-start: content-start;
      grid-column-end: content-end;
      ${({ sideVariant }) =>
        ({
          left: css`
            width: 65.97%;
          `,
          right: css`
            width: 65.97%;
          `,
        }[sideVariant])};

      max-height: 456px;
    `,
    LG: css`
      grid-row-start: content-start;
      grid-row-end: center;
      ${({ sideVariant }) =>
        ({
          left: css`
            grid-column-start: left-content-start;
            grid-column-end: center;
          `,
          right: css`
            grid-column-start: center;
            grid-column-end: right-content-end;
          `,
        }[sideVariant])};
    `,
    XL: css`
      grid-row-start: content-start;
      grid-row-end: center;
      ${({ sideVariant }) =>
        ({
          left: css`
            grid-column-start: left-content-start;
            grid-column-end: center;
          `,
          right: css`
            grid-column-start: center;
            grid-column-end: right-content-end;
          `,
        }[sideVariant])};
    `,
  })}
`

const StyledButton = styled(Button)`
  ${breakpoints({
    SM: css`
      margin-top: ${SPACE_M}px;
    `,
    MD: css`
      margin-top: ${SPACE_L}px;
    `,
    LG: css`
      margin-top: ${SPACE_XL}px;
      margin-bottom: -${SPACE_XL}px;
    `,
    XL: css`
      margin-top: ${SPACE_1XL}px;
      margin-bottom: -${SPACE_1XL}px;
    `,
  })}
`

export function EditorialSlice({ slice }) {
  const {
    headline,
    cta,
    content,
    primaryImage,
    secondaryImage,
    theme: themeName,
    sideVariant,
  } = slice
  const themeColor = { green: COLOR_GREEN, coral: COLOR_CORAL }[themeName]
  const loadingDecorationAvoidColors = useMemo(
    () => [themeColor, COLOR_STONE],
    [themeColor]
  )

  return (
    <ThemeProvider themeName="stone">
      <Container
        sideVariant={sideVariant}
        {...getSliceRootElementProps(
          slice,
          {},
          { sliceBackgroundVariant: null }
        )}
      >
        <OuterBleedingBackground
          sideVariant={sideVariant}
          themeColor={themeColor}
        />
        <PrimaryImageBackground
          themeColor={themeColor}
          sideVariant={sideVariant}
        />

        <PrimaryImage
          asset={primaryImage}
          sideVariant={sideVariant}
          avoidColors={loadingDecorationAvoidColors}
        />

        <SecondaryImageContainer sideVariant={sideVariant}>
          <LoadingDecoratedImage asset={secondaryImage} colorShift={1} />
        </SecondaryImageContainer>
        <TextContainer sideVariant={sideVariant}>
          <Header>
            <Text type="secondary">{headline.headline}</Text>
          </Header>
          <Text type="meta">
            <RichText data={content} />
          </Text>
          {cta && (
            <StyledButton
              variant="primary"
              linkTo={cta.internalLink || cta.externalLink}
            >
              {cta.label}
            </StyledButton>
          )}
        </TextContainer>
      </Container>
    </ThemeProvider>
  )
}
