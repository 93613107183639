import React, { useCallback, useEffect, useMemo } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import { ThemeProvider, usePageThemeColor } from "../../../styles"
import { MAX_WIDTH } from "../../../styles/breakpoints"
import { COLOR_STONE, SPACE_M, SPACE_XL } from "../../../styles/primitives"

import { CopyStrongText } from "../../Text"
import { ColorSelector, useColorSelectorState } from "../../ColorSelector"
import { modelColorsBySKUColorId } from "../../../styles/scooterModelColors"

export function PageThemeColorSelector({ links, label, ...otherProps }) {
  const currentPageThemeColor = usePageThemeColor()

  const pageThemeColors = useMemo(
    () =>
      links.map(
        ({ pageThemeColor }) => modelColorsBySKUColorId[pageThemeColor]
      ),
    [links]
  )
  const initialColorIndex = useMemo(
    () =>
      currentPageThemeColor
        ? pageThemeColors.findIndex(({ id }) => id === currentPageThemeColor.id)
        : -1,
    [currentPageThemeColor, pageThemeColors]
  )
  const colorSelectorState = useColorSelectorState(
    pageThemeColors,
    initialColorIndex
  )
  const getLinkProps = useCallback(
    color => ({
      to: links.find(({ pageThemeColor }) => pageThemeColor === color.id),
    }),
    [links]
  )

  return (
    <ThemeProvider themeName="stone">
      <SRoot {...otherProps}>
        <SCaption>{label}</SCaption>
        <SColorSelector
          state={colorSelectorState}
          getLinkProps={getLinkProps}
        />
      </SRoot>
    </ThemeProvider>
  )
}

const SRoot = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: ${COLOR_STONE};
  padding: ${SPACE_M}px
    calc(max(0px, 100vw - ${MAX_WIDTH}px) / 2 + ${SPACE_XL}px);
`

const SCaption = styled(CopyStrongText)``

const SColorSelector = styled(ColorSelector)``
